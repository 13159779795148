@use 'src/assets/scss';

$animation-duration: 200ms;

.Slider {
  @include scss.default-width;
}

.SlideContainer {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  &.EditorMode {
    padding: scss.px-to-rem(12) scss.px-to-rem(4);
  }
}

.Slide {
  animation: animation-show $animation-duration;
  margin-left: 0;
  min-width: 100%;
  padding-left: 0;
  position: relative;
  transition: left $animation-duration ease;
  width: 100%;

  :global(.Spacing),
  :global(.SpacingSmall) {
    margin: 0;
  }

  &:not(.Current) {
    animation: animation-hide $animation-duration;
    visibility: hidden;
  }
}

.SlideSelector {
  display: flex;
  flex-direction: row;
  gap: scss.spacing-px-to-rem(24);
  justify-content: center;

  .SlideSelectorButton {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: scss.px-to-rem(40);
    justify-content: center;
    max-width: scss.px-to-rem(60);
    transition: width 100ms ease;

    .Line {
      background-color: scss.$palette-blue-dark-100;
      border-radius: 1px;
      display: inline-block;
      height: scss.spacing-px-to-rem(8);
      transition: height 100ms ease, background-color 100ms ease;
      width: 100%;
    }

    &.Current {
      flex: 2;
      max-width: scss.px-to-rem(120);

      .Line {
        background-color: scss.$palette-primary-100;
      }
    }

    &:hover {
      .Line {
        background-color: scss.$palette-primary-dark-100;
        height: scss.px-to-rem(14);
      }
    }

    &:focus-visible {
      outline: 0;

      .Line {
        @include scss.interactive-focus;
      }
    }
  }
}

@keyframes animation-show {
  0% {
    visibility: hidden;
  }

  1% {
    visibility: initial;
  }

  100% {
    visibility: initial;
  }
}

@keyframes animation-hide {
  0% {
    visibility: initial;
  }

  99% {
    visibility: initial;
  }

  100% {
    visibility: hidden;
  }
}
