@use 'src/assets/scss';

.SearchBody {
  @include scss.extra-margin-width();
  position: relative;

  .SearchResultsHeadline {
    @include scss.small-width();
    @include scss.display-4(bold);

    @include scss.breakpoint-max-1339 {
      @include scss.extra-margin-width();
      padding: 0 scss.$spacing-2;
    }

    @include scss.breakpoint-max-1023 {
      padding: 0;
    }

    @include scss.breakpoint-max-599 {
      display: none;
    }

    > span {
      margin-right: scss.$spacing-1;
    }

    .Highlight {
      color: scss.$palette-primary-100;
    }
  }

  .ResultList {
    margin-top: scss.$spacing-2;
    padding-bottom: scss.$spacing-2;
  }

  .Illustrations {
    display: flex;
    gap: scss.$spacing-1;
    height: 250px;
    left: 50%;
    margin-bottom: calc(-1 * #{scss.$spacing-4});
    margin-left: -50vw;
    overflow: hidden;
    position: relative;
    width: 100vw;

    @include scss.breakpoint-max-374 {
      margin-bottom: calc(-1 * #{scss.$font-size-base-900});
    }

    @include scss.breakpoint-min-480 {
      height: 30vh;
    }

    @include scss.breakpoint-min-720 {
      height: 35vh;
    }

    @include scss.breakpoint-min-900 {
      height: 40vh;
    }

    @include scss.breakpoint-min-1440 {
      height: 50vh;
    }

    @include scss.breakpoint-min-1920 {
      height: 60vh;
    }

    @include scss.breakpoint-min-2400 {
      height: 70vh;
      margin-left: calc(-50vw + 0.5 * (100vw - #{scss.$breakpoint-desktop-xxl}));
      max-width: scss.$breakpoint-desktop-xxl;
    }

    .Rocket {
      color: scss.$palette-primary-100;
      flex: 3 1 0;
      height: auto;
      left: 20px;
      max-height: 100%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(38deg);
      width: 30%;
      z-index: 1;

      @include scss.breakpoint-min-480 {
        left: 40px;
      }
    }

    .Network {
      bottom: 0;
      flex: 9 1 0;
      height: auto;
      max-height: 100%;
      overflow-x: hidden;
      position: absolute;
      right: 0;
      width: 80%;

      @include scss.breakpoint-min-480 {
        width: 60%;
      }
    }
  }
}
