@use 'src/assets/scss';

.IFrameWidget {
  @include scss.default-width();
  height: 0;
  margin: 0 auto;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
}
