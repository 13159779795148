@use 'src/assets/scss';

.DownloadWidget {
  @include scss.default-width();
  align-items: center;
  background: scss.$palette-blue-dark-3;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto scss.$spacing-4;
  padding: scss.$spacing-3 scss.$spacing-35;

  @include scss.breakpoint-max-599() {
    padding: scss.$spacing-15;
  }

  :global(.scrivito_editing_marker) {
    top: 0;
  }

  // Headline
  :global(.MuiTypography-root) {
    @include scss.display-3(bold);
    color: scss.$palette-primary-100;
    margin-bottom: scss.$spacing-1;
    margin-left: auto;
    margin-right: auto;
  }

  // List with files
  ul {
    column-gap: scss.$spacing-2;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 scss.$spacing-2;
    max-width: 100%;
    padding: 0;

    @include scss.breakpoint-max-1199() {
      column-gap: unset;
      flex-flow: column;
      max-width: 100%;
    }

    > li {
      flex: 0 calc(50% - #{scss.$spacing-2});
      margin: 0 0 scss.$spacing-05;
      max-width: calc(50% - #{scss.$spacing-2});
      padding: 0;

      @include scss.breakpoint-max-1199() {
        flex-shrink: 0;
        max-width: 100%;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  // If there is only one child, the file itself is not clickable
  .OnlyChild {
    justify-content: center;
    width: 100%;

    > li {
      flex: 1;
      max-width: initial;

      button:disabled {
        color: inherit;
        pointer-events: auto;
      }
    }

    button {
      width: 100%;

      .FileName {
        @include scss.text-ellipsis();
        flex-shrink: 0;
        max-width: 50%;
      }
    }
  }

  // Remove margin from download item and button
  :global(.MuiButton-root) {
    margin: 0;
  }

  .FileIcon {
    align-items: center;
    display: flex;
    padding-right: scss.$spacing-05;
  }

  .FileName {
    @include scss.text-ellipsis();
    max-width: 100%;

    @include scss.breakpoint-max-1199() {
      flex-shrink: 1;
    }
  }

  // Single file items to download
  .DownloadItem {
    @include scss.download-icon-states();
    @include scss.copy-6();

    .FileIcon {
      padding-right: scss.$spacing-05;
    }

    .DownloadIcon {
      background: scss.$palette-white-100;
      margin-left: scss.$spacing-1;
    }
  }
}
