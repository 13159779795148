@use 'src/assets/scss';

.HeadlineWidget {
  // H1
  &:global(.MuiTypography-h1) {
    @include scss.default-width();
    @include scss.display-1(bold);
    margin-bottom: 1rem;
  }

  // H2
  &:global(.MuiTypography-h2) {
    @include scss.default-width();
    @include scss.display-2(bold);
  }

  // H3
  &:global(.MuiTypography-h3) {
    @include scss.default-width();
    @include scss.display-3(bold);
  }

  // H4
  &:global(.MuiTypography-h4) {
    @include scss.default-width();
    @include scss.display-4(bold);
  }

  // H5
  &:global(.MuiTypography-h5) {
    @include scss.default-width();
    @include scss.display-5(bold);
  }

  // H6
  &:global(.MuiTypography-h6) {
    @include scss.default-width();
    @include scss.display-6(bold);
  }
}
