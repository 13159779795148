@use 'src/assets/scss';

.ButtonWidget {
  // Primary Button
  &:global(.MuiButton-textPrimary) {
    @include scss.roboto-condensed-normal;
    background-color: scss.$palette-blue-dark-100;
    color: scss.$palette-white-100;

    &:hover {
      background: scss.$color-button-primary-hover;
    }

    &:focus {
      @include scss.interactive-focus;
      background: scss.$color-button-primary-focus;
    }

    &:active {
      background: scss.$color-button-primary-active;
    }

    &:global(.Mui-disabled) {
      background: scss.$palette-blue-dark-25;
      color: scss.$palette-white-100;
    }
  }

  // Secondary Button
  &:global(.MuiButton-textSecondary) {
    @include scss.roboto-condensed-normal;
    background: scss.$palette-blue-dark-8;
    color: scss.$palette-blue-dark-100;

    &:hover {
      background: scss.$palette-blue-dark-3;
    }

    &:focus {
      @include scss.interactive-focus;
      background: scss.$palette-blue-dark-3;
    }

    &:active {
      background: scss.$color-button-secondary-active;
    }

    &:global(.Mui-disabled) {
      background: scss.$palette-blue-dark-8;
      color: scss.$palette-blue-dark-50;
    }
  }

  // CTA Button
  &:global(.MuiButton-textInfo) {
    @include scss.roboto-condensed-normal;
    background: scss.$palette-primary-100;
    color: scss.$palette-white-100;

    &:hover {
      background: scss.$palette-primary-dark-100;
    }

    &:focus {
      @include scss.interactive-focus;
      background: scss.$palette-primary-dark-100;
    }

    &:active {
      background: scss.$color-button-cta-active;
    }

    &:global(.Mui-disabled) {
      background: scss.$palette-primary-dark-25;
      color: scss.$palette-white-100;
    }
  }

  &:global(.MuiButton-text) {
    @include scss.display-6(bold);
    display: flex;
    height: scss.px-to-rem(56);
    margin-left: auto;
    margin-right: auto;
    min-width: 10rem;
    padding: 1em 1.5em;
    text-transform: none;
    width: fit-content;

    &.Small {
      height: scss.px-to-rem(40);
      min-width: 6rem;
      padding: 0.5em 1em;
    }

    .Icon {
      align-items: center;
      display: flex;
      padding-right: 0.5rem;
    }
  }
}
