@use 'src/assets/scss';

.VideoReelSlider {
  .Title {
    @include scss.container-width;

    h2 {
      @include scss.display-1(bold);
      color: scss.$palette-primary-100;
      text-transform: uppercase;
    }
  }

  .Text {
    @include scss.container-width;
    @include scss.copy-5;
    margin-bottom: scss.$spacing-2;
  }

  .Videos {
    display: flex;
    flex-direction: row;
    gap: scss.$spacing-1;
    margin-bottom: scss.$spacing-1;
    -ms-overflow-style: none;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    video {
      aspect-ratio: 9 / 16;
      background-color: scss.$palette-black-100;
      height: scss.px-to-rem(650);

      @include scss.breakpoint-max-1439 {
        height: scss.px-to-rem(532);
      }
    }
  }

  .Controls {
    @include scss.container-width;
    display: flex;
    flex-direction: row;
    gap: scss.$spacing-2;

    button {
      @include scss.round-icon-button;
    }
  }
}
