@use 'src/assets/scss';

.ButtonGroup {
  .ButtonGroupWrapper {
    @include scss.default-width;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: scss.spacing-px-to-rem(24);
    justify-content: center;
  }

  :global(.Spacing),
  :global(.SpacingSmall) {
    display: inline-block;
    margin: initial;
  }
}
