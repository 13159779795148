@use 'src/assets/scss';

.SearchForm {
  @include scss.small-width();
  display: flex;
  justify-content: center;
  margin-bottom: scss.$spacing-1;
  width: 100%;

  @include scss.breakpoint-max-1339 {
    @include scss.extra-margin-width();
    padding: 0 scss.$spacing-2;
  }

  @include scss.breakpoint-max-1023 {
    padding: 0;
  }

  :global(.MuiButton-text) {
    min-width: auto;
    width: auto;
  }

  .SearchInput {
    flex: 9 1 0;

    :global(.MuiFilledInput-root) {
      &:global(.MuiAutocomplete-inputRoot) {
        padding-right: 0;
      }

      :global(.MuiAutocomplete-endAdornment) {
        background: none;
        right: scss.$spacing-1;
        top: calc(50% - 12px);

        &:hover {
          background: none;
        }

        button {
          background: none;
        }
      }
    }

    &:global(.MuiAutocomplete-root) :global(.MuiInputBase-root) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;

      input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-left: scss.$spacing-1;
        padding-right: scss.$spacing-1;
      }
    }

    &:focus-within {
      :global(.MuiFilledInput-input) {
        box-shadow: 0 0 0 1px scss.$color-interactive-focus;
      }
    }
  }

  // Flyout configuration
  :global(.MuiAutocomplete-popper) {
    @include scss.flyout-popper-style();
  }

  .SearchFormDropdownMenu {
    @include scss.flyout-style();
  }

  .CloseIcon {
    background: none;
    cursor: pointer;
    height: 14px;
    width: 14px;
    z-index: 1;
  }

  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    flex: 3 1 0;
  }
}
