@use '/src/assets/scss';

.LinkList {
  flex: 0 0 150px;
  text-align: center;

  .Title {
    @include scss.display-7(bold);
    color: scss.$palette-blue-dark-100;
    display: block;
    margin: 0 0 scss.spacing(0.5) 0;
  }

  .List {
    flex: 1 1 auto;
    line-height: 150%;
    list-style: none;
    margin: 0;

    .ListItem {
      margin: 0;
      padding: 0;

      a {
        @include scss.display-7();
        border: 0;
        color: scss.$palette-blue-dark-100;
        display: inline-block;
        line-height: 160%;
        outline: none;
        padding: scss.spacing-px-to-rem(1) scss.spacing-px-to-rem(9);
        text-decoration: none;

        &:hover {
          color: scss.$palette-primary-100;
        }

        &:active {
          color: scss.$palette-primary-dark-100;
        }

        &:focus,
        &:focus-visible {
          @include scss.interactive-focus();
          border-radius: 2px;
          color: scss.$palette-primary-dark-100;
        }
      }
    }
  }
}
