@use '/src/assets/scss';

.InfoTeaser {
  @include scss.color-transition();
  align-items: center;
  background: scss.$palette-blue-dark-3;
  border: 0;
  color: scss.$palette-blue-dark-100;
  display: inline-flex;
  flex-direction: column;
  gap: scss.$spacing-05;
  hyphens: auto;
  justify-content: space-evenly;
  min-height: scss.spacing-px-to-rem(216);
  min-width: scss.spacing-px-to-rem(216);
  padding: scss.$spacing-1;
  text-align: center;
  text-decoration: none;

  .Image {
    margin-bottom: calc(-1 * #{scss.$spacing-05});
  }

  .Icon {
    margin-bottom: scss.$spacing-05;
  }

  .Headline :global(.MuiTypography-root.MuiTypography-inherit) {
    @include scss.display-5(bold);
    line-height: 120%;
  }

  .Text,
  .Text p {
    @include scss.display-6();
    line-height: 170%;
  }

  .LinkText {
    @include scss.display-6(bold);
    @include scss.color-transition();

    color: scss.$color-interactive-link;

    span {
      border-image: linear-gradient(90deg,
      scss.$palette-primary-dark-25 0%,
      scss.$palette-primary-dark-25 50%,
      scss.$palette-primary-dark-25 100%)
        1;
      border-style: solid;
      border-width: 0 0 4px;
      padding-bottom: scss.spacing(0.2);
    }
  }

  &:hover,
  &:focus {
    background: scss.$palette-primary-100;
    border-radius: scss.$border-radius;
    color: scss.$palette-white-100;

    .LinkText {
      color: scss.$palette-white-100;

      span {
        border-image: linear-gradient(90deg,
        scss.$palette-white-100 0%,
        scss.$palette-white-100 50%,
        scss.$palette-white-100 100%)
          1;
      }
    }
  }

  &:focus {
    @include scss.interactive-focus();
  }

  &:active {
    background: scss.$palette-primary-dark-100;
    border-radius: scss.$border-radius;
  }
}
