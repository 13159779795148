@use 'src/assets/scss';

.FormGroupWidget {
  padding: scss.$spacing-2 scss.$spacing-2 scss.$spacing-1 !important;

  @include scss.breakpoint-max-899 {
    padding: scss.$spacing-2 scss.$spacing-1 scss.$spacing-1 !important;
  }
  @include scss.breakpoint-max-599 {
    margin: 0 calc(-1 * #{scss.$spacing-1});
  }

  &.Empty {
    padding: 0 !important;
  }

  &.color-white {
    background-color: scss.$palette-white-100;

    // if two FormGroupWidget of same color following consecutively then remove padding-top
    + .FormGroupWidget.color-white {
      padding-top: 0 !important;
    }
  }

  &.color-grey {
    background-color: scss.$palette-blue-dark-3;

    // if two FormGroupWidget of same color following consecutively then remove padding-top
    + .FormGroupWidget.color-grey {
      padding-top: 0 !important;
    }
  }

  .FormFieldset {
    border: 0;
    margin-bottom: scss.$spacing-1;
    min-width: auto;
    padding: 0;

    .Legend {
      @include scss.display-3(bold);
      color: scss.$palette-blue-dark-100;
      padding-bottom: scss.$spacing-1;
    }
  }
}
