@use 'src/assets/scss';
@use './navigation-vals' as nav;
@use '../shared-vals' as shared;
@use '../shared-styles';

.Navigation {
  @include shared-styles.navigation-base;
}

.Backdrop {
  background: linear-gradient(180deg,
  nav.$backdrop-color1 nav.$backdrop-pos1,
  nav.$backdrop-color2 nav.$backdrop-pos2,
  nav.$backdrop-color3 nav.$backdrop-pos3);
  bottom: 0;
  left: 50%;
  max-width: scss.px-to-rem(2400);
  opacity: 0.87;
  position: fixed;
  top: scss.px-to-rem(196);
  transform: translateX(-50%);
  transition: top shared.$transition-speed ease, shared.$transition-speed ease;
  transition-delay: 25ms; // TODO variable for this and for page-template
  width: 100%;
  z-index: scss.$z-index-navigation-backdrop;

  @include scss.breakpoint-max-1199 {
    display: none;
  }

  @include scss.breakpoint-min-1200 {
    &.Small {
      background: linear-gradient(180deg,
      nav.$backdrop-color1 nav.$backdrop-pos1,
      nav.$backdrop-color2 nav.$backdrop-pos2-small,
      nav.$backdrop-color3 nav.$backdrop-pos3);
      top: scss.px-to-rem(87);
    }
  }
}
