@use '../../assets/scss';

.Search {
  .Headline {
    margin-bottom: scss.$spacing-2;
    text-align: center;

    @include scss.breakpoint-max-374 {
      display: none;
    }
  }
}
