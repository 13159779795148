@use 'src/assets/scss';

.FormColumnWidget {
  @include scss.default-width();
  column-gap: scss.$spacing-2;
  display: flex;
  flex-flow: row;
  margin: 0 auto scss.$spacing-4;

  @include scss.breakpoint-max-719 {
    flex-flow: column;
    row-gap: scss.$spacing-2;
  }

  > * {
    flex: 1;
    margin: 0;
  }
}
