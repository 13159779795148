@use 'src/assets/scss';

.SearchResult {
  margin-bottom: scss.$spacing-2;
  padding: 0 scss.$spacing-2;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .HeadlineWrapper {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    gap: scss.$spacing-05;

    a {
      @include scss.reset-anchor-styles();

      &:hover {
        color: scss.$palette-primary-100;
      }

      &:active {
        color: scss.$palette-primary-dark-100;

        mark {
          color: scss.$palette-primary-dark-100;
        }
      }
    }
  }

  h2 {
    @include scss.display-3(bold);
  }

  mark {
    margin: 0;
    padding: 0;
  }

  svg {
    height: scss.px-to-rem(24);
    width: scss.px-to-rem(24);
  }

  .DownloadItem {
    @include scss.download-icon-states();
    @include scss.display-3(bold);
    @include scss.reset-anchor-styles();

    &:hover {
      color: scss.$palette-primary-100;

      .FileIcon {
        color: inherit;
      }
    }

    &:active {
      color: scss.$palette-primary-dark-100;

      mark {
        color: scss.$palette-primary-dark-100;
      }
    }

    &:focus-visible {
      box-shadow: none;
    }

    .FileIcon {
      color: scss.$palette-blue-dark-75;
      padding-right: scss.$spacing-05;
    }

    .DownloadIcon {
      background: scss.$palette-blue-dark-8;
      margin-left: scss.$spacing-1;
    }
  }

  &.Highlight {
    background: scss.$palette-blue-dark-3;
    margin-bottom: scss.$spacing-05;
    padding: scss.$spacing-15 scss.$spacing-2;

    + :not(.Highlight) {
      margin-top: scss.$spacing-4;
    }

    svg {
      color: scss.$palette-primary-100;
      position: absolute;
      right: scss.$spacing-1;
      top: scss.$spacing-1;
    }
  }

  .HighlightedText {
    @include scss.copy-5(bold);
    background-color: transparent;
    color: scss.$palette-primary-100;
    margin: 0;
    padding: 0;
  }

  .HighlightedHeadline {
    @include scss.display-3(bold);
    background-color: transparent;
    color: scss.$palette-primary-100;
  }
}
