@use 'src/assets/scss';
@use '../../building-blocks/navigation/shared-vals' as shared;
@use '../page-template/page-template.shared' as page-shared;

.PageWrapper {
  @include page-shared.page-wrapper;
}

.PageHeader {
  @include page-shared.page-header;
}

.PageMain {
  @include page-shared.page-main;
}
