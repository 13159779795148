@use 'src/assets/scss';
@use 'sass:math';

.TabsComponent {
  :global(.MuiTab-root) {
    @include scss.display-5();
    margin-bottom: scss.$spacing-1;
    padding: 0;
    text-transform: none;

    &:first-child {
      margin-right: scss.$spacing-15;
    }

    &:global(.Mui-selected) {
      @include scss.display-5(bold);
      color: scss.$palette-primary-100;
    }

    &:focus-visible {
      @include scss.interactive-focus-inset();
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  :global(.MuiTabs-indicator) {
    display: none;
  }
}
