@use '/src/assets/scss';

.UploadButtonFile {
  display: flex;
  flex-direction: column;
  padding: scss.$spacing-05 0;

  .FileContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: scss.px-to-rem(12);

    .Status,
    .FileType,
    .Action {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      height: 100%;
      justify-content: center;
      width: scss.px-to-rem(24);

      :global(.MuiCircularProgress-root) {
        color: scss.$palette-primary-100;
      }
    }

    .FileType {
      svg {
        height: scss.px-to-rem(24);
        width: scss.px-to-rem(20);
      }
    }

    .RemoveButton {
      align-items: center;
      background: none;
      border: 0;
      border-radius: 50%;
      color: scss.$palette-blue-dark-75;
      cursor: pointer;
      display: inline-flex;
      height: scss.px-to-rem(24);
      justify-content: center;
      padding: scss.px-to-rem(6);
      width: scss.px-to-rem(24);

      &:hover,
      &:focus {
        background: scss.$palette-primary-100;
        color: scss.$palette-white-100;
      }

      &:focus {
        @include scss.interactive-focus();
      }

      &:active {
        background: scss.$palette-primary-dark-100;
      }
    }

    .Name {
      @include scss.copy-6();
      display: flex;
      flex: 0 1 auto;
      line-height: 170%;
      min-width: 0;
      overflow: hidden;
      width: auto;

      .FileName {
        @include scss.text-ellipsis();
      }
    }
  }

  .ErrorContainer {
    @include scss.display-7(bold);
    line-height: 170%;
  }

  &.Success {
    .Status {
      color: scss.$palette-blue-light-100;
    }
  }

  &.Error {
    color: scss.$color-interactive-error;
  }
}
