@use 'src/assets/scss';

.IntroTextWidget {
  text-align: center;

  :global(.MuiTypography-root) {
    @include scss.display-2(bold);
  }

  p {
    @include scss.display-5();
  }

  &:not(.LandingPage) {
    @include scss.small-width();
  }

  &.LandingPage {
    :global(.MuiTypography-root) {
      margin: 0 auto;
      @include scss.breakpoint-min-600 {
        @include scss.display-0(bold);
      }

      @include scss.breakpoint-min-1024 {
        max-width: scss.$breakpoint-desktop-xs;
      }
    }

    .Text {
      @include scss.small-width();
      margin: 0 auto;
    }
  }
}
