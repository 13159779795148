@use 'src/assets/scss';

$transition-speed: 200ms;

.TimelineEntry {
  display: grid;
  grid-template:
    '. date date'
    'image line text';
  grid-template-columns: 33% auto 1fr;

  @include scss.breakpoint-max-719 {
    grid-template:
      'date date'
      'line image'
      'line text';
    grid-template-columns: auto 1fr;
  }

  .DateArea {
    grid-area: date;

    time {
      @include scss.display-2(bold);
      color: scss.$palette-primary-100;
      text-transform: uppercase;
    }
  }

  .ImageArea {
    grid-area: image;

    img {
      width: 100%;
    }
  }

  .LineArea {
    display: flex;
    grid-area: line;
    justify-content: center;
    width: scss.px-to-rem(48);

    .Line {
      background: linear-gradient(scss.$palette-primary-100 0%, transparent 100%);
      width: 4px;

      &.Expanded {
        background-color: scss.$palette-primary-100;
      }
    }
  }

  .TextArea {
    grid-area: text;

    .TextExpanded {
      &.EditMode {
        border-top: scss.px-to-rem(2) dotted scss.$palette-primary-100;
      }
    }

    .ExpandLink {
      padding: scss.px-to-rem(12) 0;

      button {
        @include scss.link-style;
        line-height: normal;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-transform: none;

        &:hover {
          background: none;
        }
      }
    }

    p {
      @include scss.copy-5;
    }
  }
}
