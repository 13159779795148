@use 'src/assets/scss';

.PageError404 {
  @include scss.default-width;
  padding-bottom: scss.spacing-px-to-rem(150);
  padding-top: scss.spacing-px-to-rem(274);
  position: relative;

  h1 {
    font-size: scss.px-to-rem(96);
    line-height: 100%;
    padding-bottom: scss.spacing-px-to-rem(61);
  }

  p {
    @include scss.display-2(bold);
    color: scss.$palette-primary-100;
    width: 50%;

    @include scss.breakpoint-max-719 {
      width: 100%;
    }
  }

  svg {
    color: scss.$palette-primary-100;
    height: 50%;
    position: absolute;
    right: 0;
    top: 35%;
    transform: scaleX(-1) rotate(38deg);

    @include scss.breakpoint-max-719 {
      display: none;
    }
  }
}
