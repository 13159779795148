@use 'src/assets/scss';

.LinkDropdownContainer {
  display: inline-flex;
  width: auto;

  .LinkDropdown {
    &:global(.MuiButton-root) {
      @include scss.display-6(bold);
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      color: scss.$palette-blue-dark-100;
      height: scss.px-to-rem(56);
      padding: 0;
      text-transform: none;

      .Icon {
        align-items: center;
        background: scss.$palette-blue-dark-8;
        border-radius: 50%;
        display: inline-flex;
        flex: 0 0 auto;
        height: scss.spacing-px-to-rem(24);
        justify-content: center;
        margin-left: scss.spacing(0.5);
        width: scss.spacing-px-to-rem(24);

        svg {
          color: scss.$palette-primary-100;
          width: scss.spacing-px-to-rem(12);
        }
      }

      &:hover,
      &:focus {
        color: scss.$palette-primary-100;
      }

      &:focus {
        .Icon {
          @include scss.interactive-focus();
        }
      }

      &.IsOpen {
        .Icon svg {
          transform: rotate(180deg) translateY(1px);
        }
      }
    }
  }
}

.LinkDropdownMenu {
  :global(.MuiPaper-root) {
    border: 2px solid scss.$palette-blue-dark-25;
    box-shadow: none;

    :global(.MuiList-root) {
      padding: scss.spacing-px-to-rem(8);

      .LinkDropdownMenuItem {
        @include scss.copy-6();
        background: none;
        border-radius: 1px;
        height: scss.spacing-px-to-rem(48);

        &:global(.MuiMenuItem-root) {
          background: none;
          color: scss.$palette-blue-dark-100;

          a {
            @include scss.copy-6();
            border: 0;
            color: inherit;
            font-size: inherit;
          }
        }

        &.Selected {
          background: none;
          color: scss.$palette-primary-100;

          a {
            @include scss.copy-6(bold);
          }
        }

        &:focus {
          @include scss.interactive-focus-inset();
          background-color: scss.$palette-blue-dark-3;
        }

        &:focus-within {
          background: none;
        }

        &:hover {
          background-color: scss.$palette-blue-dark-8;
        }

        &:active {
          background: scss.$palette-blue-dark-3;
        }
      }
    }
  }
}
