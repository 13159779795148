@use '/src/assets/scss';

.Footer {
  background: scss.$palette-blue-dark-3;
  // TODO change back to white when newsletter widget is availabe
  // background: scss.$palette-white-100;
  position: relative;
  z-index: scss.$z-index-page-footer;

  .Content {
    @include scss.container-width;
    // Space left/right on desktop adjusted to the maximum font-size
    padding: scss.spacing(4) scss.$grid-space-unit scss.spacing(1) scss.$grid-space-unit;
    width: 100%;

    @include scss.breakpoint-max-374 {
      // Space left/right on mobile adjusted to the minimum font-size
      padding: scss.spacing(4) scss.$font-size-base-900 scss.spacing(1) scss.$font-size-base-900;
    }
  }
}
