@use 'src/assets/scss';
@use '../../shared-vals' as shared;

.Flyout {
  background-color: scss.$palette-white-100;
  left: 50%;
  max-width: scss.px-to-rem(1920 - 48);
  min-height: 3rem;
  position: absolute;
  top: scss.px-to-rem(122);
  transform: translateX(-50%);
  transition: top shared.$transition-speed ease, max-height shared.$transition-speed ease;
  transition-delay: shared.$transition-delay;
  width: calc(100% - 48px);
  z-index: scss.$z-index-navigation-flyout;

  @include scss.breakpoint-min-1200 {
    &.Small {
      top: scss.px-to-rem(66);

      .FlyoutScroll {
        max-height: calc(100vh - #{scss.px-to-rem(66)});
      }
    }
  }

  @include scss.breakpoint-max-1199 {
    display: none;
  }

  &::after {
    background: linear-gradient(-45deg,
    scss.$palette-primary-100 0%,
    scss.$palette-primary-100 50%,
    transparent 50%,
    transparent 100%);
    bottom: 0;
    content: '';
    height: scss.px-to-rem(24);
    position: absolute;
    right: 0;
    width: scss.px-to-rem(24);
  }

  .FlyoutScroll {
    max-height: calc(100vh - #{scss.px-to-rem(146)});
    overflow-y: auto;
    padding: scss.px-to-rem(52);
  }

  h2 {
    margin: 0 0 scss.px-to-rem(28);
  }

  .FlyoutContent {
    display: flex;
    flex-direction: row;

    > * {
      flex: 1;
    }

    > *:first-child {
      flex: 2;
    }
  }

  .FlyoutImage {
    margin-right: scss.px-to-rem(24);
    max-width: 50%;

    @include scss.breakpoint-max-1439 {
      display: none;
    }
  }

  .FlyoutImageWrapper {
    min-height: scss.px-to-rem(400);
    overflow: hidden;
    position: relative;

    img {
      height: 400px;
      object-fit: cover;
      width: 100%;
    }

    &.Center img {
      left: 50%;
      transform: translateX(-50%);
    }

    &.Right img {
      right: 0;
    }
  }

  .FlyoutNavigation {
    display: flex;
    flex-direction: row;
  }
}
