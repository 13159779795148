@use 'src/assets/scss';

$container-padding: 12px;

.AccordionWidget {
  &:global(.MuiAccordion-root) {
    @include scss.extra-margin-width();
    box-shadow: none;
    cursor: pointer;

    .AccordionSummaryWrapper {
      padding: $container-padding;
    }

    // styling for odd accordion elements
    &:nth-of-type(2n) {
      .AccordionSummaryWrapper {
        background-color: scss.$palette-white-100;

        &:hover {
          :global(.MuiAccordionSummary-root) {
            background-color: scss.$palette-blue-dark-3;
          }
        }
      }
    }

    // styling for even accordion elements
    &:nth-of-type(2n + 1) {
      .AccordionSummaryWrapper {
        background-color: scss.$palette-blue-dark-3;

        &:hover {
          :global(.MuiAccordionSummary-root) {
            background-color: scss.$palette-blue-dark-8;
          }
        }
      }
    }

    // styling for open accordion element
    &:global(.Mui-expanded) {
      :global(.MuiAccordionSummary-root) {
        background-color: scss.$palette-blue-dark-8;
      }

      .AccordionSummaryWrapper {
        background-color: scss.$palette-blue-dark-8;

        &:hover {
          :global(.MuiAccordionSummary-root) {
            background-color: scss.$palette-blue-dark-3 !important;
          }
        }
      }
    }

    // removes initially set border from accordion element
    &::before {
      display: none;
    }
  }

  :global(.MuiAccordionSummary-root) {
    border-radius: 3px;
    flex-direction: row-reverse;
    grid-gap: scss.$spacing-1;
    padding-left: calc(#{scss.$spacing-1} - #{$container-padding});
    padding-right: calc(#{scss.$spacing-1} - #{$container-padding});

    // styling for focus state
    &:global(.Mui-focusVisible) {
      background: none;

      :global(.MuiAccordionSummary-expandIconWrapper) {
        &::before {
          border-radius: 50%;
          box-shadow: 0 0 0 1px scss.$palette-white-100, 0 0 0 4px scss.$palette-blue-light-100;
          content: '';
          height: 36px;
          left: -6px;
          position: absolute;
          top: -14px;
          width: 36px;
        }
      }

      :global(.MuiTypography-root) {
        color: scss.$palette-primary-100;
      }
    }

    :global(.MuiAccordionSummary-content) {
      margin-bottom: calc(#{scss.$spacing-15} - #{$container-padding});
      margin-top: calc(#{scss.$spacing-15} - #{$container-padding});
    }

    :global(.MuiTypography-root) {
      @include scss.copy-5;
      color: scss.$palette-blue-dark-100;
      line-height: 1.5;

      @include scss.breakpoint-min-720 {
        @include scss.display-5(bold);
        line-height: 1.5;
      }
    }

    // changes font color when accordion element is open
    &:global(.Mui-expanded) {
      :global(.MuiTypography-root) {
        color: scss.$palette-primary-100;
      }
    }

    // styles accordion icon
    :global(.MuiAccordionSummary-expandIconWrapper) {
      transform: rotate(-90deg);

      svg * {
        fill: scss.$palette-primary-100;
      }

      &:global(.Mui-expanded) {
        transform: rotate(0);

        svg * {
          fill: scss.$palette-blue-dark-100;
        }
      }
    }
  }

  // styles collapsible content part of accordion element
  :global(.MuiAccordionDetails-root) {
    padding: scss.$spacing-05 scss.$spacing-1 scss.$spacing-1;

    :global(.MuiTypography-root) {
      @include scss.copy-5;
      color: scss.$palette-blue-dark-100;
      line-height: 2;
    }

    &:hover {
      cursor: auto;
    }
  }
}
