@use 'src/assets/scss';

.IconListItem {
  @include scss.copy-5;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  list-style: none;

  svg {
    color: scss.$palette-primary-100;
    flex-shrink: 0;
    height: scss.$spacing-1;
    margin-right: scss.spacing(0.75);
    margin-top: scss.$spacing-05;
    width: scss.px-to-rem(24);
  }
}
