@use 'src/assets/scss';

.Wizard {
  @include scss.default-width;

  // overwrite button secondary color because it gets overwritten by form
  :global(.MuiButton-root.MuiButton-text.MuiButton-textSecondary) {
    background: scss.$palette-blue-dark-8;
  }
}
