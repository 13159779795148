@use '/src/assets/scss';

.FilterTag {
  &:global(.MuiButtonBase-root.MuiToggleButton-root) {
    @include scss.display-6(bold);
    background: scss.$palette-blue-dark-8;
    border: 0;
    border-radius: 5px;
    color: scss.$palette-blue-dark-100;
    height: scss.px-to-rem(40);
    padding: scss.spacing-px-to-rem(1) scss.spacing-px-to-rem(22);
    text-transform: none;

    &:global(.Mui-selected) {
      background: scss.$palette-blue-dark-100;
      color: scss.$palette-white-100;

      &:hover {
        background-color: scss.$palette-interactive-blue-dark-a;
      }

      &:active {
        background: scss.$palette-interactive-blue-dark-b;
      }

      &:disabled {
        background: scss.$palette-blue-dark-25;
        color: scss.$palette-white-100;
      }
    }

    &:focus {
      @include scss.interactive-focus();
    }

    &:hover {
      background: scss.$palette-blue-dark-3;
    }

    &:active {
      background: scss.$palette-interactive-blue-light-a;
    }

    &:disabled {
      background: scss.$palette-blue-dark-8;
      color: scss.$palette-blue-dark-50;
      pointer-events: none;
    }
  }
}
