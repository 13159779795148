$breakpoint-desktop-xxl: 2400px;
$breakpoint-desktop-xl: 1920px;
$breakpoint-desktop-l: 1440px;
$breakpoint-desktop-1600: 1600px;
$breakpoint-desktop-m: 1200px;
$breakpoint-desktop-s: 1024px;
$breakpoint-desktop-xs: 900px;
$breakpoint-desktop-xxs: 720px;
$breakpoint-mobile-xl: 600px;
$breakpoint-mobile-l: 480px;
$breakpoint-mobile-m: 375px;

@mixin breakpoint-between($min, $max) {
  @media screen and (min-width: $min) and (max-width: calc(#{$max} - 1px)) {
    @content;
  }
}

@mixin breakpoint-max-or-min($max, $min) {
  @media screen and (max-width: calc(#{$max} - 1px)), (min-width: $min) {
    @content;
  }
}

@mixin breakpoint-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

// All screens that are min. 480
@mixin breakpoint-min-480 {
  @media screen and (min-width: $breakpoint-mobile-l) {
    @content;
  }
}

// All screens that are min. 600
@mixin breakpoint-min-600 {
  @media screen and (min-width: $breakpoint-mobile-xl) {
    @content;
  }
}

// All screens that are min. 720
@mixin breakpoint-min-720 {
  @media screen and (min-width: $breakpoint-desktop-xxs) {
    @content;
  }
}

// All screens that are min. 900
@mixin breakpoint-min-900 {
  @media screen and (min-width: $breakpoint-desktop-xs) {
    @content;
  }
}

// All screens that are min. 1024px
@mixin breakpoint-min-1024 {
  @media screen and (min-width: $breakpoint-desktop-s) {
    @content;
  }
}

// All screens that are min. 1200px
@mixin breakpoint-min-1200 {
  @media screen and (min-width: $breakpoint-desktop-m) {
    @content;
  }
}

// All screens that are min. 1440px
@mixin breakpoint-min-1440 {
  @media screen and (min-width: $breakpoint-desktop-l) {
    @content;
  }
}

// All screens that are min. 1920px
@mixin breakpoint-min-1920 {
  @media screen and (min-width: $breakpoint-desktop-xl) {
    @content;
  }
}

// All screens that are min 2400px
@mixin breakpoint-min-2400 {
  @media screen and (min-width: $breakpoint-desktop-xxl) {
    @content;
  }
}

// All screens that are max. 374px
@mixin breakpoint-max-374 {
  @media screen and (max-width: $breakpoint-mobile-m) {
    @content;
  }
}

// All screens that are max. 479px
@mixin breakpoint-max-479 {
  @media screen and (max-width: $breakpoint-mobile-l) {
    @content;
  }
}

// All screens that are max. 599
@mixin breakpoint-max-599 {
  @media screen and (max-width: calc(#{$breakpoint-mobile-xl} - 1px)) {
    @content;
  }
}

// All screens that are max. 719
@mixin breakpoint-max-719 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-xxs} - 1px)) {
    @content;
  }
}

// All screens that are max. 899px
@mixin breakpoint-max-899 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-xs} - 1px)) {
    @content;
  }
}

// All screens that are max. 1023px
@mixin breakpoint-max-1023 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-s} - 1px)) {
    @content;
  }
}

// All screens that are max. 1199px
@mixin breakpoint-max-1199 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-m} - 1px)) {
    @content;
  }
}

// All screens that are max. 1919px
@mixin breakpoint-max-1919 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-xl} - 1px)) {
    @content;
  }
}

// All screens that are max. 1339px
@mixin breakpoint-max-1339 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-l} - 1px)) {
    @content;
  }
}

// All screens that are max. 1439px
@mixin breakpoint-max-1439 {
  @media screen and (max-width: calc(#{$breakpoint-desktop-l} - 1px)) {
    @content;
  }
}
