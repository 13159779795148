@use 'colors';

/* Theme Colors */
// General
$color-text-default: colors.$palette-blue-dark-100;
$color-interactive-focus: colors.$palette-blue-light-100;
$color-interactive-error: colors.$palette-primary-100;
$color-interactive-success: colors.$palette-special-metrobus;
$color-interactive-link: colors.$palette-primary-100;

// Buttons
$color-button-secondary-active: colors.$palette-interactive-blue-light-a;
$color-button-primary-hover: colors.$palette-interactive-blue-dark-a;
$color-button-primary-focus: colors.$palette-interactive-blue-dark-a;
$color-button-primary-active: colors.$palette-interactive-blue-dark-b;
$color-button-cta-active: colors.$palette-interactive-primary-dark-a;

// Navigation
$color-navigation-backdrop: colors.$palette-blue-light-a;

/* Border */
$border-radius: 5px;
