@use 'src/assets/scss';
@use './shared-vals' as shared;

@mixin navigation-base {
  @include scss.reset-default();
  background-color: scss.$palette-primary-100;
  height: shared.$navigation-height-large;
  outline: 0;
  position: relative;
  transition: height shared.$transition-speed ease;
  transition-delay: shared.$transition-delay;
  z-index: scss.$z-index-navigation;

  @include scss.breakpoint-max-1199 {
    height: shared.$navigation-height-mobile;
  }

  @include scss.breakpoint-min-1200 {
    &.Small {
      height: shared.$navigation-height-small;
    }
  }
}

@mixin navigation-header {
  display: flex;
  flex-direction: row;
  height: shared.$navigation-height-large;
  margin: auto;
  max-width: scss.px-to-rem(1920);
  padding: 0 scss.px-to-rem(24);
  transition: height shared.$transition-speed ease;
  transition-delay: shared.$transition-delay;
  z-index: scss.$z-index-navigation-mega-menu;

  @include scss.breakpoint-max-1199 {
    height: shared.$navigation-height-mobile;
  }

  &.Small {
    @include scss.breakpoint-min-1200 {
      height: shared.$navigation-height-small;

      .LogoArea {
        .LogoContainer {
          // Sizes of the icon itself (51.8 x 41.04)
          height: scss.px-to-rem(41.04);
          width: scss.px-to-rem(51.8);
        }

        .Text {
          opacity: 0;
        }
      }
    }
  }
}

@mixin navigation-header-logo-area {
  align-items: center;
  display: flex;
  flex: 0;
  flex-direction: row;
  position: relative;
  transition: padding-top shared.$transition-speed ease, opacity shared.$transition-speed ease;
  transition-delay: shared.$transition-delay;
  z-index: 2;

  svg {
    color: scss.$palette-white-100;
    height: scss.px-to-rem(55);
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity shared.$transition-speed ease, transform shared.$transition-speed ease,
      left shared.$transition-speed ease;
    transition-delay: shared.$transition-delay;
  }
}

@mixin navigation-header-logo-container {
  height: scss.px-to-rem(55);
  position: relative;
  transition: width shared.$transition-speed ease, height shared.$transition-speed ease;
  transition-delay: shared.$transition-delay;
  width: scss.px-to-rem(212);
}

@mixin navigation-header-logo-contained-focused {
  border-radius: 2px;
  box-shadow: 0 0 0 2px scss.$palette-primary-100, 0 0 0 4px scss.$color-interactive-focus;
  outline: 0;
}

@mixin navigation-header-links-area {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 1;

  > a {
    @include scss.display-5(normal, normal, none);
    align-items: center;
    color: scss.$palette-white-100;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    text-decoration: none;

    &:hover span {
      @include scss.display-5(bold, normal, none);
    }

    span {
      padding: 0.5rem;
      text-align: center;
    }

    span::after {
      // This code reserves the space for the hover-effect
      @include scss.display-5(bold, normal, none);
      color: transparent;
      content: attr(title);
      display: block;
      height: 1px;
      overflow: hidden;
      visibility: hidden;
    }

    &:focus-visible {
      outline: none;

      span {
        border-radius: 1px;
        box-shadow: 0 0 0 1px scss.$color-interactive-focus, inset 0 0 0 2px scss.$color-interactive-focus;
      }
    }
  }
}

@mixin navigation-header-icons-area {
  align-items: center;
  display: flex;
  flex: 0;
  flex-direction: row;

  .HoverOnly {
    display: none;
  }

  button,
  a {
    @include navigation-button-style;
    border-radius: 5px;
    height: scss.px-to-rem(48);
    padding: 0;
    text-align: center;
    width: scss.px-to-rem(48);

    @include scss.breakpoint-max-1199 {
      display: none;
    }

    svg {
      height: 1.5rem;
      margin-right: 0;
      vertical-align: text-top;
      width: 1.5rem;
    }
  }

  .MobileOnly {
    display: none;

    @include scss.breakpoint-max-1199 {
      display: inline-block;
    }
  }
}

@mixin navigation-header-link {
  &:focus-visible {
    box-shadow: none;
  }
}

@mixin navigation-content {
  background-color: scss.$palette-primary-100;
  display: flex;
  flex-direction: column;
  max-width: shared.$drawer-base-width;
  min-width: shared.$drawer-base-width;
  overflow-y: auto;
  width: shared.$drawer-base-width;

  @include scss.breakpoint-max-374 {
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
  }
}

@mixin navigation-drawer {
  display: flex;
  flex-direction: column;

  &:global(.MuiModal-root) :global(.MuiPaper-root) {
    border: 0;
    overflow-x: hidden;
    width: shared.$drawer-base-width;

    @include scss.breakpoint-max-374 {
      width: 100vw;
    }
  }

  :global(.MuiButtonBase-root) {
    @include scss.display-3(bold);
    box-sizing: border-box;
    color: scss.$palette-white-100;
    padding: scss.px-to-rem(20) 3.5rem;
  }

  :global(.MuiListItem-root) {
    padding: 0;
  }

  li {
    margin-left: 0;
  }
}

@mixin navigation-drawer-header {
  align-items: center;
  background-color: scss.$palette-primary-100;
  display: flex;
  flex-direction: row;
  height: scss.px-to-rem(88);
  justify-content: space-between;
  max-height: scss.px-to-rem(88);
  min-height: scss.px-to-rem(88);
  padding-right: scss.px-to-rem(24);

  .IconsArea {
    display: flex;
    flex: 0;
    flex-direction: row;
    gap: scss.px-to-rem(8);
    justify-content: flex-end;

    a,
    button {
      @include navigation-button-style;
      height: scss.px-to-rem(40);
      padding: 0;
      width: scss.px-to-rem(40);

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: 0;
        max-height: scss.px-to-rem(16);
        max-width: scss.px-to-rem(16);
      }
    }

    .Red {
      background-color: scss.$palette-primary-100;
      border: 1px solid scss.$palette-primary-100;
      color: scss.$palette-white-100;

      &:hover {
        border: 1px solid scss.$palette-white-100;
      }
    }
  }
}

@mixin navigation-button-style {
  @include scss.color-transition;
  @include scss.display-6(bold);
  align-items: center;
  background-color: scss.$palette-white-100;
  border: 1px solid scss.$palette-white-100;
  border-radius: scss.px-to-rem(5);
  color: scss.$palette-primary-100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 scss.spacing-px-to-rem(22);
  text-transform: none;

  .HoverOnly {
    display: none;
  }

  &:hover {
    background-color: scss.$palette-primary-100;
    color: scss.$palette-white-100;

    .HoverOnly {
      display: initial;
    }

    .NotHover {
      display: none;
    }
  }

  &:focus-visible {
    border-radius: 8px;
    box-shadow: 0 0 0 2px scss.$palette-primary-100, 0 0 0 4px scss.$color-interactive-focus;
    color: scss.$palette-primary-100;
    outline: 0;
  }

  svg {
    margin-right: scss.spacing-px-to-rem(14);
  }
}
