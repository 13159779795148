@use 'src/assets/scss';

.VideoContainer {
  @include scss.default-width();
  display: flex;
  flex-direction: column;
  margin: auto;

  .VideoBox {
    @include scss.bubble-style;

    iframe,
    video {
      aspect-ratio: 16 / 9;
      border: 0;
      grid-area: content;
      width: 100%;
    }

    figcaption {
      grid-area: caption;
    }
  }

  &.Left {
    margin-left: 0;
  }

  &.Right {
    margin-right: 0;
  }

  &.Portrait {
    max-width: 100%;

    .VideoBox {
      $max-height: scss.px-to-rem(650);
      margin: 0 auto;
      max-width: calc(#{$max-height} / 16 * 9);

      iframe,
      video {
        aspect-ratio: 9 / 16;
        max-height: $max-height;
        max-width: 100%;
        width: auto;
      }
    }
  }
}
