@use 'src/assets/scss';

.SwitchPage {
  @include scss.default-width;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: scss.spacing-px-to-rem(150);
  padding-top: scss.spacing-px-to-rem(274);

  h1 {
    line-height: 100%;
    margin: 0 auto;
    padding-bottom: scss.spacing-px-to-rem(61);

    @include scss.breakpoint-max-719 {
      @include scss.display-4(bold);
    }
  }

  svg {
    color: scss.$palette-primary-100;
    height: auto;
    transform: rotate(45deg);
    width: 300px;

    @include scss.breakpoint-max-719 {
      height: auto;
      width: 200px;
    }
  }
}
