@use 'src/assets/scss';

/*
States:

| state       | w     | b-c   | l-c  | tick | glow | backbox |
| ---         | ---   | ---   | ---  | ---  | ---  | ---     |
| uc          | thin  | light | grey | none | no   | no      |
| uc:hover    | thick | dark  | grey | none | no   | yes     |
| uc:focus    | thick | light | grey | none | yes  | no      |
| uc:disabled | thin  | light | grey | none | no   | no      |
| c           | thick | light | red  | red  | no   | no      |
| c:hover     | thick | dark  | red  | red  | no   | no      |
| c:focus     | thick | light | red  | red  | yes  | no      |
| c:disabled  | thin  | light | grey | none | no   | no      |

  uc: unchecked
  c: checked
  w: wide font and border
  b-c: border-color
  l-c: label-color
 */

$border-width-thin: 1px;
$border-width-thick: 2px;
$border-color-light: scss.$palette-blue-dark-25;
$border-color-dark: scss.$palette-blue-dark-50;

// The checkbox icon, default state
.CheckboxIcon {
  background-color: scss.$palette-white-100;
  border: $border-width-thin solid $border-color-light;
  border-radius: 5px;
  box-sizing: border-box;
  height: scss.px-to-rem(24);
  width: scss.px-to-rem(24);

  // center the tick and the disabled tick
  svg {
    color: scss.$palette-primary-100;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.CheckboxWidget {
  &:global(.MuiFormControlLabel-root) {
    align-items: start;
    width: 100%;
  }

  // Label
  :global(.MuiTypography-root) {
    @include scss.display-6();
    align-items: baseline;
    color: scss.$palette-blue-dark-100;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 1rem;
    padding-top: scss.px-to-rem(3);

    p {
      @include scss.display-6(normal, normal, none);

      * {
        font-size: scss.font-factor(0.75);
      }
    }
  }

  :global(.MuiCheckbox-root) {
    padding: scss.spacing-px-to-rem(9) scss.px-to-rem(16) scss.px-to-rem(9) scss.px-to-rem(9);

    &:hover {
      background: none;
    }
  }

  // Add the backbox, but hide it as default
  .CheckboxIcon {
    position: relative;

    .BackBox {
      background-color: scss.$palette-blue-dark-8;
      border-radius: 1px;
      bottom: scss.px-to-rem(2);
      display: none;
      left: scss.px-to-rem(2);
      position: absolute;
      right: scss.px-to-rem(2);
      top: scss.px-to-rem(2);
    }
  }

  // Display the backbox, when hovering and not disabled
  &:hover:not(.Disabled) {
    .BackBox {
      display: inherit;
    }
  }

  // Label and border thick, when hover, focus or checked
  &:hover,
  &:focus-within,
  &.Checked {
    :global(.MuiTypography-root) {
      @include scss.display-6();
    }

    .CheckboxIcon {
      border-width: $border-width-thick;
    }

    .OptionalLabel {
      @include scss.form-element-optional();
    }
  }

  // Label red, when checked and not disabled
  // If disabled, it is grayed out by Mui anyway
  &.Checked {
    :global(.MuiTypography-root) {
      color: scss.$palette-primary-100;
    }
  }

  // Glow, when focused
  :focus-within {
    .CheckboxIcon {
      @include scss.interactive-focus;
    }
  }

  // The border should be dark, when hovering
  &:hover {
    .CheckboxIcon {
      border-color: $border-color-dark;
    }
  }

  // active via mouse
  &:active,
  // support active via keyboard
  :global(.PrivateSwitchBase-input):active + .CheckboxIcon {
    .Tick {
      color: scss.$palette-blue-dark-25;
      display: inherit;
    }
  }

  // Disable ticks as default
  .Tick {
    display: none;
  }

  // Red tick should be visible when:
  // - checked and not disabled
  &.Checked:not(.Disabled) {
    .Tick {
      display: inherit;
    }
  }

  // Gray tick should be visible when:
  // - checked and disabled
  &.Checked.Disabled {
    .Tick {
      color: scss.$palette-blue-dark-25;
      display: initial;
    }
  }

  &.Disabled,
  &.ReadOnly {
    pointer-events: none;
  }

  &.ReadOnly {
    :global(.MuiTypography-root.Mui-disabled) {
      color: scss.$palette-blue-dark-100;
    }

    .CheckboxIcon {
      background: scss.$palette-blue-dark-3;
      border: 2px solid scss.$palette-blue-dark-25;

      .Tick {
        color: scss.$palette-blue-dark-100;
      }
    }
  }

  &.Error {
    .CheckboxIcon {
      border: 2px solid scss.$color-interactive-error;
    }
  }

  .OptionalLabel {
    @include scss.label;
    color: scss.$palette-blue-dark-50;
  }
}
