@use 'src/assets/scss';

.Modal {
  align-items: center;
  background-color: rgba(scss.$palette-blue-dark-100, 0.6);
  display: flex;
  justify-content: center;
  overflow: auto;

  &:global(.warning) {
    background-color: rgba(scss.$palette-primary-100, 0.6);
  }

  .ModalContentWrapper {
    display: flex;
    flex-direction: column;
    gap: scss.spacing-px-to-rem(10);
    margin: scss.$spacing-2;
    max-height: 100%;
    max-width: scss.px-to-rem(957);
    outline: 0;

    .CloseButton {
      align-self: flex-end;
      justify-self: flex-end;
      margin: 0;
      min-width: auto;
      padding: 4px;

      &:focus {
        @include scss.interactive-focus();
      }

      svg {
        color: scss.$palette-white-100;
      }
    }

    .ModalContent {
      background-color: scss.$palette-white-100;
      display: flex;
      isolation: auto;

      position: relative;

      &:focus {
        outline: none;
      }

      &::after {
        border-color: transparent transparent scss.$palette-blue-dark-25 transparent;
        border-style: solid;
        border-width: 0 0 scss.$spacing-3 scss.$spacing-3;
        bottom: 0;
        content: '';
        height: 0;
        position: absolute;
        right: 0;
        transform: rotate(180deg) translateY(-100%);
        width: 0;
      }

      .ModalImage {
        align-items: center;
        background-color: scss.$palette-blue-light-100;
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
        max-width: scss.px-to-rem(278);

        @include scss.breakpoint-max-599 {
          display: none;
        }

        svg {
          height: auto;
          margin: scss.$spacing-1;
          width: 100%;
        }

        img {
          flex: 1 0 auto;
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }

      .ModalInfo {
        padding: scss.$spacing-2;

        .Title {
          @include scss.copy-5(bold);
          color: scss.$palette-primary-100;
          margin: 0;
        }

        .Headline {
          @include scss.display-3(bold);
          margin: 0;
        }

        :global(.MuiButton-root) {
          margin: scss.$spacing-1 0 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
