@use '/src/assets/scss';

.LinkListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: scss.spacing(2);
  justify-content: center;
  margin: scss.spacing(3) 0;
}

.LogoSocialMediaContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: scss.spacing(3);
  justify-content: space-between;

  @include scss.breakpoint-max-719 {
    justify-content: space-around;
  }

  .Logo {
    color: scss.$palette-primary-100;
    min-width: 240px;
  }

  .SocialMediaContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &.OnlyLogo {
    justify-content: center;
  }
}
