@use 'src/assets/scss';
@use 'sass:math';

$svg-height: 16;
$margin-popover-above: scss.px-to-rem(14 + math.div($svg-height, 2));
$margin-popover-below: scss.px-to-rem(14 - math.div($svg-height, 2));
$margin-triangle-above: scss.px-to-rem(-23 - math.div($svg-height, 2));
$margin-triangle-below: scss.px-to-rem(-23 + math.div($svg-height, 2));

.InfoButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  position: relative;

  // info i svg
  svg {
    color: scss.$palette-violett-100;
    height: scss.spacing-px-to-rem(16);
    transform: translateY(-50%);
    vertical-align: middle;
    width: scss.spacing-px-to-rem(16);
  }

  &:hover,
  &:active {
    svg {
      color: scss.$palette-primary-100;
    }
  }

  // Click area
  &::after {
    border-radius: 100%;
    content: '';
    height: 48px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 48px;
  }

  // :focus
  &:focus:active {
    outline: none;
  }

  &:focus-visible {
    outline: none;

    svg {
      @include scss.interactive-focus;
      border-radius: 100%;
      box-shadow: 0 0 0 1px scss.$palette-white-100, 0 0 0 3px scss.$color-interactive-focus;
    }
  }

  &.Open {
    // Info i svg
    svg {
      @include scss.interactive-focus;
      border-radius: 100%;
      box-shadow: 0 0 0 1px scss.$palette-white-100, 0 0 0 3px scss.$color-interactive-focus;
      color: scss.$palette-blue-dark-100;
    }

    // Triangle of the bubble, above i
    &::before {
      animation: ease 300ms fadein;
      background: linear-gradient(-45deg,
      scss.$palette-blue-dark-100 0%,
      scss.$palette-blue-dark-100 50%,
      transparent 50%,
      transparent 100%);
      content: '';
      height: scss.px-to-rem(16);
      left: 50%;
      position: absolute;
      top: $margin-triangle-above;
      transform: translateX(-50%) rotate(45deg);
      width: scss.px-to-rem(16);

      @keyframes fadein {
        0% {
          opacity: 0;
        }

        25% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    // Triangle of the bubble, below i
    &.UpperThird::before {
      background: linear-gradient(-45deg,
      transparent 0%,
      transparent 50%,
      scss.$palette-blue-dark-100 50%,
      scss.$palette-blue-dark-100 100%);
      bottom: $margin-triangle-below;
      top: initial;
    }
  }
}

.InfoButtonPopoverPaper {
  background-color: transparent !important;
  box-shadow: none !important;
  overflow-y: hidden;

  @include scss.breakpoint-max-479 {
    border-radius: 0 !important;
    left: 0 !important;
    max-width: initial !important;
    right: 0 !important;
  }
}

.InfoButtonPopover {
  @include scss.copy-6;
  background: scss.$palette-blue-dark-100;
  border-radius: scss.px-to-rem(5);
  color: scss.$palette-white-100;
  margin-bottom: $margin-popover-above;
  max-width: scss.px-to-rem(358);
  padding: 0.5em 1em;

  @include scss.breakpoint-max-479 {
    border-radius: 0;
  }

  &.UpperThird {
    margin-bottom: 0;
    margin-top: $margin-popover-below;
  }
}
