@use 'src/assets/scss';

.CheckboxGroupWidget {
  display: flex;
  flex-direction: column;

  .GroupLabelContainer {
    align-items: baseline;
    display: flex;
  }

  .CheckboxGroup {
    @include scss.column-count();
    margin-bottom: scss.$spacing-1;
    width: 100%;
  }

  .CheckboxGroupLabel {
    @include scss.label-external();
  }

  .ErrorMessage {
    @include scss.error-message;
  }

  .OptionalLabel {
    @include scss.form-element-optional();
    margin-left: 1rem;
  }
}
