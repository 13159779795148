@use 'src/assets/scss';
@use 'sass:math';

$label-translate-x: scss.px-to-rem(23);
$label-translate-y: scss.px-to-rem(16);
$label-focused-translate-x: scss.px-to-rem(25);
$label-focused-translate-y: scss.px-to-rem(8);
$label-focused-factor: 0.75;
$inner-spacing: 5px;
$inner-spacing-no-border: 3px;

.TextInputComponentWrapper {
  margin-bottom: scss.$spacing-1;
  position: relative;

  &.Hidden {
    opacity: 0.5;
  }

  &.DateTime {
    :global(.MuiFilledInput-root) {
      // Transform placeholder pattern to lower case (firefox)
      :global(.MuiFilledInput-input) {
        text-transform: lowercase;

        // Hide placeholder when label is above
        &[value=''] {
          color: transparent;
        }
      }
    }

    :global(.MuiTextField-root) {
      // Show placeholder when input is focused and value is empty
      &:focus-within {
        :global(.MuiFilledInput-input)[value=''] {
          color: scss.$color-text-default;
        }
      }

      // Hide clear button in firefox
      + .TextFieldIcon {
        border-radius: 0;
        right: scss.px-to-rem(5);
        z-index: 11;
      }
    }
  }

  .HelperText {
    @include scss.helper-message;
  }

  .ErrorText {
    @include scss.error-message;
  }

  &.MuiDatePicker {
    :global(.MuiInputAdornment-root) {
      margin: 0;
      position: absolute;
      right: scss.px-to-rem(7);
    }

    :global(.MuiButtonBase-root) {
      @include scss.icon-button-style();
      bottom: auto;
      margin: 0;
      width: 2.5rem;
      z-index: 11;

      &:hover {
        background-color: transparent;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}

.TextInputComponent {
  &:global(.MuiTextField-root) {
    width: 100%;
  }

  // Label
  :global(.MuiInputLabel-root) {
    @include scss.display-6(normal, normal, none);
    color: scss.$palette-blue-dark-100;
    transform: translate($label-translate-x, $label-translate-y);
    z-index: 2;

    // Label, when input is filled or focused
    &:global(.MuiFormLabel-filled),
    &:global(.Mui-focused) {
      @include scss.display-6(bold, normal, none);
      color: scss.$palette-blue-dark-100;
      transform: translate($label-focused-translate-x, $label-focused-translate-y) scale($label-focused-factor);

      .Optional {
        $scale-error: math.div(15.75, 16);
        $font-size: scss.font-factor(0.75);
        font-size: max(#{$font-size}, #{16}px);
        letter-spacing: #{0.02 * $scale-error}em;
      }
    }

    .Optional {
      $font-size: scss.font-factor(0.75 * $label-focused-factor);
      @include scss.display-6(bold, normal, none);
      color: scss.$palette-blue-dark-50;
      font-size: max(#{$font-size}, #{16 * $label-focused-factor}px);
      font-style: italic;
      margin-left: scss.px-to-rem(13);
      transition: font-size 200ms ease;
    }
  }

  // "required"-asterisk
  :global(.MuiInputLabel-asterisk) {
    display: none;
  }

  :global(.MuiFilledInput-input::-webkit-datetime-edit) {
    transition: opacity 0.3s ease;
  }

  :global(.MuiFilledInput-input::-webkit-date-and-time-value) {
    text-align: left !important;
  }

  :global(.MuiInputLabel-root:not(.MuiInputLabel-shrink) + .MuiFilledInput-root) {
    :global(.MuiFilledInput-input::-webkit-datetime-edit) {
      opacity: 0 !important;
    }
  }

  // Input wrapper, remove background
  :global(.MuiFilledInput-root),
  :global(.MuiFilledInput-root):hover,
  :global(.MuiFilledInput-root.Mui-disabled),
  :global(.MuiFilledInput-root.Mui-focused) {
    background-color: scss.$palette-white-100;
    padding-right: 0;
  }

  // Remove bottom lines
  :global(.MuiFilledInput-root::after),
  :global(.MuiFilledInput-root::before),
  :global(.MuiFilledInput-root:hover:not(.Mui-disabled)::before) {
    border-bottom: 0;
  }

  // Input
  :global(.MuiFilledInput-input) {
    @include scss.copy-6();
    border: 2px solid scss.$palette-blue-dark-25;
    border-radius: 5px;
    box-sizing: border-box;
    color: scss.$palette-blue-dark-100;
    height: scss.px-to-rem(56);
    line-height: 100%;
    padding-left: scss.px-to-rem(22);
    padding-right: scss.px-to-rem(22);
    z-index: 1;

    /* remove arrow keys forChrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* remove arrow keys for Firefox */
    &[type='number'] {
      appearance: textfield;
    }
  }

  :global(.MuiFilledInput-input::-webkit-calendar-picker-indicator) {
    height: scss.px-to-rem(24);
    opacity: 0 !important;
    position: absolute;
    right: scss.px-to-rem(20);
    top: scss.px-to-rem(16);
    width: scss.px-to-rem(24);
  }

  // Helper and error text
  :global(.MuiFormHelperText-root) {
    @include scss.error-message;
  }

  // Following states exist
  // - :hover
  // - :active
  // - :focus
  // - .Disabled
  // - :filled
  // - .ReadOnly
  // - .Error
  // - .Success
  // - .Multiline
  // - inEditMode (just to show hidden input field in edit mode)

  &.inEditMode {
    :global(.MuiFilledInput-input) {
      appearance: default;
    }
  }

  // Attribute border width is 0 for the input itself, when .Multiline
  &.Multiline {
    :global(.MuiFilledInput-input) {
      border-width: 0;
      padding-left: 0;
      padding-right: scss.px-to-rem(22);
    }

    :global(.MuiFilledInput-root) {
      align-items: flex-start; // Text should start on top
      border: 2px solid scss.$palette-blue-dark-25;
      border-radius: 5px;
      min-height: scss.px-to-rem(143);
      padding-left: scss.px-to-rem(21);
    }
  }

  // Attribute border color is dark grey, when :hover and not .Error nor .Success nor :focus
  &:hover:not(.Error):not(.Success):not(:focus-within):not(.Disabled):not(.ReadOnly) {
    :global(.MuiFilledInput-input) {
      border-color: scss.$palette-blue-dark-50;
    }

    &.Multiline {
      :global(.MuiFilledInput-root) {
        border-color: scss.$palette-blue-dark-50;
      }
    }

    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-blue-dark-100;
      }
    }
  }

  // Attribute border color is red, when .Error
  // Attribute value text color is red, when .Error
  &.Error {
    :global(.MuiFilledInput-input) {
      border-color: scss.$color-interactive-error;
      color: scss.$color-interactive-error;
    }

    &.Multiline {
      :global(.MuiFilledInput-root) {
        border-color: scss.$color-interactive-error;
        color: scss.$color-interactive-error;
      }
    }
  }

  // Attribute border color is green, when .Success and not .Error
  &.Success:not(.Error) {
    :global(.MuiFilledInput-input) {
      border-color: scss.$color-interactive-success;
    }

    &.Multiline {
      :global(.MuiFilledInput-root) {
        border-color: scss.$color-interactive-success;
      }
    }
  }

  // Attribute helper text width should be width, when .Error or .Success
  &.Error,
  &.Success {
    :global(.MuiFormHelperText-root) {
      @include scss.display-7(bold);
    }

    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-blue-dark-100;
      }
    }
  }

  // Attribute border color is blue, when :focus
  &:focus-within {
    :global(.MuiFilledInput-input) {
      border-color: scss.$color-interactive-focus;
      color: scss.$color-text-default;
    }

    &.Multiline {
      :global(.MuiFilledInput-root) {
        border-color: scss.$color-interactive-focus;
      }
    }

    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-blue-dark-100;
      }
    }
  }

  // Attribute label color is grayed out, when .Disabled
  &.Disabled {
    :global(.MuiInputLabel-root) {
      color: scss.$palette-blue-dark-50;
    }

    :global(.MuiFilledInput-root) {
      pointer-events: none;
    }

    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-blue-dark-50;
      }
    }
  }

  // Attribute value text color is still dark grey, when .ReadOnly
  // Attribute background color is a light grey, when .ReadOnly
  &.ReadOnly {
    :global(.MuiFilledInput-root) {
      background-color: scss.$palette-blue-dark-3;
    }

    :global(.MuiFilledInput-input) {
      // Remove the "gray out"
      -webkit-text-fill-color: inherit;
    }

    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-primary-100;
      }
    }
  }

  // Attribute label width is wide, when hover, and not .Disabled or .ReadOnly
  &:hover:not(.Disabled):not(.ReadOnly) {
    :global(.MuiInputLabel-root) {
      @include scss.display-6(bold, normal, none);
    }
  }

  // A light grey back box should appear, when :hover and not .Disabled, .ReadOnly, :focus or :active
  &:hover:not(.Disabled):not(.ReadOnly):not(:focus-within):not(:active) {
    :global(.MuiFilledInput-root::before) {
      background-color: scss.$palette-blue-dark-8;
      bottom: $inner-spacing;
      height: auto;
      left: $inner-spacing;
      right: $inner-spacing;
      top: $inner-spacing;
      width: auto;
    }

    + .TextFieldIcon {
      background: scss.$palette-blue-dark-8;
    }

    // In multiline configuration, positioning is inside the border
    // So we have to use $inner-spacing-no-border
    &.Multiline {
      :global(.MuiFilledInput-root::before) {
        bottom: $inner-spacing-no-border;
        left: $inner-spacing-no-border;
        right: $inner-spacing-no-border;
        top: $inner-spacing-no-border;
      }
    }
  }

  // A dark grey back box should appear, when :active and not .Disabled
  &:active:not(.Disabled):not(.ReadOnly) {
    :global(.MuiFilledInput-root::before) {
      background-color: scss.$palette-blue-dark-25;
      bottom: $inner-spacing;
      height: auto;
      left: $inner-spacing;
      right: $inner-spacing;
      top: $inner-spacing;
      width: auto;
      z-index: 0;
    }

    + .TextFieldIcon {
      background: scss.$palette-blue-dark-25;
    }

    // In multiline configuration, positioning is inside the border
    // So we have to use $inner-spacing-no-border
    &.Multiline {
      :global(.MuiFilledInput-root::before) {
        bottom: $inner-spacing-no-border;
        left: $inner-spacing-no-border;
        right: $inner-spacing-no-border;
        top: $inner-spacing-no-border;
      }
    }
  }

  :global(.MuiFilledInput-root) {
    position: relative;
  }

  + .TextFieldIcon {
    align-items: center;
    background: scss.$palette-white-100;
    border-radius: 50%;
    display: inline-flex;
    height: scss.px-to-rem(40);
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: scss.$spacing-05;
    top: scss.px-to-rem(8);
    width: scss.px-to-rem(40);

    svg {
      width: scss.px-to-rem(20);

      * {
        fill: scss.$palette-primary-100;
      }
    }
  }

  &.Filled:not(.Success):not(.Error) {
    + .TextFieldIcon {
      svg * {
        fill: scss.$palette-primary-100;
      }
    }
  }
}
