@use 'src/assets/scss';

.imageEl {
  height: auto;
  margin: auto;
  max-width: 100%;
  width: 100%;
}

.Scalable,
.ImageLink {
  @include scss.image-hover();
  @include scss.image-wrapper-states();
  @include scss.reset-anchor-styles();
  cursor: pointer;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:focus {
    @include scss.interactive-focus();
  }

  svg {
    height: scss.px-to-rem(44);
    width: scss.px-to-rem(44);
  }
}

.ResponsiveImage {
  @include scss.bubble-style;
  max-width: 100%;
  width: 100%;

  > a,
  > button,
  > img {
    grid-area: content;
  }

  figcaption {
    grid-area: caption;
  }
}

.Centered {
  text-align: center;
}

.ScaledImageView {
  background: rgba(scss.$palette-black-100, 0.5);
  height: 100%;
  position: relative;
  width: 100%;

  &.ImageHorizontalAnchor {
    .ButtonImageWrapper,
    .imageEl {
      height: auto;
      max-height: none;
      max-width: 100%;
      width: 100%;
    }
  }

  &.ImageVerticalAnchor {
    .ButtonImageWrapper,
    .imageEl {
      height: 100%;
      max-height: 100%;
      max-width: none;
      width: auto;
    }
  }

  .ScaledImageColumn {
    .ButtonImageWrapper {
      left: 50%;
      max-height: calc(100% - 4rem);
      max-width: calc(100% - 4rem);
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .ScaledImageCloseButton {
      @include scss.round-icon-button;
      height: scss.px-to-rem(36);
      padding: scss.px-to-rem(8);
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: scss.px-to-rem(36);
      z-index: 1;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
