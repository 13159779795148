@use 'colors';
@use 'fonts';
@use 'sass:math';
@use 'theme';
@use 'breakpoints';

// Adds the focus state to the interactive element.
// The outline is provided for windows users with high contrast mode.
// See https://stackoverflow.com/questions/52589391/css-box-shadow-vs-outline
@mixin interactive-focus() {
  box-shadow: 0 0 0 1px colors.$palette-white-100, 0 0 0 4px theme.$color-interactive-focus;
  outline: 3px dashed transparent;
  outline-offset: 1px;
}

@mixin interactive-focus-inset() {
  box-shadow: inset 0 0 0 3px theme.$color-interactive-focus, inset 0 0 0 4px colors.$palette-white-100;
  outline: 3px dashed transparent;
  outline-offset: 1px;
  padding-bottom: 4px;
  padding-top: 4px;
}

// Transition used for color transitions.
// Extracted from the MuiButton
@mixin color-transition() {
  transition-delay: 0ms, 0ms, 0ms, 0ms;
  transition-duration: 250ms, 250ms, 250ms, 250ms;
  transition-property: background-color, box-shadow, border-color, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1),
    cubic-bezier(0.4, 0, 0.2, 1);
}

// Cuts the text when too long and adds an ellipsis
@mixin text-ellipsis() {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Remove the unit of a length
// @param {Number} $number - Number to remove unit from
// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// Converts px values to rem
@function px-to-rem($px) {
  @return math.div($px, strip-unit(fonts.$font-size-base-default)) + rem;
}

// Calculates the width in percent depending on the given column count
@function widget-column-width($columns) {
  @return math.div(100%, 12) * $columns;
}

// Resets the styles of an anchor element including pseudo-states
@mixin reset-anchor-styles() {
  animation: unset;
  border: unset;
  border-image: unset;
  color: unset;
  padding-bottom: unset;
  text-decoration: unset;

  &:hover {
    animation: unset;
    border-color: unset;
  }

  &:active {
    animation: none;
    border-color: unset;
    border-image: unset;
    color: unset;
  }

  &:focus-visible {
    animation: none;
    border-color: unset;
    border-image: unset;
    border-radius: unset;
    color: unset;
  }
}

// Resets the styles of p, a and list (ul, ol) elements
@mixin reset-default() {
  p {
    font-family: unset;
    font-size: unset;
    font-style: unset;
    font-weight: unset;
    letter-spacing: unset;
  }

  a {
    @include reset-anchor-styles();
  }

  ol,
  ul {
    list-style: none;

    li {
      margin-left: unset;
      padding-left: unset;

      &::marker {
        color: unset;
      }
    }
  }
}

$font-size-clamp-factor: math.div(strip-unit(fonts.$font-size-base-900), strip-unit(fonts.$font-size-base-1200));

// Mixin to add column count to checkbox-, radio- group container
@mixin column-count() {
  column-count: 1;
  // allow elements to be balanced at number 2 or 3
  orphans: 1;
  widows: 1;

  &.Column-2 {
    column-count: 2;

    @include breakpoints.breakpoint-max-479 {
      column-count: 1;
    }
  }

  &.Column-3 {
    column-count: 3;

    @include breakpoints.breakpoint-max-719 {
      column-count: 2;
    }
    @include breakpoints.breakpoint-max-479 {
      column-count: 1;
    }
  }

  &.Column-4 {
    column-count: 4;

    @include breakpoint-max-1023 {
      column-count: 3;
    }

    @include breakpoints.breakpoint-max-719 {
      column-count: 2;
    }
    @include breakpoints.breakpoint-max-479 {
      column-count: 1;
    }
  }
}
