@use 'src/assets/scss';

.TextWidget {
  @include scss.default-width();

  &.TextWidgetSmall {
    p,
    span:not(:global(.scrivito_editing_marker_title)):not(:global(.scrivito_editing_marker)) {
      @include scss.copy-6;
    }

    a,
    a span:not(:global(.scrivito_editing_marker_title)):not(:global(.scrivito_editing_marker)) {
      @include scss.copy-6(bold);
    }
  }

  &.TextWidgetGrey {
    background-color: scss.$palette-blue-dark-3;
    padding: scss.$spacing-1;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  p {
    overflow-wrap: break-word;
  }
}
