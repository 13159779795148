@use 'src/assets/scss';

.RadioFormControl:global(.MuiFormControl-root) {
  margin-bottom: scss.$spacing-1;
  width: 100%;

  .RadioGroup {
    @include scss.column-count();
    display: block;
    width: 100%;
  }
}

.RadioGroupLabel {
  @include scss.label-external();
}
