@use 'src/assets/scss';

$transition-speed: 350ms;
$transition-delay: 100ms;
$navigation-height-large: 9.25rem;
$navigation-height-small: scss.px-to-rem(71);
$navigation-height-mobile: scss.px-to-rem(88);

$drawer-base-width: scss.px-to-rem(390);
$drawer-sub-sub-width: scss.px-to-rem(390 * 2);
$drawer-color-transition-speed: 200ms;
