@use 'src/assets/scss';

.LandingPageHero {
  img,
  video {
    aspect-ratio: 5 / 2;
    margin-bottom: scss.spacing(4);
    object-fit: cover;
    width: 100%;
  }
}
