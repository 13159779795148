@use 'src/assets/scss';

.WizardFooter {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: scss.px-to-rem(48);
  padding-top: scss.$spacing-2;

  @include scss.breakpoint-max-899 {
    flex-direction: column-reverse;
    gap: scss.$spacing-05;
  }

  .PrevButton,
  .NextButton {
    white-space: nowrap;

    @include scss.breakpoint-max-899 {
      width: 100%;
    }
  }

  .FooterSpace {
    flex: 1;

    @include scss.breakpoint-max-899 {
      display: none;
    }
  }
}
