@use 'src/assets/scss';

$inner-spacing: 5px;

.DropdownContainer {
  margin-bottom: scss.$spacing-1;

  // default label without value
  :global(.MuiFormLabel-root.MuiInputLabel-root) {
    @include scss.display-6();
    color: scss.$palette-blue-dark-100;
    top: 50%;
    transform: translate(scss.$spacing-1, -50%) scale(1);

    .OptionalLabel {
      @include scss.form-element-optional();
      margin-left: scss.px-to-rem(13);
    }
  }

  .DropdownWidget {
    position: relative;

    &::before {
      border-radius: 1px;
      bottom: $inner-spacing;
      content: '';
      height: calc(100% - #{$inner-spacing} - #{$inner-spacing});
      left: $inner-spacing;
      pointer-events: none;
      position: absolute;
      right: $inner-spacing;
      top: $inner-spacing;
      width: calc(100% - #{$inner-spacing} - #{$inner-spacing});
    }

    &:global(.MuiInputBase-root) {
      background-color: scss.$palette-white-100;
      height: scss.px-to-rem(56);
      text-align: left;

      :global(.MuiSelect-select) {
        @include scss.copy-6();
        padding: scss.$spacing-1 scss.$spacing-25 scss.$spacing-1 scss.$spacing-1 !important;
        z-index: 2;
      }

      &.HasLabel {
        :global(.MuiSelect-select) {
          padding: scss.spacing(1.3) scss.$spacing-25 scss.$spacing-05 scss.$spacing-1 !important;
        }
      }

      &.Optional {
        :global(.MuiSelect-select) {
          padding: scss.spacing(1.3) scss.$spacing-4 scss.$spacing-05 scss.$spacing-1 !important;
        }
      }

      &.ItemSelected {
        color: scss.$palette-blue-dark-100;
      }

      :global(.MuiSelect-icon) {
        right: scss.$spacing-1;
        top: auto;
        width: scss.px-to-rem(17);

        path {
          fill: scss.$palette-primary-100;
        }

        &:global(.MuiSelect-iconOpen) {
          path {
            fill: scss.$palette-blue-dark-100;
          }
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border: 2px solid scss.$palette-blue-dark-25;
      }

      &.IsOpen {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: scss.$palette-blue-dark-50;
        }
      }

      &:hover:not(:global(.Mui-disabled)) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: scss.$palette-blue-dark-50;
        }

        &::before {
          background: scss.$palette-blue-dark-8;
        }
      }

      &:global(.Mui-focused):not(.IsOpen) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border: 0;
          box-shadow: 0 0 0 1px scss.$palette-blue-light-100, inset 0 0 0 2px scss.$palette-blue-light-100;
        }
      }

      &:global(.Mui-error) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: scss.$color-interactive-error;
        }
      }

      &:global(.Mui-disabled) {
        :global(.MuiSelect-icon) {
          path {
            fill: scss.$palette-blue-dark-50;
          }
        }

        :global(.MuiOutlinedInput-notchedOutline) {
          opacity: 0.35;
        }
      }
    }
  }

  .ErrorText {
    @include scss.error-message;
  }

  :global(.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink) {
    @include scss.label(bold, normal);
    color: scss.$palette-blue-dark-100;
    top: 50%;
    transform: translate(scss.$spacing-1, calc(-50% - #{scss.$spacing-05})) scale(1);
  }

  :global(.MuiFormLabel-root.MuiInputLabel-root.Mui-focused) {
    color: scss.$palette-blue-dark-100;
  }

  .Placeholder {
    @include scss.copy-6();
    color: scss.$palette-blue-dark-50;
  }

  .ClearButton {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    height: scss.px-to-rem(17);
    justify-content: center;
    position: absolute;
    right: scss.$spacing-25;
    top: 50%;
    transform: translateY(-50%);
    width: scss.px-to-rem(17);
    z-index: 10;

    svg {
      color: scss.$palette-blue-dark-100;
      height: scss.px-to-rem(14);
      width: scss.px-to-rem(14);
    }

    // do not break hover styling when above clear button
    &:hover {
      + .DropdownWidget {
        &:global(.MuiInputBase-root):not(:global(.Mui-disabled)) {
          :global(.MuiOutlinedInput-notchedOutline) {
            border-color: scss.$palette-blue-dark-50;
          }

          &::before {
            background: scss.$palette-blue-dark-8;
          }
        }
      }
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &:hover:not(.Disabled) :global(.MuiFormLabel-root.MuiInputLabel-root):not(:global(.MuiInputLabel-shrink)) {
    @include scss.display-6(bold);
  }

  &.Disabled {
    :global(.MuiFormLabel-root.MuiInputLabel-root) {
      opacity: 0.35;
    }
  }
}

.DropdownMenu {
  :global(.MuiPaper-root) {
    border: 2px solid scss.$palette-blue-dark-25;
    box-shadow: none;

    :global(.MuiList-root) {
      padding: scss.px-to-rem(8);

      .DropdownMenuItem {
        @include scss.copy-6();
        background: none;
        border-radius: 1px;
        height: auto;
        white-space: normal;

        &:global(.Mui-selected) {
          @include scss.copy-6(bold);
          background: none;
          color: scss.$palette-primary-100;
        }

        &:focus {
          @include scss.interactive-focus-inset();
          background-color: scss.$palette-blue-dark-3;
          padding-bottom: 6px;
          padding-top: 6px;
        }

        &:focus-within {
          background: none;
        }

        &:hover {
          background-color: scss.$palette-blue-dark-8;
        }

        &:active {
          background: scss.$palette-blue-dark-3;
        }
      }
    }
  }
}
