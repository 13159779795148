@use 'src/assets/scss';

.PressReleasePreview {
  display: flex;
  list-style: none;
  margin-bottom: scss.$spacing-2;
  margin-left: 0;

  a {
    @include scss.reset-anchor-styles();
    display: flex;
    flex-flow: row;
    margin: calc(#{scss.$spacing-05} * -1);
    padding: scss.$spacing-05;
    width: 100%;

    @include scss.breakpoint-max-599 {
      flex-wrap: wrap;
    }

    &:hover {
      @include scss.color-transition();
      background-color: scss.$palette-blue-dark-3;

      .PressReleaseHeadline {
        @include scss.color-transition();
        color: scss.$palette-primary-100;
      }
    }

    &:active {
      .PressReleaseHeadline {
        @include scss.color-transition();
        color: scss.$palette-primary-dark-100;
      }
    }

    &:focus-visible {
      border-radius: scss.$border-radius;
    }

    time {
      @include scss.display-7(bold, normal, normal);
      color: scss.$palette-primary-100;
      line-height: 170%;
      min-width: scss.px-to-rem(96);
    }
  }

  .PressReleaseHeadlines {
    display: flex;
    flex-flow: column;

    .PressReleaseSubHeadline {
      @include scss.display-7(bold, normal, normal);
      line-height: 170%;
    }

    .PressReleaseHeadline {
      @include scss.display-4(bold, normal, normal);
      line-height: 150%;
    }
  }
}
