@use 'colors';
@use 'fonts';
@use 'spacings';
@use 'helper';
@use 'theme';
@use 'animations';
@use 'breakpoints';
@use 'z-index' as zIndex;

// Button style for link button on hero and big teaser
@mixin link-button-style() {
  @include fonts.display-5(bold, normal, none);
  align-items: center;
  background-color: colors.$palette-white-100;
  color: colors.$palette-primary-100;
  display: flex;
  justify-content: center;
  padding: helper.px-to-rem(3) helper.px-to-rem(24);

  &::before {
    border: 8px solid transparent;
    border-bottom-color: colors.$palette-primary-100;
    border-right-color: colors.$palette-primary-100;
    bottom: 0;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
  }
}

@mixin icon-button-style() {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 50%;
  bottom: spacings.$spacing-1;
  box-shadow: none;
  color: colors.$palette-primary-100;
  display: flex;
  height: 2.5rem;
  justify-content: center;

  &:hover {
    background-color: colors.$palette-blue-dark-8;
    color: colors.$palette-blue-dark-100;
    cursor: pointer;
  }

  &:active {
    background-color: colors.$palette-blue-dark-25;
    color: colors.$palette-blue-dark-100;
  }

  &:focus-visible {
    @include helper.interactive-focus();
    background-color: colors.$palette-blue-dark-8;
    color: colors.$palette-blue-dark-100;
  }
}

// Icon button states for the file download
@mixin download-icon-states() {
  align-items: center;
  background: none;
  color: theme.$color-text-default;
  display: flex;
  max-width: 100%;
  padding: 0;
  text-transform: none;

  &:hover {
    @include helper.color-transition();
    background: none;
    color: colors.$palette-primary-100;

    .DownloadIcon {
      @include helper.color-transition();
      background: colors.$palette-primary-100;
      color: colors.$palette-white-100;
    }
  }

  &:active {
    @include helper.color-transition();
    background: none;
    color: colors.$palette-primary-dark-100;

    .DownloadIcon {
      @include helper.color-transition();
      background: colors.$palette-primary-dark-100;
      color: colors.$palette-white-100;
    }
  }

  &:focus {
    color: colors.$palette-primary-dark-100;

    .DownloadIcon {
      @include helper.color-transition();
      @include helper.interactive-focus();
      background: colors.$palette-primary-dark-100;
      color: colors.$palette-white-100;
    }
  }

  .FileIcon {
    align-items: center;
    display: flex;
  }

  .DownloadIcon {
    align-items: center;
    border-radius: 50%;
    color: colors.$palette-primary-100;
    display: flex;
    flex-shrink: 0;
    height: helper.px-to-rem(44);
    justify-content: center;
    width: helper.px-to-rem(44);
  }

  svg {
    height: 1.333rem;
    width: auto;
  }
}

// Flyout configuration for the autocomplete flyout
@mixin flyout-popper-style() {
  &:global([data-popper-placement='top']) {
    > * {
      margin-bottom: 6px;
    }
  }

  &:global([data-popper-placement='bottom']) {
    > * {
      margin-top: 6px;
    }
  }

  :global(.MuiAutocomplete-paper) {
    background-color: transparent;
    border-radius: 0 0 theme.$border-radius theme.$border-radius;
    box-shadow: none;
  }
}

// Style for the autocomplete flyout
@mixin flyout-style() {
  background-color: colors.$palette-white-100;
  border: 2px solid colors.$palette-blue-dark-25;
  border-radius: theme.$border-radius;
  padding-bottom: spacings.$spacing-05;
  padding-top: spacings.$spacing-05;
  z-index: zIndex.$z-index-flyout;

  :global(.MuiAutocomplete-option.MuiAutocomplete-option) {
    @include fonts.copy-6();
    background: none;
    border-radius: 1px;
    height: helper.px-to-rem(48);
    margin-left: spacings.$spacing-05;
    margin-right: spacings.$spacing-05;

    span {
      @include helper.text-ellipsis();
    }

    &:global(.Mui-selected) {
      @include fonts.copy-6(bold);
      background: none;
      color: colors.$palette-primary-100;
    }

    &:global(.Mui-focused) {
      @include fonts.copy-6(bold);
      background-color: colors.$palette-blue-dark-8;
    }

    mark {
      @include fonts.copy-6(bold);
      background-color: transparent;
      color: colors.$palette-primary-100;
      margin: 0;
      padding: 0;
    }
  }
}

// mixin for error message in form elements
@mixin error-message() {
  @include fonts.display-7(bold);
  color: theme.$color-interactive-error;
  line-height: 210%;
  margin-left: helper.px-to-rem(24);
  margin-right: helper.px-to-rem(24);
}

// mixin for helper message in form elements
@mixin helper-message() {
  @include fonts.display-7();
  color: colors.$palette-black-75;
  line-height: 210%;
  margin-left: helper.px-to-rem(24);
  margin-right: helper.px-to-rem(24);
}

@mixin form-group-label {
  @include display-5(bold);
}

@mixin form-element-optional {
  @include label;
  color: colors.$palette-blue-dark-50;
}

// mixin for styling of a elements
@mixin link-style() {
  @include fonts.copy-5(bold);
  @include animations.interactive-border-animation();
  border-style: solid;
  border-width: 0 0 4px;
  color: theme.$color-interactive-link;
  padding-bottom: 1px;
  text-decoration: none;

  &:active {
    animation: none;
    border-color: colors.$palette-pink-100;
    border-image: linear-gradient(colors.$palette-pink-100, colors.$palette-pink-100) 1;
    color: colors.$palette-pink-100;
  }

  &:focus-visible {
    @include helper.interactive-focus();
    animation: none;
    border-color: colors.$palette-primary-dark-100;
    border-image: linear-gradient(colors.$palette-primary-dark-100, colors.$palette-primary-dark-100) 1;
    border-radius: theme.$border-radius;
    color: colors.$palette-primary-dark-100;
  }
}
@mixin overlay-text() {
  @include fonts.display-3(bold, normal, none);
  align-items: center;
  color: colors.$palette-white-100;
  display: flex !important;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// The following both mixins are used for hover effect on images (big teaser, linked image)
$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;

@mixin image-hover() {
  .Overlay,
  .OverlayText {
    @include overlay-text;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity $transition-duration-hover ease;
    width: 100%;
  }

  .Overlay {
    background: colors.$palette-primary-100;
    mix-blend-mode: multiply;
    pointer-events: none;
  }

  .OverlayText {
    align-items: center;
    color: colors.$palette-white-100;
    display: flex;
    justify-self: center;

    // these styles are only needed for the editor mode
    :global(.fr-wrapper) {
      height: 100%;

      :global(.fr-element) {
        @include overlay-text;
      }
    }

    .HoverTag {
      @include fonts.display-3(bold, normal, none);
      padding: helper.px-to-rem(10);
      text-align: center;
      width: 100%;
      z-index: 1;
    }
  }

  img,
  .Image {
    transform: scale(1.01); // needed to get rid of margin om bottom of image
    transition-duration: $transition-duration-scale;
    width: 100%;
  }
}

@mixin image-wrapper-states() {
  &:hover {
    .Overlay,
    .OverlayText {
      opacity: 1;
      visibility: visible;
    }

    img,
    .Image {
      transform: scale($scale-factor);
    }
  }

  &:active {
    .Overlay,
    .OverlayText {
      opacity: 1;
      visibility: visible;

      .HoverTag {
        display: none;
      }
    }

    .Button {
      background-color: colors.$palette-primary-dark-100;
    }

    .Overlay {
      background: colors.$palette-primary-dark-100;
    }
  }

  &:focus,
  &:active,
  &:hover {
    img,
    .Image {
      opacity: 0.4;
    }
  }
}

@mixin bubble-style() {
  display: grid;
  grid-template:
    'content content'
    'caption bubble';
  grid-template-columns: 1fr auto;

  &.Bubble::after {
    aspect-ratio: 1 / 1;
    content: '';
    grid-area: bubble;
    width: helper.px-to-rem(90);

    @include breakpoints.breakpoint-max-719 {
      width: helper.px-to-rem(72);
    }

    @include breakpoints.breakpoint-max-479 {
      width: helper.px-to-rem(60);
    }
  }

  &.Bubble.lightBlue::after {
    $color: colors.$palette-blue-light-100;
    background: linear-gradient(-45deg, transparent 0%, transparent 50%, $color 51%, $color 100%);
  }

  &.Bubble.darkGreen::after {
    $color: colors.$palette-blue-dark-100;
    background: linear-gradient(-45deg, transparent 0%, transparent 50%, $color 51%, $color 100%);
  }

  &.Bubble.violet::after {
    $color: colors.$palette-violett-100;
    background: linear-gradient(-45deg, transparent 0%, transparent 50%, $color 51%, $color 100%);
  }

  &.Bubble.pink::after {
    $color: colors.$palette-pink-100;
    background: linear-gradient(-45deg, transparent 0%, transparent 50%, $color 51%, $color 100%);
  }

  figcaption {
    @include fonts.copy-6();
    padding-right: 1rem;

    p {
      @include fonts.copy-6();
    }

    strong {
      @include fonts.copy-6(bold);
    }
  }
}

@mixin round-icon-button {
  @include helper.color-transition();
  align-items: center;
  background: colors.$palette-white-100;
  border: 0;
  border-radius: 100%;
  color: colors.$palette-primary-100;
  cursor: pointer;
  display: flex;
  height: helper.px-to-rem(42);
  justify-content: center;
  width: helper.px-to-rem(42);

  &:hover {
    background: colors.$palette-primary-100;
    color: colors.$palette-white-100;
  }

  &:active {
    background: colors.$palette-primary-dark-100;
    color: colors.$palette-white-100;
  }

  &:focus-visible {
    @include helper.interactive-focus;
    background: colors.$palette-primary-dark-100;
    color: colors.$palette-white-100;
  }
}

// Mixin for the link button on desktop depending on the color of the speech bubble
@mixin link-state($primary-color: colors.$palette-primary-100, $secondary-color: colors.$palette-white-100) {
  .Link {
    margin-top: spacings.$spacing-1;
    position: relative;
    width: max-content;

    @include breakpoints.breakpoint-min-720 {
      @include helper.color-transition();
      @include helper.reset-anchor-styles();
      @include link-button-style();
      align-self: flex-end;

      &:active {
        @include helper.color-transition();
      }

      &:focus {
        @include helper.color-transition();
      }
    }

    &:hover {
      @include helper.color-transition();
      background-color: $primary-color;
      color: $secondary-color;

      &::before {
        border-bottom-color: $secondary-color;
        border-right-color: $secondary-color;
      }
    }

    &:active {
      background-color: linear-gradient(
          0deg,
          rgba(colors.$palette-black-100, 0.1),
          rgba(colors.$palette-black-100, 0.1)
        ),
        $primary-color;
    }

    &:focus {
      @include helper.interactive-focus();
      background-color: $primary-color;
      box-shadow: 0 0 0 1px colors.$palette-white-100, 0 0 0 4px theme.$color-interactive-focus,
        0 0 0 theme.$border-radius colors.$palette-white-100;
      color: $secondary-color;

      &::before {
        border-bottom-color: $secondary-color;
        border-right-color: $secondary-color;
      }
    }
  }
}
