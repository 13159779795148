@use 'src/assets/scss';

.WizardHeaderSmall {
  margin-bottom: scss.$spacing-15;

  .ProgressBar {
    background-color: scss.$palette-blue-dark-25;
    height: 2px;
  }

  .Progress {
    background-color: scss.$palette-primary-100;
    height: 2px;
  }

  .Info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .InfoLeft {
    @include scss.display-7(bold);
    color: scss.$palette-primary-100;
  }

  .InfoRight {
    @include scss.display-7(bold);
  }
}
