@use 'src/assets/scss';

.ToggleButton {
  position: relative;

  &:first-of-type {
    &:global(.MuiButtonBase-root.MuiToggleButton-root) {
      border-radius: 5px 0 0 5px;
    }
  }

  &:last-of-type {
    &:global(.MuiButtonBase-root.MuiToggleButton-root) {
      border: 2px solid scss.$palette-blue-dark-25;
      border-radius: 0 5px 5px 0;
    }
  }

  // standalone toggle button (not in toggle button group)
  &:only-of-type {
    &:global(.MuiButtonBase-root.MuiToggleButton-root) {
      border-radius: 5px;
      margin-bottom: scss.$spacing-1;
    }
  }

  &:global(.MuiButtonBase-root.MuiToggleButton-root) {
    background: scss.$palette-white-100;
    border: 2px solid scss.$palette-blue-dark-25;
    border-radius: 0;
    border-right: 0;
    display: inline-flex;
    height: scss.px-to-rem(56);
    padding: 0;
    text-transform: none;

    &:global(.Mui-selected) {
      background: scss.$palette-blue-dark-100;
      border: 2px solid scss.$palette-blue-dark-100;

      .Label {
        @include scss.display-6(bold);
        color: scss.$palette-white-100;
      }

      + .ToggleButton {
        border-left: 0;
      }
    }
  }

  .Label,
  .LabelPlaceholder {
    @include scss.display-6(normal);
    align-items: center;
    border-radius: 1px;
    color: scss.$palette-blue-dark-100;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    justify-content: center;
    letter-spacing: 0.02em;
    line-height: 120%;
    padding: scss.px-to-rem(5);
  }

  .LabelPlaceholder {
    @include scss.display-6(bold);
    visibility: hidden;
  }

  .Label {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:focus {
    @include scss.interactive-focus;
    z-index: 1;

    &:global(.MuiButtonBase-root.MuiToggleButton-root) {
      // overwrite border (to make visible in focus state)
      border-color: transparent;
    }
  }

  &:hover:not(&:global(.Mui-selected)) {
    :global(.MuiButtonBase-root.MuiToggleButton-root) {
      background: scss.$palette-white-100;
    }

    .Label {
      @include scss.display-6(bold);
      background: scss.$palette-blue-dark-8;
    }
  }

  &:active {
    &:global(.MuiButtonBase-root.MuiToggleButton-root) {
      background: scss.$palette-interactive-blue-light-a;

      .Label {
        background: scss.$palette-interactive-blue-light-a;
        color: scss.$palette-blue-dark-100;
      }
    }
  }

  &:disabled {
    .Label {
      color: scss.$palette-blue-dark-50;
    }
  }
}
