@use './assets/scss';
@import './assets/fonts';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision;
}

body {
  background-color: scss.$palette-blue-dark-3;

  @include scss.breakpoint-max-599 {
    background-color: scss.$palette-white-100;
  }

  // Adjustments for app view (reduced page view)
  /* stylelint-disable selector-no-qualifying-type */
  &.AppView {
    background-color: scss.$palette-white-100;
  }

  // Settings for modal
  &.modalWarningOpen,
  &.modalOpen {
    position: relative;

    &::before {
      content: '';
      height: 100vh;
      mix-blend-mode: multiply;
      position: absolute;
      width: 100vw;
      z-index: 100;
    }
  }

  &.modalWarningOpen::before {
    background-color: scss.$palette-primary-100;
  }

  &.modalOpen::before {
    background-color: scss.$palette-blue-dark-100;
  }
  /* stylelint-enable selector-no-qualifying-type */
}

html {
  color: scss.$color-text-default;
  scroll-behavior: smooth;
  scroll-padding-top: scss.px-to-rem(0);

  // navigation is always sticky at desktop
  // add navigation height (small) as scrolling viewport offset top
  @include scss.breakpoint-min-1200 {
    scroll-padding-top: scss.px-to-rem(87);
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  &.big-header {
    scroll-padding-top: scss.px-to-rem(107);
    @include scss.breakpoint-min-1200 {
      scroll-padding-top: scss.px-to-rem(196);
    }
  }
}

h1,
h1 strong,
h1 em,
h1 strong em,
h1 em strong {
  @include scss.display-1(bold);
  hyphens: auto;
}

h2,
h2 strong,
h2 em,
h2 strong em,
h2 em strong {
  @include scss.display-2(bold);
  hyphens: auto;
}

h3,
h3 strong,
h3 em,
h3 strong em,
h3 em strong {
  @include scss.display-3(bold);
  hyphens: auto;
}

h4,
h4 strong,
h4 em,
h4 strong em,
h4 em strong {
  @include scss.display-4(bold);
  hyphens: auto;
}

h5,
h5 strong,
h5 em,
h5 strong em,
h5 em strong {
  @include scss.display-5(bold);
  hyphens: auto;
}

h6,
h6 strong,
h6 em,
h6 strong em,
h6 em strong {
  @include scss.display-6(bold);
  hyphens: auto;
}

p {
  @include scss.copy-5();
  hyphens: auto;
}

h1 + p,
p + p,
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ol + h2,
ol + h3,
ol + h4,
ol + h5 {
  margin-top: 1em;
}

p + h6 {
  margin-top: 1rem;
}

strong {
  @include scss.copy-5(bold);
}

em {
  @include scss.copy-5(normal, italic);
}

em strong,
strong em {
  @include scss.copy-5(bold, italic);
}

mark {
  background-color: scss.$palette-primary-100;
  color: scss.$palette-white-100;
  display: inline-block;
  margin: calc(-1 * #{scss.spacing-px-to-rem(5)}) 0;
  padding: scss.spacing-px-to-rem(5) scss.spacing-px-to-rem(8);
}

sub,
sup {
  line-height: normal;
}

a {
  @include scss.link-style();
}

ol,
ul {
  @include scss.copy-5();
  list-style-position: outside;
  margin-bottom: 1em;

  li {
    margin-left: 2.5rem;
    padding-left: scss.px-to-rem(4);

    &::marker {
      color: scss.$palette-primary-100;
    }
  }
}

* + ul,
* + ol {
  margin-top: 1em;
}

// Spacing between the widgets (normal)
.Spacing {
  margin: 0 auto scss.$spacing-4;
}

// Spacing between the widgets (small)
.SpacingSmall {
  margin: 0 auto scss.spacing-px-to-rem(32);
}

// Spacing between the widgets (large)
.SpacingLarge {
  margin: 0 auto scss.spacing(8);
}

.ManualHyphens {
  hyphens: manual;
}

input {
  hyphens: manual;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.ScheduleAlert {
  @include scss.default-width;
  margin-bottom: 1rem;

  ul {
    margin-bottom: 0;
  }

  li {
    @include scss.display-7;
  }
}
