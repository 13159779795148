@use 'colors';
@use 'theme';

@keyframes border-animation {
  0% {
    border-image: linear-gradient(90deg,
    colors.$palette-primary-dark-25 0%,
    colors.$palette-primary-dark-25 50%,
    colors.$palette-primary-dark-25 100%)
      1;
  }

  50% {
    border-image: linear-gradient(90deg,
    colors.$palette-primary-dark-25 0%,
    colors.$palette-primary-100 50%,
    colors.$palette-primary-dark-25 100%)
      1;
  }

  100% {
    border-image: linear-gradient(90deg,
    colors.$palette-primary-100 0%,
    colors.$palette-primary-100 50%,
    colors.$palette-primary-100 100%)
      1;
  }
}

@keyframes border-animation-white {
  0% {
    border-image: linear-gradient(90deg,
    rgba(colors.$palette-white-100, 0.5) 0%,
    rgba(colors.$palette-white-100, 0.5) 50%,
    rgba(colors.$palette-white-100, 0.5) 100%)
      1;
  }

  50% {
    border-image: linear-gradient(90deg,
    rgba(colors.$palette-white-100, 0.5) 0%,
    colors.$palette-white-100 50%,
    rgba(colors.$palette-white-100, 0.5) 100%)
      1;
  }

  100% {
    border-image: linear-gradient(90deg,
    colors.$palette-white-100 0%,
    colors.$palette-white-100 50%,
    colors.$palette-white-100 100%)
      1;
  }
}

@mixin interactive-border-animation {
  border-image: linear-gradient(90deg,
  colors.$palette-primary-dark-25 0%,
  colors.$palette-primary-dark-25 50%,
  colors.$palette-primary-dark-25 100%)
    1;

  &:hover {
    animation: border-animation 200ms;
    animation-fill-mode: forwards, none;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    border-color: theme.$color-interactive-link;
  }
}

@mixin interactive-border-animation-white {
  border-image: linear-gradient(90deg,
  rgba(colors.$palette-white-100, 0.5) 0%,
  rgba(colors.$palette-white-100, 0.5) 50%,
  rgba(colors.$palette-white-100, 0.5) 100%)
    1;

  &:hover {
    animation: border-animation-white 200ms;
    animation-fill-mode: forwards, none;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    border-color: colors.$palette-white-100;
  }
}
