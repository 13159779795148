@use 'src/assets/scss';

/**
* Styling of Radio Button Component
*/
.RadioButton {
  width: 100%;

  :global(.MuiButtonBase-root.MuiRadio-root) {
    align-self: flex-start;
  }

  :global(.MuiTypography-root) {
    @include scss.display-6(normal);
    padding-left: scss.px-to-rem(7); // To make it in line with the checkboxes
  }

  // hover if not disabled
  &:global(:not(.Mui-disabled):hover) {
    .RadioButtonIcon {
      border: 1px scss.$palette-blue-dark-50 solid;

      .dot {
        background: scss.$palette-blue-dark-8;
        border-radius: 50%;
        height: scss.px-to-rem(16);
        width: scss.px-to-rem(16);
      }
    }
  }

  // hover when checked
  &:hover {
    :global(.Mui-checked) {
      .RadioButtonIcon .dot {
        background: scss.$palette-primary-100;
        border-radius: 50%;
        height: scss.px-to-rem(8);
        width: scss.px-to-rem(8);
      }
    }

    .RadioButtonIcon .inner {
      border-color: scss.$palette-blue-dark-50 !important;
    }
  }

  &:active {
    :not(:global(.Mui-checked)) {
      .RadioButtonIcon .dot {
        background: scss.$palette-blue-dark-50;
        border-radius: 50%;
        height: scss.px-to-rem(8);
        width: scss.px-to-rem(8);
      }

      .RadioButtonIcon {
        @include scss.interactive-focus();
      }
    }
  }

  :global(.MuiButtonBase-root.MuiRadio-root:hover) {
    background-color: transparent;
  }

  // color if checked
  :global(.Mui-checked + .MuiTypography-root) {
    color: scss.$palette-primary-100;
  }

  // focus styling
  :focus-within {
    .RadioButtonIcon {
      @include scss.interactive-focus();
    }
  }

  // disabled styling
  :global(.Mui-disabled) {
    .RadioButtonIcon .inner {
      background: scss.$palette-blue-dark-3;
    }
  }

  &:global(.MuiFormControlLabel-root) :global(.MuiFormControlLabel-label.Mui-disabled) {
    color: scss.$palette-blue-dark-50;
  }

  &.Disabled {
    pointer-events: none;
  }
}

/**
* Styling of Radio Button Icon
*/
.RadioButtonIcon {
  border: 1px transparent solid;
  border-radius: 50%;

  .inner {
    align-items: center;
    background: scss.$palette-white-100;
    border: 1px scss.$palette-blue-dark-25 solid;
    border-radius: 50%;
    display: flex;
    height: scss.px-to-rem(22);
    justify-content: center;
    width: scss.px-to-rem(22);
  }

  &.checked {
    .dot {
      background: scss.$palette-primary-100;
      border-radius: 50%;
      height: scss.px-to-rem(8);
      width: scss.px-to-rem(8);
    }
  }
}
