@use 'src/assets/scss';

$z-index-teaser-background: 1;
$z-index-teaser-content: 3;
$z-index-teaser-overlay: 2;

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$image-interaction-opacity: 0.4;

.HighlightTeaser {
  line-height: 0;
  position: relative;
  z-index: 1;

  .HighlightTeaserHoverOverlay {
    aspect-ratio: 16 / 9;
    background: scss.$palette-primary-100;
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    object-fit: cover;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity $transition-duration-hover ease;
    width: 100%;
    z-index: $z-index-teaser-overlay;
  }

  &:hover {
    z-index: 0;

    .HighlightTeaserHoverOverlay {
      opacity: 1;
    }

    .HighlightTeaserImageWrapper > img {
      opacity: $image-interaction-opacity;
      transform: scale($scale-factor);
    }
  }

  &:hover:active {
    .HighlightTeaserHoverOverlay {
      background: scss.$palette-primary-dark-100;
    }

    .HighlightTeaserImageWrapper > img {
      opacity: $image-interaction-opacity;
    }
  }

  // Special solution for highlight teaser because images are cropped and the normal box-shadow would be outside the element
  &:focus-visible {
    box-shadow: unset;
    outline: 3px solid scss.$color-interactive-focus;
    outline-offset: -3px;
  }

  &.HighlightTeaserLarge {
    .HighlightTeaserContent {
      .HighlightTeaserText {
        @include scss.display-1(bold, normal, 120%);

        @include scss.breakpoint-min-1920 {
          @include scss.display-0(bold, normal, 120%);
        }
      }

      .HighlightTeaserSubject {
        @include scss.display-2(bold, normal, 120%);

        @include scss.breakpoint-max-1919 {
          @include scss.display-3(bold, normal, 120%);
        }

        @include scss.breakpoint-min-1440 {
          @include scss.display-4(bold, normal, 120%);
        }

        @include scss.breakpoint-min-600 {
          @include scss.display-5(bold, normal, 120%);
        }

        @include scss.breakpoint-max-599 {
          @include scss.display-6(bold, normal, 120%);
        }
      }
    }
  }

  .HighlightTeaserContent {
    bottom: 0;
    display: block;
    line-height: normal;
    max-height: 100%;
    padding: scss.$spacing-1;
    position: absolute;
    z-index: $z-index-teaser-content;

    .HighlightTeaserSubject {
      color: scss.$palette-white-100;

      @include scss.breakpoint-min-1920 {
        @include scss.display-2(bold, normal, 120%);
      }

      @include scss.breakpoint-min('1680px') {
        @include scss.display-3(bold, normal, 120%);
      }

      @include scss.breakpoint-min-1440 {
        @include scss.display-4(bold, normal, 120%);
      }

      @include scss.breakpoint-min-600 {
        @include scss.display-5(bold, normal, 120%);
      }

      @include scss.breakpoint-max-599 {
        @include scss.display-6(bold, normal, 120%);
      }
    }

    .HighlightTeaserText {
      color: scss.$palette-white-100;

      @include scss.breakpoint-min-900 {
        @include scss.display-2(bold, normal, 120%);
      }

      @include scss.breakpoint-max-899 {
        @include scss.display-3(bold, normal, 120%);
      }
    }
  }

  .HighlightTeaserImageWrapper {
    overflow: hidden;

    > img {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      transition-duration: $transition-duration-scale;
    }
  }

  .HighlightTeaserBackground {
    background: linear-gradient(0deg, scss.$palette-blue-dark-100 0%, rgba(76, 97, 112, 0) 100%);
    bottom: 0;
    height: 75%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: $z-index-teaser-background;
  }
}
