@use 'src/assets/scss';
@use 'sass:math';

.CollapsibleTabs {
  :global(.MuiAccordion-root) {
    background: none;
    box-shadow: none;
    text-align: center;

    &::before {
      background-color: scss.$palette-white-100;
    }

    &:first-child:global(.Mui-disabled) {
      padding-bottom: scss.$spacing-15;
    }

    :global(.MuiAccordionDetails-root) {
      margin: 0 auto;
      max-width: 700px;
      padding: 0 scss.$spacing-15;

      @include scss.breakpoint-max-479 {
        padding: 0 scss.$spacing-15;
      }
    }

    // Currently opened accordion
    &:global(.Mui-disabled) {
      background: none;

      &:last-child {
        border-top: 1px solid scss.$palette-white-100;
        margin-bottom: scss.$spacing-15;
      }
    }

    :global(.MuiButtonBase-root.Mui-disabled) {
      color: scss.$palette-primary-100;
      opacity: 1;

      :global(.MuiAccordionSummary-content) {
        @include scss.display-4(bold);
      }
    }

    :global(.MuiAccordionSummary-content) {
      @include scss.display-5();
      justify-content: center;
    }
  }
}
