@use 'src/assets/scss';

.KpiTile {
  display: flex;
  position: relative;

  .KpiTileContent {
    color: scss.$palette-white-100;
    display: grid;
    grid-template: auto auto / 1fr;
    padding: scss.$spacing-1;
    width: 100%;
    z-index: 1;

    &:global(.hasAsset) {
      padding-right: 17vw;

      @include scss.breakpoint-max-599 {
        padding-right: 35vw;
      }
    }

    .KpiTileText {
      @include scss.display-2(bold, normal, 120%, scss.$font-size-base-600, scss.$font-size-base-2400);

      @include scss.breakpoint-max-599 {
        @include scss.display-2(bold, normal, 120%, scss.$font-size-base-1200, scss.$font-size-base-2400);
      }

      @include scss.breakpoint-max-479 {
        @include scss.display-2(bold, normal, 120%, scss.$font-size-base-900, scss.$font-size-base-2400);
      }

      @include scss.breakpoint-max-374 {
        @include scss.display-2(bold, normal, 120%, scss.$font-size-base-600, scss.$font-size-base-2400);
      }
    }

    .KpiTileCounter {
      @include scss.display-0(bold, normal, 120%, scss.$font-size-base-900, scss.$font-size-base-2400);
      align-self: flex-end;
      justify-self: end;

      @include scss.breakpoint-max-899 {
        @include scss.display-0(bold, normal, 120%, scss.$font-size-base-600, scss.$font-size-base-2400);
      }

      @include scss.breakpoint-max-599 {
        @include scss.display-0(bold, normal, 120%, scss.$font-size-base-1200, scss.$font-size-base-2400);
      }

      @include scss.breakpoint-max-479 {
        @include scss.display-0(bold, normal, 120%, scss.$font-size-base-900, scss.$font-size-base-2400);
      }

      @include scss.breakpoint-max-374 {
        @include scss.display-0(bold, normal, 120%, 1rem, scss.$font-size-base-2400);
      }
    }
  }

  .KpiTileImageWrapper {
    aspect-ratio: 16 / 9;
    height: 100.2%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100.2%;
    z-index: 0;

    > img,
    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &:global(.darkGreen) {
    background-color: scss.$palette-blue-dark-100;
  }

  &:global(.darkRed) {
    background-color: scss.$palette-primary-dark-100;
  }

  &:global(.violet) {
    background-color: scss.$palette-violett-100;
  }

  &:global(.red) {
    background-color: scss.$palette-primary-100;
  }

  &:global(.gray) {
    background-color: scss.$palette-blue-dark-8;

    .KpiTileContent {
      color: scss.$palette-blue-dark-100;
    }
  }
}
