@use 'src/assets/scss';
@use 'sass:math';

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$diagonal: 16px;
$big-teaser-outer-spacing: scss.px-to-rem(5);
$countdown-panel-width: 472px;
$image-min-height-mobile: 300px;

.CountdownComponent {
  // link wrapper of countdown component
  .CountdownComponentLink {
    display: flex;
    flex-direction: row;
    min-height: 300px;
    color: scss.$palette-white-100;
    border: 0;
  }

  .CountdownPanel {
    background: scss.$palette-primary-dark-100;
    padding: scss.$spacing-2;
    flex-shrink: 0;
    max-width: $countdown-panel-width;
    display: flex;
    flex-direction: column;

    .Text {
      @include scss.display-4();
    }

    .TextFinished {
      @include scss.display-2(bold);
      flex: 1 1 0;
      display: flex;
      align-items: center;
    }

    .Countdown {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: scss.$spacing-05;

      .Days {
        @include scss.display-0(bold, normal, default, 1.5rem, 3rem);
        overflow: hidden;
      }

      .Unit {
        @include scss.display-4();
      }
    }

    // styling for link
    @include scss.link-state(scss.$palette-primary-100);
  }

  .ImageWrapper {
    position: relative;
    overflow: hidden;
    flex: 3 0 0;

    .Date {
      @include scss.display-4();
      background: scss.$palette-primary-100;
      padding: 0 scss.$spacing-05;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      position: absolute;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      transition-duration: scss.$transition-duration-scale;

      &:hover {
        transform: scale($scale-factor);
      }
    }
  }

  @include scss.breakpoint-max-719 {
    .CountdownComponentLink {
      flex-direction: column;
    }

    .CountdownPanel {
      flex: unset;
      max-width: unset;
      min-height: unset;
    }

    .Link {
      @include scss.interactive-border-animation-white;
      color: scss.$palette-white-100;
    }

    .ImageWrapper {
      img {
        position: relative;
      }
    }
  }
}
