@use 'src/assets/scss';
@use '../../shared-vals' as shared;
@use '../../shared-styles';

.NavigationDrawerPage {
  @include shared-styles.navigation-content;

  :global(.MuiButtonBase-root) {
    @include scss.display-3(bold);
    box-sizing: border-box;
    color: scss.$palette-white-100;
    padding: scss.px-to-rem(20) 3.5rem;
  }

  &:not(.Sub) {
    :global(.MuiButtonBase-root).Current {
      span {
        border-bottom: scss.px-to-rem(8) solid scss.$palette-white-100;
        margin-bottom: scss.px-to-rem(-1 * 8);
      }
    }

    :global(.MuiButtonBase-root):focus-visible {
      background-color: transparent;
      outline: 0;

      span {
        border-radius: 1px;
        box-shadow: 0 0 0 3px scss.$palette-primary-100, 0 0 0 6px scss.$color-interactive-focus;
      }
    }
  }

  &.Sub {
    background-color: scss.$palette-white-100;

    :global(.MuiButtonBase-root) {
      @include scss.display-6(bold);
      color: scss.$color-text-default;
      min-height: scss.px-to-rem(64);
      padding: 0 4rem 0 3.5rem;

      &:focus-visible {
        @include scss.display-6(bold);
        background-color: transparent;
        color: scss.$palette-primary-100;

        span {
          border-radius: 1px;
          box-shadow: 0 0 0 3px scss.$palette-white-100, 0 0 0 6px scss.$color-interactive-focus;
        }
      }

      &.Current {
        @include scss.display-6(bold);
        color: scss.$palette-primary-100;
      }

      &.HasChildren::after {
        background: linear-gradient(45deg,
        transparent 0%,
        transparent 50%,
        scss.$palette-primary-100 50%,
        scss.$palette-primary-100 100%);
        content: '';
        height: scss.px-to-rem(12);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-2.4rem, -50%) rotate(45deg);
        width: scss.px-to-rem(12);
      }
    }

    :global(.MuiList-root) > *:nth-child(2n) {
      :global(.MuiButtonBase-root),
      &:global(.MuiButtonBase-root) {
        background-color: scss.$palette-blue-dark-3;

        &:focus-visible span {
          border-radius: 1px;
          box-shadow: 0 0 0 3px scss.$palette-blue-dark-3, 0 0 0 6px scss.$color-interactive-focus;
        }
      }
    }
  }

  &.SubSub {
    :global(.MuiButtonBase-root) {
      @include scss.display-6;

      &:focus-visible {
        @include scss.display-6(bold);
        background-color: transparent;
        color: scss.$palette-primary-100;
      }

      &.Current {
        @include scss.display-6(bold);
        color: scss.$palette-primary-100;
      }
    }
  }
}
