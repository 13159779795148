@use 'src/assets/scss';

.ColumnWidget {
  @include scss.default-width();
  column-gap: scss.$spacing-2;
  display: flex;
  flex-flow: row;
  margin: 0 auto;

  @include scss.breakpoint-max-719 {
    flex-flow: column;
    row-gap: scss.$spacing-2;
  }

  > * {
    flex: 1;
    margin: 0;
  }

  :global(.TextWidget) {
    /*
    The required negative margin m is (h_p - h_f) / 2, where
    h_p is the height of the paragraph and h_f is the height of the font.
    The paragraph is higher than the font, thus creating a spacing on top and bottom.

    h_p = 2 * s_f where s_f is the font-size
    s_f = clamp(1.125rem, 2vw, 1.5rem)
    h_f = clamp(21px, 2.34vh, 28.5px)
    The 2.34vh is a magic number I've calculated using collected size information.
    Honestly I don't know where it comes from.

    m = calc(calc(calc(2 * clamp(1.125rem, 2vw, 1.5rem)) - clamp(21px, 2.34vh, 28.5px)) / 2)
    */
    margin-top: calc(calc(calc(2 * clamp(#{scss.$font-size-base-900}, 2vw, #{scss.$font-size-base-1200})) - clamp(21px, 2.34vh, 28.5px)) / -2);

    @include scss.breakpoint-max-719 {
      margin-top: 0;
    }
  }
}
