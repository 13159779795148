@use 'src/assets/scss';

.JourneyPlanner {
  max-width: 452px;
  width: 100%;

  @include scss.breakpoint-max-899 {
    max-width: none;
  }

  :global(.TextInputComponentAutosuggest) {
    margin-bottom: 0;
  }

  // Trip Request
  .FromToContainer {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;

    // Input fields are next to each other
    @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
      flex-flow: row;
    }

    > * {
      margin: 0;
      width: 100%;
    }
  }

  :global(.JourneyPlannerFrom) {
    order: 1;

    :global(.MuiFormControl-root:focus-within) {
      z-index: 2;
    }
    // Show rounded corners only if there is a "to"-field below
    :global(.MuiFilledInput-input:not(#journey-planner-from-dep)) {
      @include scss.breakpoint-max-or-min(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .SwitchButton {
    @include scss.icon-button-style();
    bottom: 0.5rem;
    order: 2;
    position: absolute;
    right: 0.5rem; // input height (3.5rem) - button height (2.5rem) / 2
    width: 2.5rem;
    z-index: scss.$z-index-input-icon-button;

    @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
      flex: 1 0 auto;
      height: 2.5rem;
      margin: 0.5rem scss.$spacing-05;
      position: static;
      transform: rotate(90deg);
      width: 2.5rem;
    }
  }

  :global(.JourneyPlannerTo) {
    order: 3;

    :global(.MuiFormControl-root:focus-within) {
      z-index: 2;
    }

    :global(.MuiFormControl-root .MuiFilledInput-root .MuiAutocomplete-input) {
      @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
        padding-right: scss.px-to-rem(22);
      }
    }

    :global(.MuiFilledInput-input) {
      @include scss.breakpoint-max-or-min(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:focus-within {
          height: calc(#{scss.px-to-rem(56)} + 2px);
          margin-top: -2px;
        }

        &:not(:focus) {
          border-top: 0;
        }
      }
    }

    :global(.MuiFormControl-root):hover {
      @include scss.breakpoint-max-or-min(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
        border-top: 2px solid scss.$palette-blue-dark-50;
        margin-top: -2px;
        z-index: 1;
      }
    }

    :global(.MuiFormControl-root):hover,
    :global(.MuiFormControl-root):active {
      :global(.MuiFilledInput-root)::before {
        @include scss.breakpoint-max-or-min(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xs) {
          top: 3px !important;
        }
      }
    }
  }

  :global(.Dropdown) {
    margin-top: scss.$spacing-1;
  }

  .DateTimePickers {
    column-gap: scss.$spacing-05;
    display: flex;
    flex-flow: column;

    @include scss.breakpoint-between(scss.$breakpoint-mobile-l, scss.$breakpoint-desktop-xs) {
      flex-flow: row;
    }

    @include scss.breakpoint-min-1440 {
      flex-flow: row;
    }

    > * {
      flex: 1 0;
      width: 100%;

      @include scss.breakpoint-between(scss.$breakpoint-mobile-l, scss.$breakpoint-desktop-xs) {
        width: calc(50% - #{scss.$spacing-05} / 2);
      }

      @include scss.breakpoint-min-1440 {
        width: calc(50% - #{scss.$spacing-05} / 2);
      }

      // special case to fit date picker text into input field for firefox
      // when date and time picker are in one row
      @include scss.breakpoint-between(scss.$breakpoint-desktop-l, 1500px) {
        // firefox only
        /* stylelint-disable-next-line function-url-quotes */
        @-moz-document url-prefix() {
          :global(.MuiFilledInput-input) {
            $font-size: scss.font-factor(0.69);
            font-size: max(#{$font-size}, 16px);
            text-transform: lowercase;
          }
        }
      }
    }
  }

  .JourneyPlannerOptions {
    display: flex;
    // TODO will be used with the checkbox for accessibility options
    //justify-content: space-between;
    justify-content: flex-end;

    a {
      @include scss.reset-anchor-styles();
      @include scss.copy-6();
      align-items: center;
      color: scss.$color-text-default;
      cursor: pointer;
      display: flex;

      &:hover {
        color: scss.$palette-primary-100;
      }

      &:active {
        color: scss.$palette-primary-dark-100;
      }

      &:focus-visible {
        color: scss.$color-text-default;
      }
    }

    a svg {
      margin-left: scss.spacing(0.25);
    }
  }

  :global(.MuiButton-root.MuiButton-text.DepartureButton) {
    margin-top: 0;
  }

  :global(.MuiButton-root.MuiButton-text) {
    margin-top: scss.$spacing-1;
    width: 100%;
  }

  .ErrorMessage {
    @include scss.error-message;
  }
}
