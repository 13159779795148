@use 'src/assets/scss';
@use '../navigation-vals' as nav;
@use '../../shared-vals' as shared;
@use '../../shared-styles';

.NavigationHeader {
  @include shared-styles.navigation-header;

  .LogoArea {
    @include shared-styles.navigation-header-logo-area;

    @include scss.breakpoint-between(scss.$breakpoint-mobile-l, scss.$breakpoint-desktop-m) {
      bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }

    @include scss.breakpoint-max-479 {
      svg {
        transform: translateY(7px);
      }

      .Text {
        opacity: 0;
      }
    }

    .LogoContainer {
      @include shared-styles.navigation-header-logo-container;
    }

    a:focus-visible .LogoContainer {
      @include shared-styles.navigation-header-logo-contained-focused;
    }
  }

  .LinksArea {
    @include shared-styles.navigation-header-links-area;

    > a {
      @include scss.breakpoint-max-1199 {
        display: none;
      }
    }
  }

  .IconsArea {
    @include shared-styles.navigation-header-icons-area;
  }
}
