@use '/src/assets/scss';

.InfoTeaserGroupWidget {
  @include scss.default-width();
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  gap: scss.$spacing-2;
  grid-template-columns: repeat(auto-fit, minmax(scss.spacing-px-to-rem(216), 1fr));
}
