@use 'src/assets/scss';

$special-spacing: clamp(#{scss.$font-size-base-900}, #{scss.$font-size-dynamic}, 38.4px);
$speech-bubble-offset: calc(3 * #{$special-spacing});
$speech-bubble-min-width: scss.px-to-rem(400);

.NoHero {
  background: scss.$palette-white-100;
  margin: 0 auto;
  max-width: scss.$max-width-article;
  padding: scss.spacing-px-to-rem(82) scss.$spacing-1 scss.$spacing-2;

  @include scss.breakpoint-max-374 {
    padding: 0 0 scss.$spacing-1;
  }

  @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-1600) {
    margin: 0 scss.$spacing-1;
  }
}

.SpeechBubble {
  display: flex;
  flex: 0 0 calc(#{$special-spacing} * 16);
  flex-flow: column;
  hyphens: auto;
  justify-content: space-around;
  margin-top: scss.$spacing-4;
  min-width: 0;
  padding: scss.$spacing-25;
  position: relative;
  z-index: 1;

  @include scss.breakpoint-max-1199 {
    flex: 0 0 calc(#{$special-spacing} * 19);
    margin-top: scss.$spacing-2;
  }

  @include scss.breakpoint-max-899 {
    flex-basis: 100%;
    margin-top: 0;
    min-width: unset;
    padding: scss.$spacing-2;
    width: 100%;
  }

  &::after {
    border-color: transparent transparent scss.$palette-blue-dark-100 transparent;
    border-style: solid;
    border-width: 0 0 $speech-bubble-offset $speech-bubble-offset;
    bottom: 0;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    transform: rotate(180deg) translateY(-100%);
    width: 0;
  }

  &.violet {
    @include scss.link-state(scss.$palette-pink-100);
    background: scss.$palette-violett-100;
    color: scss.$palette-white-100;

    &::after {
      border-color: transparent transparent scss.$palette-pink-100 transparent;
    }
  }

  &.blue {
    @include scss.link-state(scss.$palette-blue-dark-100);
    background: scss.$palette-blue-light-100;
    color: scss.$palette-white-100;
  }

  &.red {
    @include scss.link-state(scss.$palette-primary-100);
    background: scss.$palette-primary-dark-100;
    color: scss.$palette-white-100;

    &::after {
      border-color: transparent transparent scss.$palette-primary-100 transparent;
    }
  }

  &.grey {
    @include scss.link-state(scss.$palette-primary-dark-100);
    background-color: scss.$palette-blue-dark-8;
  }

  &:not(.grey) {
    @include scss.breakpoint-max-719 {
      .Link {
        @include scss.interactive-border-animation-white;
        color: scss.$palette-white-100;
      }
    }
  }

  .TextWrapper {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: center;

    h1,
    h2 {
      @include scss.display-2(bold, normal, none);
      line-height: 120%;
      margin-bottom: scss.$spacing-05;
    }

    p {
      @include scss.display-4(normal, normal, none);
      line-height: 170%;
    }

    &.Small {
      @include scss.breakpoint-max-1339 {
        > h1,
        h2 {
          @include scss.display-3(bold, normal, none);
        }

        > p {
          @include scss.display-5(normal, normal, none);
        }
      }
    }
  }

  &.JourneyPlanner {
    align-items: center;
    background-color: scss.$palette-blue-dark-8;
    justify-content: flex-start;
    min-width: $speech-bubble-min-width;
    padding: scss.$spacing-1 scss.$spacing-2 scss.$spacing-2;

    @include scss.breakpoint-max-899 {
      padding: 0;

      &::after {
        display: none;
      }
    }

    @include scss.breakpoint-max-479 {
      min-width: auto;
      padding: 0;
    }

    &.ShowJourneyText {
      @include scss.breakpoint-max-899 {
        margin-bottom: scss.$spacing-1;
      }
    }

    &::after {
      border-color: transparent transparent scss.$palette-blue-dark-25 transparent;
    }
  }
}

.Hero {
  display: flex;
  margin: auto auto $speech-bubble-offset;
  max-width: scss.$max-width-hero;
  padding-bottom: scss.$spacing-2;
  position: relative;

  @include scss.breakpoint-max-599 {
    margin: 0 scss.$spacing-1;
    padding-bottom: $speech-bubble-offset;
  }

  @include scss.breakpoint-max-899 {
    flex-direction: column-reverse;
  }

  @include scss.breakpoint-min-1200 {
    // White background for the bottom of the hero component to overlay the navigation
    &::after {
      background: scss.$palette-white-100;
      bottom: calc(-1 * #{$speech-bubble-offset});
      content: '';
      height: 50%;
      left: 0;
      margin: 0 auto;
      max-width: 100%;
      position: absolute;
      right: 0;
      width: scss.$max-width-article;
      z-index: -1;
    }
  }

  @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-xl) {
    margin-left: scss.$spacing-1;
    margin-right: scss.$spacing-1;
  }

  .HeroImageColumn:not(.ShowJourneyText).IsFocused,
  &:focus-within .HeroImageColumn:not(.ShowJourneyText) {
    @include scss.breakpoint-max-899 {
      height: 0;
    }
  }

  &.MobilePlannerAbove {
    @include scss.breakpoint-max-899 {
      flex-direction: column;
    }
  }

  :global(.breadcrumb) {
    left: calc(#{$special-spacing} * 17);
    position: absolute;
    top: calc(#{$special-spacing} * 17);

    @include scss.breakpoint-max-1199 {
      left: calc(#{$special-spacing} * 20);
      margin-top: scss.$spacing-2;
      top: calc(#{$special-spacing} * 15);
    }

    nav {
      margin: 0;
    }
  }
}

.HeroImageColumn {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100vw;
  margin-bottom: scss.$spacing-2;
  margin-left: scss.spacing(-2);
  max-height: calc(#{$special-spacing} * 16);
  overflow: hidden;
  position: relative;
  text-align: right;
  transition: height 200ms linear;

  @include scss.breakpoint-max-899 {
    margin: 0 scss.$spacing-1;
  }

  @include scss.breakpoint-max-599 {
    height: 40vw;
    margin: 0 scss.$spacing-1;
  }

  @include scss.breakpoint-max-479 {
    flex-direction: column;
    max-height: initial;
  }

  &.HideMobile {
    @include scss.breakpoint-max-719 {
      height: initial;

      .HeroImage {
        display: none;
      }
    }
  }

  &.ShowJourneyText {
    @include scss.breakpoint-max-599 {
      height: 100%;
    }

    @include scss.breakpoint-max-899 {
      margin: 0;
    }
  }
}

.HeroImage {
  background: scss.$palette-blue-dark-8;
  flex: 2;
  position: relative;
  border: 0;

  @include scss.breakpoint-max-899 {
    min-height: 40vw;
  }

  @include scss.breakpoint-max-1199 {
    flex: 1;
    min-width: 50%;
  }

  &.Clickable {
    @include scss.image-wrapper-states;

    img {
      transition-duration: scss.$transition-duration-scale;
    }

    &:focus,
    &:active,
    &:hover {
      img,
      .Image {
        opacity: 1;
      }
    }
  }

  img {
    min-height: 100%;
    object-fit: cover;
    object-position: right;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.HeroImageInfo {
  flex: 1;
  margin-top: 0;
  padding: scss.$spacing-25 scss.$spacing-15;
  text-align: left;

  .Link {
    align-self: flex-start;
  }
}
