@use 'src/assets/scss';
@use '../../building-blocks/navigation/shared-vals' as shared;

$transition-delay: 25ms;
$transition-speed: 350ms;
$transition-speed-mobile: 200ms;

@mixin page-wrapper {
  background-color: scss.$palette-white-100;
  margin: auto;
  max-width: scss.$max-width-page;
  overflow: hidden;
  width: 100%;
}

@mixin page-header {
  left: 0;
  margin: 0 auto;
  max-width: scss.$max-width-page;
  position: fixed;
  right: 0;
  top: 0;
  z-index: scss.$z-index-page-header;

  @include scss.breakpoint-max-1199 {
    overflow-y: hidden;
    position: absolute;
    transition: transform $transition-speed-mobile ease;

    &.PositionFixed {
      position: fixed;
    }

    &.NoTransition {
      transition: unset;
    }

    &.Small {
      transform: translateY(-100%);
    }
  }
}

@mixin page-main {
  padding-top: shared.$navigation-height-large;
  position: relative;
  z-index: scss.$z-index-page-main;

  @include scss.breakpoint-max-1199 {
    padding-top: shared.$navigation-height-mobile;
  }

  @include scss.breakpoint-min-2400 {
    margin: 0 auto;
  }

  // Adjustments for app view (reduced page view)
  &.PageMainAppView {
    padding-top: scss.$spacing-1;

    article {
      padding-bottom: scss.$spacing-1;
    }
  }

  // Content with max width of 12 columns
  article {
    background-color: scss.$palette-white-100;
    box-sizing: border-box;
    padding-bottom: scss.$spacing-4;
    width: 100%;

    > div > div:not(:global(.FullWidth)) {
      @include scss.container-width;

      @include scss.breakpoint-between(scss.$breakpoint-mobile-xl, scss.$breakpoint-desktop-1600) {
        margin: 0 scss.spacing-px-to-rem(24);
      }
    }

    &:global(.PressRelease) {
      > div > div:first-child {
        margin-bottom: scss.spacing-px-to-rem(96);

        > div {
          @include scss.default-width;
        }

        p {
          @include scss.display-3(bold);
        }

        h1 {
          margin: 0;
        }
      }
    }
  }
}
