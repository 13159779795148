@use 'src/assets/scss';

.LoadingButton {
  .LoadingIcon {
    color: scss.$palette-white-100;
    margin-left: scss.px-to-rem(20);
  }

  .DoneIcon {
    height: scss.px-to-rem(25);
    margin-left: scss.px-to-rem(20);
    width: scss.px-to-rem(25);
  }

  &.Done,
  &.Loading {
    background-color: scss.$palette-primary-dark-100;
  }
}
