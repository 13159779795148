@use '/src/assets/scss';

.TableContainer {
  &:global(.MuiTableContainer-root) {
    // align first line with content horizontally
    margin-top: calc(#{scss.$spacing-05} * -1);

    &.MaxWidth {
      @include scss.default-width();
      // overwrite mixin value
      margin-top: calc(#{scss.$spacing-05} * -1);
    }
  }

  :global(.MuiTableCell-root) {
    @include scss.display-5(normal);
    border-bottom: 2px solid scss.$palette-blue-dark-25;
    border-color: scss.$palette-blue-dark-25;
    color: scss.$palette-blue-dark-100;
    overflow-wrap: anywhere;
    // padding left 0 to align with other content vertically
    padding: scss.$spacing-05 scss.$spacing-1 scss.$spacing-05 0;
  }

  &.SeparateHeader {
    :global(.MuiTableCell-head) {
      border-bottom: 4px solid scss.$palette-blue-dark-25;
    }
  }

  &.ManualHyphens {
    :global(.MuiTableCell-body) * {
      hyphens: manual;
    }
  }

  :global(.MuiTableCell-head) {
    white-space: nowrap;
  }

  :global(.MuiTableBody-root .MuiTableRow-root):last-child {
    :global(.MuiTableCell-root) {
      border-bottom: 0;
    }
  }
}
