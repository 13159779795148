@use '/src/assets/scss';

.Appointment {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: scss.spacing(1) 0;

  .Title {
    @include scss.display-2(bold);
    margin: 0 0 scss.spacing(1) 0;
    text-align: center;
  }
}
