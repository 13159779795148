@use 'src/assets/scss';

.WizardHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: scss.$spacing-15;

  .ProgressIndicator {
    background-color: scss.$palette-blue-dark-25;
    height: 2px;
    margin-bottom: scss.$spacing-05;
    position: relative;

    .ProgressIndicatorIcon {
      background-color: scss.$palette-white-100;
      height: scss.px-to-rem(12);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: scss.px-to-rem(12);
    }

    .IconCurrentStep {
      @include scss.interactive-focus;
      background-color: scss.$palette-primary-100;
      border-radius: 100%;
      height: scss.px-to-rem(12);
      width: scss.px-to-rem(12);
    }

    .IconDoneStep {
      color: scss.$palette-primary-100;
      height: scss.px-to-rem(8);
      left: scss.px-to-rem(2);
      position: absolute;
      top: scss.px-to-rem(2);
      width: scss.px-to-rem(8);
    }

    .IconStep {
      background-color: scss.$palette-blue-dark-25;
      border-radius: 100%;
      height: scss.px-to-rem(6);
      margin: scss.px-to-rem(3);
      width: scss.px-to-rem(6);
    }
  }

  &.WizardHeaderGray .ProgressIndicatorIcon {
    background-color: scss.$palette-blue-dark-3;
  }

  .ProgressStep {
    @include scss.display-6;
    background-color: transparent;
    border: 0;
    flex: 1;
    line-height: 120%;
    text-align: center;

    &.Current .ProgressIndicator {
      background: linear-gradient(90deg,
      scss.$palette-primary-100 0%,
      scss.$palette-primary-100 50%,
      scss.$palette-blue-dark-25 50%,
      scss.$palette-blue-dark-25 100%);
    }

    &.Current {
      color: scss.$palette-primary-100;
    }

    &.Done .ProgressIndicator {
      background-color: scss.$palette-primary-100;
    }

    &.Done {
      cursor: pointer;
    }

    &:focus-visible {
      outline: none;

      .ProgressStepTitle {
        @include scss.interactive-focus;
      }
    }
  }
}
