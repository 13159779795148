@use 'src/assets/scss';

/**
  Calculation for $backdrop-pos2
  Height of the Flyout - (Height of NavDrop - Top of Flyout)

  Normal size: 561 - (196 - 146) = 511
  Small: 561 - (87 - 66) = 540
 */

$backdrop-pos1: 0%;
$backdrop-pos2: scss.px-to-rem(511);
$backdrop-pos2-small: scss.px-to-rem(540);
$backdrop-pos3: 100%;
$backdrop-color1: scss.$color-navigation-backdrop;
$backdrop-color2: scss.$color-navigation-backdrop;
$backdrop-color3: scss.$palette-white-100;

$flyout-bottom-margin: scss.px-to-rem(26);
$meta-menu-height: scss.px-to-rem(40);
