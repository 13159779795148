@use 'breakpoints';
@use 'helper';
@use 'spacings';

// Total width of the page including navigation and main content
$max-width-page: 2400px;
// Spacing unit between the grid columns
$grid-space-unit: spacings.$spacing-1;
// Max width for hero section
$max-width-hero: 1870px;
// Max width for the main article
$max-width-article: calc(1680px - #{$grid-space-unit} * 6); // 3x for each side

$max-width-extra-small: 556px;
$max-width-small: 744px;
$max-width-default: 1008px;
$max-width-form: 840px;
$max-width-extra-margin: 1056px;

@mixin container-width {
  margin: 0 auto;
  padding: 0 $grid-space-unit;

  @include breakpoints.breakpoint-min-1024 {
    max-width: $max-width-article;
  }
}

@mixin default-width {
  margin: 0 auto;

  @include breakpoints.breakpoint-min-1024 {
    max-width: $max-width-default;
  }
}

@mixin small-width {
  margin: 0 auto;

  @include breakpoints.breakpoint-min-1024 {
    max-width: $max-width-small;
  }
}

@mixin form-width {
  margin: 0 auto;

  @include breakpoints.breakpoint-min-1024 {
    max-width: $max-width-form;
  }
}

@mixin extra-margin-width {
  margin: 0 auto;

  @include breakpoints.breakpoint-min-1024 {
    max-width: $max-width-extra-margin;
  }
}
