@use 'src/assets/scss';
@use '../../shared-vals' as shared;
@use '../../shared-styles';

.NavigationDrawer {
  @include shared-styles.navigation-drawer;
}

.NavigationDrawerContent {
  @include shared-styles.navigation-content;
  flex: 1;

  .DownloadButtonItem {
    padding: scss.px-to-rem(20) 3.5rem;
  }

  .DownloadButton {
    @include shared-styles.navigation-button-style;
    min-height: scss.px-to-rem(40);
    width: 100%;

    &:hover {
      animation: none;
    }
  }
}
