@use 'fonts';
@use 'colors';
@use 'theme';
@use 'helper';

// Override Calendar (used in text input component)
.MuiCalendarPicker-root {
  .MuiPickersCalendarHeader-labelContainer {
    overflow: visible;
  }

  .MuiPickersDay-root:hover {
    background-color: colors.$palette-blue-dark-8;
    cursor: pointer;
  }

  .PrivatePickersYear-yearButton,
  .MuiButtonBase-root {
    &.Mui-selected:hover,
    &.Mui-selected {
      background-color: colors.$palette-primary-100;

      &:focus {
        background-color: colors.$palette-primary-100;
        color: colors.$palette-white-100;
      }
    }

    &:not(.Mui-selected) {
      border: 0;

      &.MuiPickersDay-today {
        @include fonts.roboto-font-style(bold);
      }
    }

    &:focus {
      @include helper.interactive-focus-inset();
      background-color: transparent;
      color: theme.$color-text-default;
    }
  }
}
