@use 'src/assets/scss';

.SkipLinkNavigation {
  align-items: center;
  background: rgba(scss.$palette-blue-dark-100, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  z-index: scss.$z-index-skip-link-nav;

  .Box {
    background: scss.$palette-white-100;
    display: flex;
    flex-direction: column;
    padding: scss.px-to-rem(24);

    .Instruction {
      @include scss.copy-5('bold');
      margin: 0 0 scss.px-to-rem(16) 0;
      padding: scss.px-to-rem(4) scss.px-to-rem(48);
    }

    a {
      @include scss.copy-5('normal');
      border-radius: 5px;
      border-width: 0;
      color: scss.$palette-blue-dark-100;
      flex: 0 1 auto;
      outline: 0;
      padding: scss.px-to-rem(4) scss.px-to-rem(48);
      text-decoration: none;

      &:focus {
        @include scss.interactive-focus();
        background: scss.$palette-blue-light-25;
      }
    }
  }

  &:focus-within {
    transform: translateY(0%);
  }
}
