@use 'src/assets/scss';

.WagonSelection {
  display: flex;
  flex-direction: column;

  margin-bottom: scss.$spacing-1;

  .WagonSelectionLabel {
    @include scss.label-external();
  }

  .WagonSelectionItemContainer {
    display: flex;
    flex-direction: column;
    gap: scss.spacing-px-to-rem(12);
  }
}
