@use 'src/assets/scss';

.IconList {
  @include scss.default-width;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include scss.display-3(bold);
  }
}
