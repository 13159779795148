/* Rheinbahn Colors */
$palette-primary-100: #e10025;
$palette-white-100: #fff;
$palette-primary-dark-100: #9b1926;
$palette-primary-dark-25: #e6c5c9;
$palette-primary-dark-50: #cd8c92;
$palette-primary-dark-75: #b4525c;

$palette-violett-100: #7f1653;
$palette-violett-25: #dfc5d4;
$palette-violett-50: #bf8aa9;
$palette-violett-75: #9f507e;

$palette-pink-100: #ce3783;
$palette-pink-25: #f3cde0;
$palette-pink-50: #e69bc1;
$palette-pink-75: #da69a2;

$palette-blue-light-100: #69bbd8;
$palette-blue-light-25: #d9eef5;
$palette-blue-light-50: #b4ddeb;
$palette-blue-light-75: #8ecce2;

$palette-blue-dark-100: #4c6170;
$palette-blue-dark-3: #f8f9f9;
$palette-blue-dark-8: #f1f2f4;
$palette-blue-dark-25: #d2d7db;
$palette-blue-dark-50: #a5b0b7;
$palette-blue-dark-75: #798894;

$palette-black-100: #000;
$palette-black-25: #bfbfbf;
$palette-black-50: #808080;
$palette-black-75: #404040;
$palette-black-85: #262626;

$palette-special-bus: #ffb60a;
$palette-special-strassenbahn: #e40521;
$palette-special-ubahn: #0056a4;
$palette-special-metrobus: #009e55;
$palette-special-schnellbus: #009ba4;
$palette-special-schienenersatz: #9b4d75;
$palette-special-nachtlinien: #1d1d1b;
$palette-special-ferienfahrplan: #00aaa1;

/* Development Colors */
$palette-interactive-blue-dark-a: #35444e;
$palette-interactive-blue-dark-b: #2a363e;
$palette-interactive-primary-dark-a: #8c1722;
$palette-interactive-blue-light-a: #eaebed;
$palette-blue-light-a: #e4e7ea;
