// Roboto
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('Roboto/Roboto.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('Roboto/Roboto-Bold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('Roboto/Roboto-Italic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('Roboto/Roboto-Bold-Italic.woff2') format('woff2');
}

// Roboto Condensed
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('Roboto_Condensed/Roboto_Condensed.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('Roboto_Condensed/Roboto_Condensed-Bold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('Roboto_Condensed/Roboto_Condensed-Bold-Italic.woff2') format('woff2');
}
