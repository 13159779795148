@use '/src/assets/scss';

.FilterTagGroup {
  @include scss.small-width();
  align-items: center;
  display: flex;
  margin-bottom: scss.$spacing-1;
  margin-top: calc(-1 * #{scss.px-to-rem(4)});
  overflow: auto;

  @include scss.breakpoint-max-1339 {
    @include scss.extra-margin-width();
    padding: scss.$spacing-1 scss.$spacing-2;
  }

  @include scss.breakpoint-max-1023 {
    padding: scss.$spacing-1 0;
  }

  @include scss.breakpoint-max-599 {
    padding-bottom: 0;
  }

  :global(.MuiToggleButtonGroup-root) {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: scss.$spacing-05;
    height: calc(100% + #{scss.px-to-rem(8)});
    overflow: visible;
    padding-bottom: scss.px-to-rem(4);
    padding-top: scss.px-to-rem(4);
  }

  :global(.Label) {
    @include scss.display-6(bold);
    color: scss.$palette-blue-dark-100;
    flex: 0 1 auto;
    margin-right: scss.$spacing-05;

    @include scss.breakpoint-max-599 {
      display: none;
    }
  }
}
