@use 'src/assets/scss';
@use '../navigation-vals' as nav;
@use '../../shared-vals' as shared;
@use '../../shared-styles';

.NavigationHeaderLink {
  @include shared-styles.navigation-header-link;

  &.Selected {
    @include scss.display-5(bold, normal, none);
    position: relative;

    &::after {
      background-color: scss.$palette-white-100;
      bottom: nav.$flyout-bottom-margin;
      content: '';
      display: inline-block;
      height: scss.px-to-rem(14);
      left: 0;
      position: absolute;
      right: 0;
      transition: bottom shared.$transition-speed ease;
      transition-delay: shared.$transition-delay;
    }
  }

  &.Small {
    @include scss.breakpoint-min-1200 {
      &.Selected::after {
        bottom: 0;
      }
    }
  }
}
