@use 'src/assets/scss';

.ToggleButtonGroupWidget {
  &:global(.MuiToggleButtonGroup-root) {
    // use grid to prevent layout shift on hover due to change from normal to bold font
    display: grid;
    grid-template: 1fr / repeat(auto-fit, minmax(50px, auto));
    margin-bottom: scss.$spacing-1;
  }

  &.HasLabel {
    &:global(.MuiToggleButtonGroup-root) {
      // use grid to prevent layout shift on hover due to change from normal to bold font
      display: grid;
      grid-template: 2fr / repeat(auto-fit, minmax(50px, auto));
    }

    :global(.MuiToggleButton-root) {
      grid-row: 2;
    }

    :global(.MuiToggleButtonGroup-grouped) {
      @include scss.label-external();
      grid-row: 1;
    }
  }
}
