/* ---------------- */
/* Font Definitions */
/* ---------------- */
/* Roboto Condensed */
@mixin roboto-condensed-normal {
  font-family: 'Roboto Condensed', 'Roboto', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin roboto-condensed-bold {
  font-family: 'Roboto Condensed', 'Roboto', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin roboto-condensed-bold-italic {
  font-family: 'Roboto Condensed', 'Roboto', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 700;
}

/* Roboto */
@mixin roboto-normal {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin roboto-bold {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin roboto-italic {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin roboto-bold-italic {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 700;
}

/* -------------- */
/* Base Font Size */
/* -------------- */
$font-size-base-default: 16px;
$font-size-base-600: 0.75rem;
$font-size-base-900: 1.125rem;
$font-size-base-1200: 1.5rem;
$font-size-base-2400: 3rem;
$font-size-dynamic: 2vw; // based on 1200px breakpoint

/* ------ */
/* Helper */
/* ------ */
// Returns the correct Roboto Condensed font depending on the optional given weight and style.
@mixin roboto-condensed-font-style($weight: normal, $style: normal) {
  @if $weight == bold and $style == italic {
    @include roboto-condensed-bold-italic;
  } @else if ($weight == bold and $style == normal) {
    @include roboto-condensed-bold;
  } @else {
    @include roboto-condensed-normal;
  }
}

// Returns the correct Roboto font depending on the optional given weight and style.
@mixin roboto-font-style($weight: normal, $style: normal) {
  @if $weight == bold and $style == italic {
    @include roboto-bold-italic;
  } @else if ($weight == bold and $style == normal) {
    @include roboto-bold;
  } @else if ($weight == normal and $style == italic) {
    @include roboto-italic;
  } @else {
    @include roboto-normal;
  }
}

// Multiplies the given factor with the min and max font size threshold
@function font-factor(
  $factor,
  $font-size-min-threshold: $font-size-base-600,
  $font-size-max-threshold: $font-size-base-1200
) {
  @return calc(clamp(#{$font-size-min-threshold}, #{$font-size-dynamic}, #{$font-size-max-threshold}) * #{$factor});
}

/* ----------------------------------------- */
/* Mixins for fonts (Display 0-7 & Copy 5-7) */
/* ----------------------------------------- */
// Display 0 – Headline in normal or bold
@mixin display-0(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 4.1667;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.015em;
  @if $lineHeight == default {
    line-height: 130%;
  }
}

// Display 1 – Headline in normal or bold
@mixin display-1(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 2.6667;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.015em;
  @if $lineHeight == default {
    line-height: 130%;
  }
}

// Display 2 – Headline in normal or bold
@mixin display-2(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 2;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.015em;
  @if $lineHeight == default {
    line-height: 150%;
  }
}

// Display 3 – Headline in normal or bold
@mixin display-3(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 1.5;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 170%;
  }
}

// Display 4 – Headline in normal or bold
@mixin display-4(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 1.1667;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 205%;
  }
}

// Display 5 – Headline in normal or bold
@mixin display-5(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 1;
  @include roboto-condensed-font-style($weight, $style);
  font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 210%;
  }
}

// Display 6 – Headline in normal or bold
@mixin display-6(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 0.75;
  $font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  @include roboto-condensed-font-style($weight, $style);
  font-size: max(#{$font-size}, 16px); // will be 0.75rem (if 0.75em > 16px), but at least 16px
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 210%;
  }
}

// Display 7 – Headline in normal or bold
@mixin display-7(
  $weight: normal,
  $style: normal,
  $lineHeight: default,
  $fontSizeMinThreshold: $font-size-base-900,
  $fontSizeMaxThreshold: $font-size-base-1200
) {
  $font-factor: 0.583;
  $font-size: font-factor($font-factor, $fontSizeMinThreshold, $fontSizeMaxThreshold);
  @include roboto-condensed-font-style($weight, $style);
  font-size: max(#{$font-size}, 14px);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 210%;
  }
}

// Copy 5 – Text in normal or bold
@mixin copy-5($weight: normal, $style: normal, $lineHeight: default) {
  $font-factor: 1;
  @include roboto-font-style($weight, $style);
  font-size: font-factor($font-factor, $font-size-base-900, $font-size-base-1200);
  letter-spacing: 0.025em;
  @if $lineHeight == default {
    line-height: 200%;
  }
}

// Copy 6 – Text in normal or bold
@mixin copy-6($weight: normal, $style: normal, $lineHeight: default) {
  $font-factor: 0.75;
  $font-size: font-factor($font-factor, $font-size-base-900, $font-size-base-1200);
  @include roboto-font-style($weight, $style);
  font-size: max(#{$font-size}, 16px);
  letter-spacing: 0.025em;
  @if $lineHeight == default {
    line-height: 200%;
  }
}

// Copy 7 – Text in normal or bold
@mixin copy-7($weight: normal, $style: normal, $lineHeight: default) {
  $font-factor: 0.583;
  $font-size: font-factor($font-factor, $font-size-base-900, $font-size-base-1200);
  @include roboto-font-style($weight, $style);
  font-size: max(#{$font-size}, 14px);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 220%;
  }
}

// Label - Text for labels
@mixin label($weight: bold, $style: italic, $lineHeight: default) {
  $font-factor: 0.5;
  $font-size: font-factor($font-factor, $font-size-base-900, $font-size-base-1200);
  @include roboto-condensed-font-style($weight, $style);
  font-size: max(#{$font-size}, 12px);
  letter-spacing: 0.02em;
  @if $lineHeight == default {
    line-height: 100%;
  }
}

// label outside of element
@mixin label-external() {
  @include display-6();
}
