/* Global Spacings */
@use 'fonts';
@use 'helper';
@use 'sass:math';

$space-base-size: fonts.font-factor(1, fonts.$font-size-base-900, fonts.$font-size-base-1200);

@function spacing($factor) {
  @return calc(#{$space-base-size} * #{$factor});
}

/**
* Use pixel of desktop breakpoints (>= 1200)
*/
@function spacing-px-to-rem($px) {
  @return spacing(
    math.div($px, (helper.strip-unit(fonts.$font-size-base-1200) * helper.strip-unit(fonts.$font-size-base-default)))
  );
}

// 12px
$spacing-05: spacing(0.5);
// 24px
$spacing-1: spacing(1);
// 36px
$spacing-15: spacing(1.5);
// 48px
$spacing-2: spacing(2);
// 60px
$spacing-25: spacing(2.5);
// 72px
$spacing-3: spacing(3);
// 84px
$spacing-35: spacing(3.5);
// 96px
$spacing-4: spacing(4);
