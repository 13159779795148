@use 'src/assets/scss';

.NavigationFlyoutSection {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  a,
  span {
    color: scss.$palette-blue-dark-100;
    text-decoration: none;
  }

  a:hover {
    color: scss.$palette-primary-100;
  }

  a:focus-visible {
    border-radius: 2px;
    box-shadow: 0 0 0 2px scss.$palette-white-100, 0 0 0 5px scss.$color-interactive-focus;
    outline: 0;

    /*
    TODO fix linter error
    The linter error is shown, because stylelint doesn't like
    the combination of an element (a) with a class (.Current).
    Needs to be moved to SubNavLink and SubSubNavLink and checked,
    if everything still works properly.
     */
    /* stylelint-disable selector-no-qualifying-type */
    &.Current {
      /* stylelint-enable */
      margin-bottom: 0;
      padding-bottom: scss.px-to-rem(8);

      &::after {
        margin-bottom: scss.px-to-rem(8);
      }
    }
  }

  .SubNavLink {
    @include scss.display-5('bold');
    line-height: scss.px-to-rem(28);
    margin-bottom: scss.px-to-rem(13);
    margin-top: scss.px-to-rem(31);
    position: relative;

    &a:hover {
      color: scss.$palette-primary-100;
    }

    &.Current:focus-visible {
      margin-bottom: scss.px-to-rem(13 - 8);
      padding-bottom: scss.px-to-rem(8);

      &::after {
        margin-bottom: scss.px-to-rem(8);
      }
    }

    &.Current::after {
      background-color: scss.$palette-blue-dark-100;
      bottom: scss.px-to-rem(-6);
      content: '';
      height: scss.px-to-rem(4);
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .SubNavLink::before {
    background: linear-gradient(45deg,
    transparent 0%,
    transparent 50%,
    scss.$palette-primary-100 50%,
    scss.$palette-primary-100 100%);
    content: '';
    display: inline-block;
    height: scss.px-to-rem(14);
    position: absolute;
    transform: translate(-36px, 0.34rem) rotate(45deg);
    width: scss.px-to-rem(14);
  }

  .SubSubNavLink {
    @include scss.display-6;
    line-height: scss.px-to-rem(21);
    margin-bottom: scss.px-to-rem(8);
    margin-top: scss.px-to-rem(8);
    position: relative;

    span {
      display: inline-block;
      position: relative;
    }

    &:hover {
      color: scss.$palette-primary-100;
    }

    &:hover::before {
      background: linear-gradient(45deg,
      transparent 0%,
      transparent 50%,
      scss.$palette-blue-dark-100 50%,
      scss.$palette-blue-dark-100 100%);
      content: '';
      display: inline-block;
      height: scss.px-to-rem(10);
      opacity: 0.25;
      position: absolute;
      transform: translate(-24px, 0.34rem) rotate(45deg);
      width: scss.px-to-rem(10);
    }

    &.Current::after {
      background-color: scss.$palette-blue-dark-100;
      bottom: scss.px-to-rem(-6);
      content: '';
      height: scss.px-to-rem(4);
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}
