@use 'src/assets/scss';

.HeadlineContainer {
  @include scss.default-width();
  align-items: baseline;
  column-gap: scss.$spacing-2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: scss.$spacing-4;

  h1,
  h2 {
    @include scss.display-2(bold);
  }
}
