@use 'src/assets/scss';
@use '../../shared-styles';

.NavigationDrawerHeader {
  @include shared-styles.navigation-drawer-header;

  .NavigationDrawerTitle {
    @include scss.display-4(bold);
    @include scss.text-ellipsis;
    color: scss.$palette-white-100;
    cursor: pointer;
    padding-left: 3.5rem;

    span {
      position: relative;

      &::before {
        background: linear-gradient(-135deg,
        transparent 0%,
        transparent 50%,
        scss.$palette-white-100 50%,
        scss.$palette-white-100 100%);
        content: '';
        height: scss.px-to-rem(12);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-1.5rem, -50%) rotate(45deg);
        width: scss.px-to-rem(12);
      }
    }
  }
}
