@use 'src/assets/scss';
@use 'sass:math';

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$diagonal: 16px;
$big-teaser-outer-spacing: scss.px-to-rem(5);

@mixin overlay-text() {
  @include scss.display-3(bold, normal, none);
  align-items: center;
  color: scss.$palette-white-100;
  display: flex !important;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.BigTeaser {
  @include scss.reset-anchor-styles();
  color: unset;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-bottom: scss.$spacing-1;
  margin-top: scss.$spacing-1;
  position: relative;
  text-decoration: none !important;

  .ImageWrapper {
    @include scss.image-hover();
    height: 0;
    overflow: hidden !important;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    [data-scrivito-image-placeholder='true'] {
      left: 0;
      min-height: 100%;
      position: absolute;
      top: 0;
    }
  }

  .Button {
    @include scss.link-button-style();
    background-blend-mode: multiply;
    border-radius: 0;
    bottom: scss.$spacing-3;
    height: scss.px-to-rem(56);
    overflow: hidden;
    padding: scss.px-to-rem(3) scss.px-to-rem(24);
    position: absolute;
    right: scss.$spacing-3;
    text-transform: none;

    @include scss.breakpoint-max-599() {
      @include scss.display-7(bold, normal, none);
      background-color: scss.$palette-blue-dark-8;
      bottom: scss.px-to-rem(12);
      height: scss.px-to-rem(40);
      justify-content: flex-start;
      left: 0;
      position: relative;
      right: 0;
      width: 100%;
    }

    @include scss.breakpoint-between(600px, 899px) {
      &.ButtonLeft {
        bottom: scss.$spacing-1;
        left: scss.$spacing-1;
        right: unset;
      }

      &.ButtonRight {
        bottom: scss.$spacing-1;
        left: unset;
        right: scss.$spacing-1;
      }
    }

    @include scss.breakpoint-min-900 {
      &.ButtonLeft {
        bottom: scss.$spacing-2;
        left: scss.$spacing-2;
        right: unset;
      }

      &.ButtonRight {
        bottom: scss.$spacing-2;
        left: unset;
        right: scss.$spacing-2;
      }
    }
    @include scss.breakpoint-min-1200 {
      &.ButtonLeft {
        bottom: scss.$spacing-3;
        left: scss.$spacing-3;
        right: unset;
      }

      &.ButtonRight {
        bottom: scss.$spacing-3;
        left: unset;
        right: scss.$spacing-3;
      }
    }

    &:hover {
      background-color: scss.$palette-white-100;

      @include scss.breakpoint-between(0, scss.$breakpoint-mobile-xl) {
        background-color: scss.$palette-blue-dark-8;
      }
    }
  }

  &:focus {
    &::before {
      @include scss.interactive-focus();
      background: scss.$palette-white-100;
      border-radius: 5px;
      content: '';
      height: calc(100% + 2 * #{$big-teaser-outer-spacing});
      left: calc(-1 * #{$big-teaser-outer-spacing});
      position: absolute;
      top: calc(-1 * #{$big-teaser-outer-spacing});
      width: calc(100% + 2 * #{$big-teaser-outer-spacing});
    }
  }

  /* stylelint-disable-next-line order/order */
  @include scss.image-wrapper-states();

  .BigTeaserNotice {
    @include scss.copy-7;
    display: none; // disable it until a better solution is found
    line-height: 1.2;
    text-align: center;
  }
}
