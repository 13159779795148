@use 'src/assets/scss';

.ColorBoxWidget {
  padding-bottom: scss.spacing-px-to-rem(96);
  padding-top: scss.spacing-px-to-rem(96);

  > div {
    @include scss.container-width;
    display: flex;
    flex-direction: column;

    > div:last-child {
      :global(.Spacing),
      :global(.SpacingLarge),
      :global(.SpacingSmall) {
        margin-bottom: 0;
      }
    }
  }

  &:global(.darkGreen) {
    background-color: scss.$palette-blue-dark-100;
    color: scss.$palette-white-100;

    li svg {
      color: scss.$palette-white-100;
    }

    td,
    th {
      color: scss.$palette-white-100;
    }
  }

  &:global(.darkRed) {
    background-color: scss.$palette-primary-dark-100;
    color: scss.$palette-white-100;

    li svg {
      color: scss.$palette-white-100;
    }

    td,
    th {
      color: scss.$palette-white-100;
    }
  }

  &:global(.violet) {
    background-color: scss.$palette-violett-100;
    color: scss.$palette-white-100;

    li svg {
      color: scss.$palette-white-100;
    }

    td,
    th {
      color: scss.$palette-white-100;
    }
  }

  &:global(.red) {
    background-color: scss.$palette-primary-100;
    color: scss.$palette-white-100;

    li svg {
      color: scss.$palette-white-100;
    }

    td,
    th {
      color: scss.$palette-white-100;
    }
  }

  &:global(.gray) {
    background-color: scss.$palette-blue-dark-8;
    color: scss.$palette-blue-dark-100;
  }
}
