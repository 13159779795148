@use 'src/assets/scss';

.Breadcrumb {
  @include scss.reset-default();
  // Hide on mobile
  @include scss.breakpoint-max-1023 {
    display: none;
  }

  // Set default width for widget (can be overwritten in the hero component if necessary)
  &:global(.MuiBreadcrumbs-root) {
    @include scss.default-width;
    margin-left: auto;
    margin-right: auto;
  }

  %breadcrumb-hover {
    color: scss.$palette-primary-100;
  }

  %breadcrumb-active {
    color: scss.$palette-primary-100;
  }

  %breadcrumb-focus {
    @include scss.interactive-focus();
    border-radius: scss.px-to-rem(5);
  }

  // anchor styling of all breadcrumb children
  a {
    @include scss.display-7(bold);
    border-bottom: 0;
    color: scss.$palette-blue-dark-100;
    text-decoration: none;

    &:focus,
    &:focus-visible {
      border: 0;
      box-shadow: none;
      outline: none;
    }
  }

  /** Breadcrumb Logo Component */
  .BreadCrumbLogoComponent {
    display: flex;
    padding-right: scss.px-to-rem(6);

    &:hover {
      svg {
        @extend %breadcrumb-hover;
      }
    }

    &:focus {
      svg {
        @include scss.interactive-focus();
        border-radius: scss.px-to-rem(5);
      }
    }
  }

  %breadcrumb-separator {
    border-color: transparent transparent transparent scss.$palette-blue-dark-25;
    border-style: solid;
    border-width: scss.px-to-rem(7) 0 scss.px-to-rem(7) scss.px-to-rem(7);
    height: 0;
    margin-right: scss.px-to-rem(12);
    overflow: hidden;
    width: 0;
  }

  /** Breadcrumb Separator Component */
  .BreadcrumbSeparatorComponent {
    @extend %breadcrumb-separator;
  }

  /** Breadcrumb Simple Link Component */
  .BreadCrumbSimpleLink {
    align-items: center;
    display: flex;
    height: scss.px-to-rem(29);
    padding: scss.px-to-rem(6) scss.px-to-rem(6);

    &.active {
      > a {
        @extend %breadcrumb-active;
      }
    }

    &:hover {
      > a {
        @extend %breadcrumb-hover;
      }

      .BreadcrumbSeparatorComponent {
        border-color: transparent transparent transparent scss.$palette-primary-100;
      }
    }

    &:focus-within {
      @extend %breadcrumb-focus;
    }
  }

  /** Overwrite MUI Breadcrumb Component's styling */
  // separator (set to empty string)
  :global(.MuiBreadcrumbs-separator) {
    display: none;
  }

  :global(.MuiButtonBase-root) {
    background: none;
    height: scss.px-to-rem(29);
    margin: 0;
    padding: scss.px-to-rem(6) scss.px-to-rem(6);

    &::before {
      @extend %breadcrumb-separator;
      content: '';
      margin-right: scss.px-to-rem(6);
    }

    &:hover {
      background: none;

      svg {
        @extend %breadcrumb-hover;
      }

      &::before {
        border-color: transparent transparent transparent scss.$palette-primary-100;
      }
    }

    &:focus {
      @extend %breadcrumb-focus;
      background: none;
    }
  }
}
