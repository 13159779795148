@use 'sass:math';

.NavigationFlyoutColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: #{36 - math.div(14, 2)}px; // Distance to triangle - half width of the box
  margin-right: 1rem;
  min-width: min(20vw, 400px);

  > *:first-child {
    > *:first-child {
      margin-top: 0;
    }
  }
}
