@use 'src/assets/scss';
@use '../../shared-vals' as shared;
@use '../../shared-styles';

.NavigationHeader {
  @include shared-styles.navigation-header;

  .LogoArea {
    @include shared-styles.navigation-header-logo-area;
    transition: padding-top shared.$transition-speed ease, opacity shared.$transition-speed ease,
      width shared.$transition-speed ease;
    width: scss.px-to-rem(212);

    @include scss.breakpoint-max-719 {
      min-width: scss.px-to-rem(54);
      overflow-x: hidden;
      width: scss.px-to-rem(54);

      svg {
        transform: translateY(7px);
      }

      .Text {
        opacity: 0;
      }
    }

    .LogoContainer {
      @include shared-styles.navigation-header-logo-container;
    }

    a:focus-visible .LogoContainer {
      @include shared-styles.navigation-header-logo-contained-focused;
    }
  }

  .LinksArea {
    @include shared-styles.navigation-header-links-area;

    @include scss.breakpoint-max-1199 {
      display: none;
    }
  }

  .TitleArea {
    align-items: center;
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: scss.spacing-px-to-rem(60);

    @include scss.breakpoint-max-1199 {
      display: flex;
      flex: 1;
      justify-content: center;
      margin-left: 0;
    }

    > a {
      @include scss.display-5(bold, italic, none);
      color: scss.$palette-white-100;
      letter-spacing: 0.02em;
      line-height: 170%;
      text-transform: uppercase;
      white-space: nowrap;

      @include scss.breakpoint-max-479 {
        @include scss.display-6(bold, italic, none);
        display: flex; // Prevents layout breakout
        line-height: 100%;
        text-align: center;
        white-space: break-spaces;
      }

      @include scss.breakpoint-max-374 {
        @include scss.display-7(bold, italic, none);
        display: flex; // Prevents layout breakout
        line-height: 100%;
        text-align: center;
        white-space: break-spaces;
      }

      span {
        padding: 0.5rem;
        text-align: center;
      }
    }
  }

  .ButtonArea {
    align-items: center;
    display: flex;
    flex-direction: row;

    @include scss.breakpoint-max-1199 {
      display: none;
    }

    a,
    button {
      @include shared-styles.navigation-button-style;
      height: scss.px-to-rem(56);
      transition: height shared.$transition-speed;

      span {
        @include scss.breakpoint-max-1439 {
          display: none;
        }
      }
    }

    svg {
      @include scss.breakpoint-max-1439 {
        margin-right: 0 !important;
      }
    }

    &.Small a {
      height: scss.px-to-rem(40);
    }
  }

  .IconsArea {
    @include shared-styles.navigation-header-icons-area;
  }
}
