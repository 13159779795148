@use 'src/assets/scss';

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$image-interaction-opacity: 0.4;

.CircleTeaserGroup {
  align-items: center;
  align-items: flex-end;
  column-gap: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: scss.$spacing-1;

  // special breakpoint
  // 396px = scss.$spacing-1 * 22
  @media screen and (min-width: 396px) {
    column-gap: scss.$spacing-4;
    justify-content: center;
  }

  // special breakpoint
  @media screen and (min-width: 480px) and (max-width: 599px) {
    &.circle-teaser-count-3 {
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      justify-items: center;

      .CircleTeaser:nth-child(even) {
        justify-self: flex-start;
      }

      .CircleTeaser:nth-child(odd) {
        justify-self: flex-end;
      }

      .CircleTeaser:nth-child(3) {
        grid-column: span 2;
        justify-self: center;
      }
    }

    &.circle-teaser-count-4 {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr;
      justify-items: center;

      .CircleTeaser:nth-child(even) {
        justify-self: flex-start;
      }

      .CircleTeaser:nth-child(odd) {
        justify-self: flex-end;
      }
    }
  }

  @include scss.breakpoint-min-600 {
    &.circle-teaser-count-3 {
      gap: 0;
      justify-content: space-evenly;
    }

    &.circle-teaser-count-4 {
      gap: 0;
      justify-content: space-between;
    }
  }

  @include scss.breakpoint-min-1200 {
    &.circle-teaser-count-3 {
      gap: scss.$spacing-4;
      justify-content: center;
    }
  }

  // special breakpoint
  // 1344px = scss.$spacing-1 * 56
  @media screen and (min-width: 1344px) {
    &.circle-teaser-count-4 {
      gap: scss.$spacing-4;
      justify-content: center;
    }
  }

  .CircleTeaser {
    $circle-teaser-size: calc(10 * clamp(0.75rem, 2vw, 1.5rem));
    border: 0;
    outline: none;
    text-decoration: none;
    width: $circle-teaser-size;

    .CircleTeaserFigure {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .CircleTeaserImageWrapper {
      background: scss.$palette-blue-dark-8;
      border-radius: 50%;
      display: flex;
      height: $circle-teaser-size;
      overflow: hidden;
      pointer-events: none;
      position: relative;
      width: $circle-teaser-size;

      .ImageContainer {
        border-radius: 50%;
        clip-path: content-box;
        height: $circle-teaser-size;
        overflow: hidden;
        position: relative;
        width: $circle-teaser-size;
      }

      .CircleTeaserImage {
        border-radius: 50%;
        height: $circle-teaser-size;
        min-height: 100%;
        object-fit: cover;
        transform: translateZ(0);
        transition-duration: $transition-duration-scale;
        width: $circle-teaser-size;
      }

      .CircleTeaserHoverOverlay,
      .CircleTeaserHoverText {
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity $transition-duration-hover ease;
        width: 100%;
      }

      .CircleTeaserHoverOverlay {
        background: scss.$palette-primary-100;
        border-radius: 50%;
        mix-blend-mode: multiply;
      }

      .CircleTeaserHoverText {
        @include scss.display-6(bold, normal, none);
        align-items: center;
        color: scss.$palette-white-100;
        display: flex;
        justify-self: center;
        line-height: 150%;

        .HoverTag {
          padding: scss.$spacing-05;
          text-align: center;
          width: 100%;
          z-index: 1;
        }
      }
    }

    .CircleTeaserHeadline {
      @include scss.display-5(bold, normal, none);
      color: scss.$palette-blue-dark-100;
      display: block;
      line-height: 150%;
      margin-bottom: scss.$spacing-05;
      text-align: center;

      @include scss.breakpoint-max-1199 {
        @include scss.display-6(bold, normal, none);
      }
    }

    &:focus,
    &:hover {
      .CircleTeaserImage {
        opacity: $image-interaction-opacity;
        transform: scale($scale-factor) translateZ(0);
      }

      .CircleTeaserHoverOverlay,
      .CircleTeaserHoverText {
        opacity: 1;
      }

      .CircleTeaserHeadline {
        @include scss.breakpoint-max-1199 {
          @include scss.display-6(bold, normal, none);
        }
      }
    }

    &:focus {
      .CircleTeaserImageWrapper {
        @include scss.interactive-focus();
        background: scss.$palette-white-100;
        // overwrite interactive focus to get more space between shadow and content
        box-shadow: 0 0 0 3px scss.$palette-white-100, 0 0 0 6px scss.$palette-blue-light-100;
      }
    }

    &:focus-visible {
      box-shadow: none;
      outline: 0;
    }

    &:hover {
      .CircleTeaserHeadline {
        color: scss.$palette-primary-100;
      }
    }

    &:active {
      .CircleTeaserImage {
        opacity: $image-interaction-opacity;
      }

      .CircleTeaserHoverOverlay {
        background: scss.$palette-primary-dark-100;
      }

      .CircleTeaserHoverText {
        display: none;
      }

      .CircleTeaserHeadline {
        color: scss.$palette-blue-dark-100;
      }
    }
  }
}
