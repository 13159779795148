@use 'src/assets/scss';
@use '../../shared-vals' as shared;

.NavigationDrawerMeta {
  background-color: scss.$palette-primary-dark-100;
  color: scss.$palette-white-100;
  transition: background-color shared.$drawer-color-transition-speed ease,
    color shared.$drawer-color-transition-speed ease;

  :global(.MuiButtonBase-root) {
    @include scss.display-6;
    padding: scss.px-to-rem(4.5) 3.5rem;
  }

  &:not(.Sub) :global(.MuiButtonBase-root):focus-visible {
    @include scss.display-6(bold);
    background-color: transparent;
    outline: 0;

    span {
      border-radius: 1px;
      box-shadow: 0 0 0 3px scss.$palette-primary-dark-100, 0 0 0 6px scss.$color-interactive-focus;
    }
  }

  &.Sub {
    background-color: scss.$palette-blue-dark-3;

    :global(.MuiButtonBase-root) {
      color: scss.$color-text-default;

      &:focus-visible {
        @include scss.display-6(bold);
        background-color: transparent;
        color: scss.$palette-primary-100;

        span {
          border-radius: 1px;
          box-shadow: 0 0 0 3px scss.$palette-blue-dark-3, 0 0 0 6px scss.$color-interactive-focus;
        }
      }
    }
  }
}
