@use 'src/assets/scss';

.AppBanner {
  position: fixed;
  background-color: scss.$palette-blue-dark-3;
  border: 2px solid scss.$palette-black-25;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  padding: scss.spacing-px-to-rem(18);
  display: grid;
  grid-template:
    'logo title close'
    'logo text text'
    'button button button';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  gap: 0 scss.spacing-px-to-rem(9);

  .Logo {
    grid-area: logo;

    img {
      width: scss.px-to-rem(56);
      height: scss.px-to-rem(56);
      object-fit: cover;
    }
  }

  .Title {
    @include scss.display-7(bold);
    grid-area: title;
  }

  .Text {
    @include scss.display-7;
    grid-area: text;
  }

  .Close {
    grid-area: close;
    // 18px is the button size in the design
    // 12px is the size of the icon
    // the difference is the padding
    padding: scss.spacing-px-to-rem(18 - 12);
    align-self: center;

    button {
      padding: 0;
      margin: 0;
      line-height: 0;
      border: 0;
      background-color: transparent;
      color: scss.$palette-blue-dark-75;

      &:hover {
        color: scss.$palette-blue-dark-100;
        cursor: pointer;
      }
    }

    svg {
      width: scss.px-to-rem(12);
      height: scss.px-to-rem(12);
      padding: 0;
      margin: 0;
    }
  }

  .Button {
    grid-area: button;
    padding-top: scss.spacing-px-to-rem(18);

    a {
      width: 100%;
    }
  }
}
