@use 'src/assets/scss';

.Timeline {
  @include scss.breakpoint-max-719 {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      overflow-wrap: anywhere;
    }
  }

  .Entries {
    margin-top: scss.px-to-rem(48);

    > div {
      display: flex;
      flex-direction: column;
      gap: scss.px-to-rem(36);
    }
  }
}
