@use 'src/assets/scss';

$container-grid-gap: scss.spacing-px-to-rem(30);
$outer-grid-gap-size: scss.$spacing-1;
$radio-size: scss.spacing-px-to-rem(24);

@mixin additional-information-checked-and-small() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > * {
    position: relative;
  }

  > *:not([style*='display: none']):not(:nth-last-child(-n + 2))::after {
    $dot-size: scss.spacing-px-to-rem(6);
    background: scss.$palette-primary-100;
    border-radius: 50%;
    content: '';
    height: $dot-size;
    position: absolute;
    right: calc(-1 * ((#{$container-grid-gap} / 2) + (#{$dot-size} / 2)));
    top: 50%;
    transform: translateY(-50%);
    width: $dot-size;
  }
}

.WagonSelectionItem {
  position: relative;

  .GridContainer {
    align-items: center;
    background: scss.$palette-white-100;
    border: 2px solid scss.$palette-blue-dark-25;
    border-radius: scss.$border-radius;
    color: scss.$palette-blue-dark-100;
    display: grid;
    gap: $outer-grid-gap-size;
    grid-template: 1fr / min-content 1fr;
    padding: scss.spacing-px-to-rem(12) scss.spacing-px-to-rem(24);
    transition: all 300ms ease;

    @include scss.breakpoint-max-719 {
      padding: scss.spacing-px-to-rem(9) scss.spacing-px-to-rem(18);
    }

    .Radio {
      align-items: center;
      align-self: baseline;
      background: scss.$palette-white-100;
      border: 1px solid scss.$palette-blue-dark-25;
      border-radius: 50%;
      display: inline-flex;
      grid-column: 1;
      grid-row: 1;
      height: $radio-size;
      justify-content: center;
      margin: scss.spacing-px-to-rem(12) 0;
      width: $radio-size;

      .RadioIndicator {
        border-radius: 50%;
        height: scss.spacing-px-to-rem(8);
        width: scss.spacing-px-to-rem(8);
      }
    }

    .Container {
      align-items: baseline;
      display: grid;
      gap: scss.spacing-px-to-rem(12);
      grid-template: 1fr / 1fr auto;

      @include scss.breakpoint-max-599 {
        grid-template: auto auto / 1fr;
      }

      .Label {
        @include scss.display-5(bold);
      }

      // container for year | capaciyt | duration
      .AdditionalInformation {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $container-grid-gap;
        justify-content: flex-end;
        row-gap: 0;

        @include scss.breakpoint-max-599 {
          @include additional-information-checked-and-small();
        }

        .Year {
          width: 4ch;
          @include scss.breakpoint-max-599 {
            width: auto;
          }
        }

        .CapacityContainer {
          line-height: 120%;
          text-align: center;

          @include scss.breakpoint-max-599 {
            // overwrite inline style
            width: auto !important;
          }
        }

        .PriceContainer {
          text-align: right;
          @include scss.breakpoint-max-599 {
            // overwrite inline style
            width: auto !important;
          }
        }
      }

      .Year,
      .CapacityContainer,
      .DurationContainer {
        @include scss.display-6();
        align-items: center;
        display: flex;
        gap: scss.spacing-px-to-rem(8);
      }

      .PriceContainer {
        @include scss.display-6();
        display: block;
      }

      > .PriceContainer {
        display: none;
      }

      // do not show in not checked state
      .DurationContainer,
      .ContentContainer {
        @include scss.display-6();
        display: none;
      }

      .ContentContainer {
        @include scss.copy-6();

        ul:last-child {
          margin: 0;
        }

        * {
          @include scss.copy-6();
        }

        li {
          margin: 0 0 0 scss.spacing-px-to-rem(18);
        }
      }

      .UnitShort {
        display: none;

        @include scss.breakpoint-max-599 {
          display: inline;
        }
      }

      .UnitLong {
        @include scss.breakpoint-max-599 {
          display: none;
        }
      }
    }

    .ImagesContainer {
      display: none;
    }
  }

  .Input {
    appearance: none;
    height: 100%;
    margin: 0;
    position: absolute;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  .Input:checked {
    + .GridContainer {
      align-items: flex-start;
      grid-template: 1fr / min-content 4fr 3fr;
      padding: scss.spacing-px-to-rem(24);

      @include scss.breakpoint-max-719 {
        grid-template: 2fr / auto 1fr;
        padding: scss.spacing-px-to-rem(18);
      }

      .Radio .RadioIndicator {
        background: scss.$palette-primary-100;
      }

      .Container {
        // space between year | capacity | duration
        column-gap: $container-grid-gap;
        grid-template: auto auto auto 1fr / 1fr;
        height: 100%;

        @include scss.breakpoint-max-719 {
          grid-column: 1 / -1;
          grid-row: 1;
        }

        .AdditionalInformation {
          @include additional-information-checked-and-small();
        }

        .PriceContainer {
          align-self: flex-end;
          display: block;
          text-align: left;
        }

        .PriceContainerInfo {
          display: none;
        }

        .Price {
          @include scss.display-5(bold);
        }

        .DurationContainer,
        .ContentContainer {
          display: flex;
        }

        .Label,
        .ContentContainer,
        .PriceContainer {
          grid-column: 1 / -1;
        }

        .Year,
        .CapacityContainer,
        .DurationContainer {
          display: flex;
          grid-row: 2;
          // overwrite inline style
          width: auto !important;
        }

        .Label {
          @include scss.breakpoint-max-719 {
            // align label correctly when outer and inner grid are overlapping
            margin-left: calc(#{$outer-grid-gap-size} + #{$radio-size});
          }
        }

        .Year {
          grid-column: 1;
        }

        .CapacityContainer {
          grid-column: 2;
        }

        .DurationContainer {
          grid-column: 3;
        }

        .DurationContainer,
        .CapacityContainer {
          position: relative;
        }
      }

      .ImagesContainer {
        display: grid;
        gap: calc(clamp(1.125rem, 2vw, 1.5rem) * 0.875);
        grid-auto-rows: auto;
        grid-template-columns: 100%;
        justify-items: stretch;

        @include scss.breakpoint-max-719 {
          gap: scss.spacing-px-to-rem(9);
          grid-auto-rows: auto;
          grid-column: 1 / -1;
          grid-template-columns: auto auto;

          > img {
            height: 100%;
          }
        }
      }
    }
  }

  //   &:focus-within,
  .Input:focus {
    + .GridContainer {
      .Radio {
        @include scss.interactive-focus();
      }
    }
  }

  &:hover {
    .Input:not(:disabled) {
      + .GridContainer {
        .Radio {
          background: scss.$palette-blue-dark-8;
        }
      }
    }
  }

  .Input:disabled {
    pointer-events: none;

    + .GridContainer {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
