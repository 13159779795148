@use 'src/assets/scss';
@use '../../building-blocks/navigation/shared-vals' as shared;
@use '../../building-blocks/page-banner/page-banner-vals' as banner;
@use './page-template.shared' as page-shared;

.PageWrapper {
  @include page-shared.page-wrapper;
}

.PageHeader {
  @include page-shared.page-header;
}

.PageMain {
  @include page-shared.page-main;
}

.PageHeaderDrop {
  background-color: scss.$palette-primary-100;
  height: scss.px-to-rem(196);
  left: 50%;
  max-width: scss.$max-width-page;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: height page-shared.$transition-speed ease;
  transition-delay: page-shared.$transition-delay;
  width: 100%;
  z-index: scss.$z-index-page-header-drop;

  @include scss.breakpoint-max-1199 {
    height: scss.px-to-rem(107);
    max-height: scss.px-to-rem(107);
    overflow-y: hidden;
    position: absolute;
    transition: transform page-shared.$transition-speed-mobile ease,
      margin-top page-shared.$transition-speed-mobile ease;
    transition-delay: 0ms;

    &.PositionFixed {
      position: fixed;
    }

    &.NoTransition {
      transition: unset;
    }

    &.Small {
      transform: translate(-50%, -100%);
    }
  }

  @include scss.breakpoint-max-599 {
    height: shared.$navigation-height-mobile;
  }

  &.BannerMargin {
    margin-top: banner.$banner-height;

    @include scss.breakpoint-max-1199 {
      &.Small {
        margin-top: 0;
      }
    }
  }
}
