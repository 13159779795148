@use '/src/assets/scss';

.UploadButton {
  display: flex;
  flex-direction: column;
  margin-bottom: scss.$spacing-1;

  .UploadFileWrapper {
    align-items: center;
    column-gap: scss.$spacing-1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: scss.$spacing-05;

    :global(.MuiFormControl-root) {
      align-self: flex-start;
      flex: 0 0 auto;
    }

    .FileListWrapper,
    .FileList {
      min-width: 0;
    }
  }

  .UploadButtonContainer {
    .LabelContainer {
      align-items: baseline;
      display: flex;
      gap: scss.$spacing-1;

      .Label {
        @include scss.label-external();
        color: scss.$palette-blue-dark-100;
        margin: 0;
      }

      .OptionalLabel {
        @include scss.form-element-optional();
      }
    }
  }

  .ErrorMessageWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: scss.$spacing-05;

    svg {
      color: scss.$color-interactive-error;
      flex: 0 0 auto;
      height: scss.px-to-rem(24);
      width: scss.px-to-rem(24);
    }
  }

  .ErrorText {
    @include scss.error-message;
    margin: 0;

    &.ErrorExceededMultipleFileSize {
      margin: 0 0 scss.$spacing-1;
    }
  }

  .HelperText {
    @include scss.helper-message;
    margin: 0;
  }
}
