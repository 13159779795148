@use 'src/assets/scss';
@use 'page-banner-vals' as banner;

.PageBanner {
  @include scss.color-transition;
  align-items: center;
  background-color: scss.$palette-special-bus;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: banner.$banner-height;
  justify-content: center;
  outline: 0;
  padding-left: scss.px-to-rem(16);
  padding-right: scss.px-to-rem(16);
  width: 100%;

  strong,
  svg,
  span {
    color: scss.$palette-black-100;
  }

  strong {
    @include scss.display-5(bold);
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    margin-left: scss.px-to-rem(16);
    margin-right: scss.px-to-rem(28);
  }

  span {
    @include scss.copy-6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include scss.breakpoint-max-599 {
      display: none;
    }
  }

  &.Hidden {
    display: none;
  }

  &:hover,
  &:focus-visible,
  &:active {
    background-color: scss.$color-button-cta-active;
    border-radius: 0;

    strong,
    svg,
    span {
      color: scss.$palette-white-100;
    }
  }

  &:focus-visible {
    border-radius: 1px;
    box-shadow: inset 0 0 0 3px scss.$palette-primary-100, inset 0 0 0 7px #69bbd8;
  }
}

.PageBannerSpacer {
  height: banner.$banner-height;
}
