@use 'src/assets/scss';
@use 'sass:math';

.AutoSuggest {
  @include scss.default-width();
  position: relative;

  :global(.MuiAutocomplete-root) {
    :global(.MuiTextField-root) {
      margin-bottom: 0;
    }
  }

  :global(.MuiFormControl-root) {
    :global(.MuiFilledInput-root) {
      padding: 0 !important;

      :global(.MuiAutocomplete-input) {
        padding-left: scss.px-to-rem(22);
        padding-right: calc(#{scss.px-to-rem(22)} + #{scss.$spacing-15});
        padding-top: scss.px-to-rem(25);
      }
    }
  }

  // Adjust flyout borders and box shadows
  // Flyout configuration
  :global(.MuiAutocomplete-popper) {
    @include scss.flyout-popper-style();
  }

  // Flyout list
  .AutoSuggestDropdown {
    @include scss.flyout-style();

    svg {
      flex: 0 0 1.5rem;
      margin-right: scss.$spacing-05;
    }
  }

  .LocationButton {
    @include scss.icon-button-style();
    position: absolute;
    right: 0.5rem; // input height (3.5rem) - button height (2.5rem) / 2
    top: 0.5rem;
    width: 2.5rem;
    z-index: scss.$z-index-input-icon-button;

    &:disabled {
      color: scss.$palette-blue-dark-75;
      cursor: default;
    }

    :global(.MuiCircularProgress-svg) {
      color: scss.$palette-primary-100;
    }
  }
}
