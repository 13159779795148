@use 'src/assets/scss';
@use '../../shared-vals' as shared;

.NavigationDrawerPager {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
  transition: margin-left 200ms ease;

  &.Sub {
    margin-left: -#{shared.$drawer-base-width};

    @include scss.breakpoint-max-374 {
      margin-left: -100vw;
    }
  }

  &.SubSub {
    margin-left: -#{shared.$drawer-sub-sub-width};

    @include scss.breakpoint-max-374 {
      margin-left: -200vw;
    }
  }
}
