@use 'src/assets/scss';

.OverviewPage {
  align-items: baseline;
  column-gap: scss.$spacing-2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: scss.$spacing-4;

  h1 {
    @include scss.display-2(bold);
  }

  .Filter {
    margin-top: scss.$spacing-05;
    padding: 0;
  }

  .PressReleasePreviewList {
    margin-top: scss.spacing-px-to-rem(72);
  }

  > div > * {
    @include scss.default-width;
  }
}
