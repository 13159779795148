@use 'src/assets/scss';

.KpiTileGroup {
  @include scss.reset-default();
  justify-content: center;
  margin-bottom: scss.$spacing-4;
  overflow: hidden;

  .KpiTileGroupGrid {
    aspect-ratio: 16 / 9;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @include scss.breakpoint-min-600 {
      grid-template-rows: auto 1fr;
    }

    &:not(.teaser-count-even) > * {
      aspect-ratio: 16 / 9;
      grid-column-end: span 12;

      @include scss.breakpoint-min-600 {
        grid-column-end: span 6;

        &:nth-child(n + 3) {
          aspect-ratio: auto;
          grid-column-end: span 4;

          // small tile, text full width
          > div:first-child {
            padding: scss.$spacing-1;
          }
        }
      }
    }

    &.teaser-count-even {
      > * {
        aspect-ratio: 16 / 9;
        grid-column-end: span 6;
        grid-row-end: auto;

        @include scss.breakpoint-max-599 {
          grid-column-end: span 12;
        }
      }
    }

    &.teaser-count-two {
      aspect-ratio: auto;
    }

    &.teaser-count-tree {
      aspect-ratio: auto;

      > * {
        aspect-ratio: 16 / 13.5;
        grid-column-end: span 4;
        grid-row-end: auto;

        @include scss.breakpoint-max-599 {
          grid-column-end: span 12;
        }

        // small tile, text full width
        > div:first-child {
          padding: scss.$spacing-1;
        }
      }
    }
  }
}
