@use 'src/assets/scss';
@use '../navigation-vals' as nav;
@use '../../shared-vals' as shared;

.NavigationHeaderMeta {
  height: nav.$meta-menu-height;
  left: 0;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  transition: height shared.$transition-speed ease;
  z-index: scss.$z-index-navigation-meta;

  @include scss.breakpoint-max-1199 {
    display: none;
  }

  .MetaLinksContainer {
    bottom: 0;
    left: 50%;
    max-width: scss.px-to-rem(1920);
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  a {
    // 0.25 rem inner padding (for focus). 24px spacing between labels = 20px margin
    @include scss.display-6(normal, normal, none);
    color: scss.$palette-white-100;
    line-height: scss.px-to-rem(40);
    margin-left: scss.px-to-rem(20);
    margin-right: scss.px-to-rem(20);
    padding: scss.px-to-rem(4);
    position: relative;
    text-decoration: none;

    .MetaLabel {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }

    .MetaLabelPlaceholder {
      @include scss.display-6(bold, normal, none);
      color: transparent;
      height: 1px;
      overflow: hidden;
      visibility: hidden;
      white-space: nowrap;
    }

    &:active {
      color: scss.$palette-white-100;
    }

    &:hover {
      @include scss.display-6(bold, normal, none);
    }

    &:focus-visible {
      border-radius: 1px;
      box-shadow: 0 0 0 1px scss.$color-interactive-focus, inset 0 0 0 2px scss.$color-interactive-focus;
      color: scss.$palette-white-100;
      outline: 0;
    }
  }

  &.Small {
    @include scss.breakpoint-min-1200 {
      height: 0;

      &:focus-within {
        background-color: scss.$palette-primary-dark-100;
        height: nav.$meta-menu-height;
      }
    }
  }
}
