@use 'src/assets/scss';

$icon-size: scss.px-to-rem(20);

.FormEditMode {
  // Makes it easier to reach the + button on top when the
  // Scrivito edit mode is active.
  padding-top: 2rem;
}

.FormWidget {
  @include scss.form-width();
  @include scss.copy-5();
  background: scss.$palette-white-100;
  display: flex;
  flex-flow: column;
  margin: 0 auto;

  @include scss.breakpoint-min-1024 {
    max-width: scss.widget-column-width(8);
  }

  // overwrite button secondary color for grey form
  :global(.MuiButton-root.MuiButton-text.MuiButton-textSecondary) {
    background: scss.$palette-white-100;
  }

  // Align content on the left side for large screens
  &.left {
    @include scss.breakpoint-min-1024 {
      margin-right: scss.widget-column-width(4);
    }
  }

  // Align content on the right side for large screens
  &.right {
    @include scss.breakpoint-min-1024 {
      margin-left: scss.widget-column-width(4);
    }
  }

  :global(.Column) {
    column-gap: scss.$spacing-1;
    margin: 0;
    row-gap: 0;
  }

  .Alert {
    align-items: center;
    column-gap: scss.$spacing-2;
    flex-direction: row;
    flex-wrap: wrap;
    margin: scss.$spacing-2 scss.$spacing-1 0 !important;
    padding: scss.$spacing-05 scss.$spacing-1 scss.$spacing-05
      calc(#{scss.$spacing-1} + #{scss.$spacing-1} + #{$icon-size});
    position: relative;
    row-gap: scss.spacing-px-to-rem(8);

    @include scss.breakpoint-min-900 {
      margin: scss.$spacing-2 scss.$spacing-2 0 !important;
    }

    :global(.MuiAlert-icon) {
      fill: scss.$palette-primary-100;
      left: scss.$spacing-1;
      margin: 0;
      padding: 0;
      position: absolute;
      top: calc(50% - #{$icon-size} * 0.5);
    }

    svg {
      height: $icon-size;
      width: $icon-size;
    }

    :global(.MuiAlert-message) {
      @include scss.copy-6;
      color: scss.$palette-primary-100;
      display: flex;
      flex-shrink: 0;
      margin-right: auto;
      max-width: 100%;
      padding: 0;
    }

    :global(.MuiAlert-action) {
      margin: 0;
      padding: 0;
    }

    .ErrorButton {
      @include scss.link-style();
      @include scss.copy-6(bold);
      background: none !important;
      box-shadow: none !important;
      display: block;
      height: auto;
      margin: 0;
      min-width: 0;
      outline: none !important;
      padding: 0;
      white-space: nowrap;
    }
  }

  .SubmitButton {
    margin-top: scss.$spacing-2;
  }
}
