@use 'src/assets/scss';

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$image-interaction-opacity: 0.4;

.TextTeaserGroup {
  @include scss.reset-default();
  display: flex;
  justify-content: center;
  margin-bottom: scss.$spacing-4;

  .TextTeaserGroupGrid {
    display: inline-grid;
    gap: scss.$spacing-2;

    // breakpoint min 375 (default)
    grid-template-rows: auto;
    width: 100%;

    @include scss.breakpoint-min-720 {
      &.text-teaser-count-1 {
        grid-template: auto / 1fr;
        justify-items: center;
      }

      &.text-teaser-count-2 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
      }

      &.text-teaser-count-3 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @include scss.breakpoint-max-899 {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;

          .TextTeaser:first-child {
            grid-column: span 2;
            justify-self: center;
            max-width: none;
            width: 100%;
          }
        }
      }

      &.text-teaser-count-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
      }

      &.text-teaser-count-5 {
        @include scss.breakpoint-max-899 {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, auto);

          .TextTeaser:first-child {
            grid-column: span 2;
            grid-row: 1;
            justify-self: center;
            max-width: none;
            width: 100%;
          }
        }
        @include scss.breakpoint-min-900 {
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(2, auto);
          justify-content: center;

          .TextTeaser {
            grid-column: span 2;
            grid-row: 2;
          }

          .TextTeaser:first-child {
            grid-column: 1 / 4;
            grid-row: 1;
            justify-self: flex-end;
          }

          .TextTeaser:nth-child(2) {
            grid-column: 4 / 7;
            grid-row: 1;
            justify-self: flex-start;
          }
        }
      }

      &.text-teaser-count-6 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, auto);
      }
    }
  }

  .TextTeaser {
    text-decoration: none;

    @include scss.breakpoint-min-720 {
      max-width: scss.spacing-px-to-rem(744);
    }

    .TextTeaserFigure {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-self: center;
    }

    .TextTeaserImageWrapper {
      display: flex;
      overflow: hidden;
      position: relative;

      .TextTeaserImage {
        height: auto;
        object-fit: cover;
        transition-duration: $transition-duration-scale;
        width: 100%;
      }

      .TextTeaserHoverOverlay,
      .TextTeaserHoverText {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity $transition-duration-hover ease;
        width: 100%;
      }

      .TextTeaserHoverOverlay {
        background: scss.$palette-primary-100;
        mix-blend-mode: multiply;
        pointer-events: none;
      }

      .TextTeaserHoverText {
        @include scss.display-6(bold, normal, none);
        align-items: center;
        color: scss.$palette-white-100;
        display: flex;
        justify-self: center;

        .HoverTag {
          padding: scss.spacing-px-to-rem(10);
          text-align: center;
          width: 100%;
          z-index: 1;
        }
      }
    }

    .TextTeaserSubject {
      @include scss.display-5(normal, normal, none);
      color: scss.$palette-blue-dark-100;
      display: block;
      flex: 1 1 auto;
      hyphens: auto;
      margin-top: scss.$spacing-05;
      padding-right: scss.$spacing-2;
      position: relative;

      &::after {
        border-color: transparent transparent scss.$palette-primary-100 transparent;
        border-style: solid;
        border-width: 0 0 scss.$spacing-1 scss.$spacing-1;
        bottom: 0.68em;
        content: '';
        height: 0;
        position: absolute;
        right: 0;
        width: 0;
      }
    }

    &:hover,
    &:focus {
      .TextTeaserHoverOverlay,
      .TextTeaserHoverText {
        opacity: 1;
        visibility: visible;
      }

      .TextTeaserImage {
        opacity: $image-interaction-opacity;
        transform: scale($scale-factor);
      }
    }

    &:focus {
      @include scss.interactive-focus();
      background: scss.$palette-white-100;
      border-radius: 5px;
      box-shadow: 0 0 0 6px scss.$palette-white-100, 0 0 0 9px scss.$color-interactive-focus;
    }

    &:active {
      .TextTeaserHoverText {
        .HoverTag {
          display: none;
        }
      }

      .TextTeaserImage {
        opacity: $image-interaction-opacity;
      }

      .TextTeaserHoverOverlay {
        background: scss.$palette-primary-dark-100;
      }
    }
  }
}
