@use '/src/assets/scss';

.ClosingLine {
  align-items: center;
  column-gap: scss.spacing(3);
  display: flex;
  justify-content: space-between;
  margin: scss.spacing(3) 0 0 0;
  row-gap: scss.spacing(2);

  @include scss.breakpoint-max-719 {
    flex-direction: column;
  }

  .License {
    @include scss.display-7(bold);
    flex: 1 0 auto;
    order: 1;

    @include scss.breakpoint-max-719 {
      order: 2;
    }
  }

  .LinkListContainer {
    column-gap: scss.spacing(3);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    order: 2;
    row-gap: scss.spacing(1);

    @include scss.breakpoint-max-719 {
      justify-content: center;
      order: 1;
    }

    .LinkList {
      column-gap: scss.spacing(3);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      list-style: none;
      margin: 0;
      row-gap: scss.spacing(1);

      @include scss.breakpoint-max-719 {
        flex-wrap: wrap;
        justify-content: center;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      a {
        @include scss.display-7(bold);
        border-image: linear-gradient(90deg, transparent, transparent, transparent) 1;
        display: inline-block;
        padding: scss.spacing-px-to-rem(3);
      }

      &.Normal {
        a {
          color: scss.$palette-blue-dark-100;

          &:hover {
            color: scss.$palette-primary-100;
          }

          &:focus-visible {
            color: scss.$palette-primary-dark-100;
          }
        }
      }
    }
  }
}
