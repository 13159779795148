@use 'src/assets/scss';

$scale-factor: 1.05;
$transition-duration-hover: 150ms;
$transition-duration-scale: 150ms;
$image-interaction-opacity: 0.4;

.HighlightTeaserGroup {
  @include scss.reset-default();
  justify-content: center;
  margin-bottom: scss.$spacing-4;
  overflow: hidden;

  .HighlightTeaserGroupGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    > * {
      grid-column-end: span 4;

      @include scss.breakpoint-max-899 {
        &:first-child {
          grid-column-end: span 12;
        }

        &:nth-child(n + 2) {
          grid-column-end: span 6;
        }
      }

      @include scss.breakpoint-max-599 {
        &:nth-child(n + 2) {
          grid-column-end: span 12;
        }
      }

      @include scss.breakpoint-min-900 {
        &:first-child {
          grid-column-end: span 8;
          grid-row-end: span 2;
        }

        &:nth-child(n + 4) {
          grid-column-end: span 6;
        }
      }
    }

    &.teaser-count-even {
      > * {
        grid-column-end: span 6;
        grid-row-end: auto;

        @include scss.breakpoint-max-599 {
          grid-column-end: span 12;
        }
      }
    }
  }
}
