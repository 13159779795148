@use 'src/assets/scss';

.PressReleaseButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: scss.spacing-px-to-rem(12);
  margin-top: scss.spacing-px-to-rem(24);
}

.PressReleaseTitle {
  @include scss.display-1(bold);
  margin-top: 0;
}

time {
  @include scss.display-5(bold);
  color: scss.$palette-primary-100;
}
