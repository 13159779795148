@use 'src/assets/scss';

.SocialMediaLink {
  // Link button styling
  &:global(.MuiButton-root) {
    @include scss.color-transition();
    background-color: scss.$palette-white-100;
    border-radius: 50%;
    height: scss.px-to-rem(48);
    margin-right: scss.px-to-rem(12);
    min-width: scss.px-to-rem(48);

    &:last-child {
      margin-right: 0;
    }

    .Icon {
      display: flex;
    }

    // Icon
    svg {
      @include scss.color-transition();
      align-items: center;
      background-color: inherit;
      color: scss.$palette-blue-dark-100;
      display: flex;
      height: scss.px-to-rem(24);
      justify-content: center;
    }

    // Effect on hover depending on social media type
    &:focus,
    &:hover {
      svg {
        @include scss.color-transition();
        color: scss.$palette-white-100;
      }

      &.facebook {
        @include scss.color-transition();
        background-color: #1877f2;
      }

      &.blog {
        @include scss.color-transition();
        background-color: scss.$palette-primary-100;
      }

      &.instagram {
        @include scss.color-transition();
        background-color: #c32aa3;
      }

      &.linkedin {
        @include scss.color-transition();
        background-color: #0a66c2;
      }

      &.twitter {
        @include scss.color-transition();
        background-color: #1da1f2;
      }

      &.xing {
        @include scss.color-transition();
        background-color: #026466;
      }

      &.youtube {
        @include scss.color-transition();
        background-color: #f00;
      }
    }

    &:focus {
      @include scss.interactive-focus();
    }

    &:active {
      &.facebook,
      &.blog,
      &.instagram,
      &.linkedin,
      &.twitter,
      &.xing,
      &.youtube {
        background-color: scss.$palette-primary-dark-100;
      }
    }
  }
}
