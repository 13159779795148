@use 'src/assets/scss';

.ImageWidget {
  &:not(.full) {
    @include scss.default-width();
  }

  &.full figcaption {
    @include scss.default-width;
    width: 100%;
  }

  // Align content on the left side for large screens
  &.left {
    margin-left: 0;
  }

  // Align content on the right side for large screens
  &.right {
    margin-right: 0;
  }
}

.ImageWrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  &.HeightLimited {
    $max-height: scss.px-to-rem(650);

    img {
      max-height: $max-height;
      width: 100%;
    }

    figure,
    div {
      max-width: calc(#{$max-height} / 16 * 9);
    }
  }
}
